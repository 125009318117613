import cn from 'classnames'
import React from 'react'
import { handleNextSlaid } from '../../Functions/Utils'
import nextArrowPicture from './nextArrow.png'
import styles from './styles.module.css'
const NextArrow = ({ setCurrentComic, currentComic, comicsName, lection }) => {
	const handleNext = () => {
		handleNextSlaid({ setCurrentComic, currentComic, comicsName })
	}
	return (
		<div
        loading="eager"
        className={styles.btn_back}
        onClick={handleNext}
    
     >Вперёд</div>
	)
}

export default NextArrow
