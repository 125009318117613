import { Col, Container, Row } from 'react-bootstrap'
import { Fact, ImgBox, TextBox } from '../UI_Kit/UI'
import { Lection3_m2 } from './Lection'
import h1_m4_p1 from './image/h1_m4_p1.png'
import h1_m4_p2 from './image/h1_m4_p2.png'
import h1_p1 from './image/h1_p1.png'
import h2_m2_p1 from './image/h2_m2_p1.png'
import h2_m2_p2 from './image/h2_m2_p2.png'
import h2_m3_p1 from './image/h2_m3_p1.png'
import h2_m4_p1 from './image/h2_m4_p1.png'
import h3_m2_p1 from './image/h3_m2_p1.png'
import h3_m3_p1 from './image/h3_m3_p1.png'
import h3_m3_p2 from './image/h3_m3_p2.png'
import h3_m4_p1 from './image/h3_m4_p1.png'
import h3_p1 from './image/h3_p1.png'
import h4_m3_p1 from './image/h4_m3_p1.png'
import h4_m3_p2 from './image/h4_m3_p2.png'
import h4_m4_p1 from './image/h4_m4_p1.png'
import h4_m4_p2 from './image/h4_m4_p2.png'
import h4_m4_p3 from './image/h4_m4_p3.png'
import h4_p1 from './image/h4_p1.png'
import h4_p5 from './image/h4_p2.png'
import h4_p3 from './image/h4_p3.png'
import h4_p4 from './image/h4_p4.png'
import h5_m2_p1 from './image/h5_m2_p1.png'
import h5_m2_p2 from './image/h5_m2_p2.png'
import h5_m3_p1 from './image/h5_m3_p1.png'
import h5_m3_p2 from './image/h5_m3_p2.png'
import h5_m4_p1 from './image/h5_m4_p1.png'
import h5_m4_p2 from './image/h5_m4_p2.png'
import h4_m2_p2 from './image/l5_m2_p4.png'
import h4_m2_p1 from './image/l5_m2_p5.png'
import h4_p2 from './image/l7_p2.png'
import h6_m2_p1 from './image/l8_m2_p4.png'
import h2_p1 from './image/l9_p4.png'
import mouse_tl from './image/mouse_tl.png'
import saigak from './image/saigak.png'
export const Hint1_m1 = props => {
	const text_1 = (
		<p className='text-italic'>
			Степь часто сравнивают с морем, так как у степи есть свои корабли!
			Кораблями степей Азии называют верблюдов (бактрианов). Народы Монголии,
			Калмыкии, Татарстана и других регионов держат верблюдов в хозяйстве.
			Многие любят их за спокойный и трудолюбивый темперамент. Для верблюдов
			степь привычная среда обитания: они приспособлены к резким перепадам
			температур, длительному недостатку влаги и отсутствию тени.
		</p>
	)
	return (
		<Container className='pt-5 pb-sm-5 pb-4'>
			<Row className='d-flex align-items-center mb-5'>
				<Col
					xxl={10}
					xl={10}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					className='offset-lg-1'
				>
					<TextBox element={text_1} yellow mode={4} />
				</Col>
			</Row>
			<Row className='d-flex align-items-center mb-5'>
				<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
					<ImgBox
						src={h1_p1}
						description='Фото Тимофеенко Ю.В. Верблюды в Ассоциации “Живая природа степи”'
						mark_class={'mark-double'}
						description_class={'til-down'}
					/>
				</Col>
			</Row>
		</Container>
	)
}

export const Hint2_m1 = props => {
	const text_1 = (
		<p className='text-italic'>
			Монгольский дзерен, или зобастая антилопа (Procapra gutturosa) — небольшое
			копытное животное. Вид включён в Красную книгу РФ, Красные книги Тувы,
			Алтая и Забайкальского края как вид, находящийся под угрозой исчезновения.
			В Китае отнесён к охраняемым видам 2 категории, в Монголии — объект
			промысла и любительской охоты.
		</p>
	)

	const text_2 = (
		<p className='text-medium'>
			Благодаря усилиям Даурского заповедника в России удалось восстановить
			дзерена, который был практически полностью истреблён. Ныне тысячные стада
			грациозных антилоп опять украшают Даурскую степь.
		</p>
	)
	const text_3 = (
		<p className='fact-text'>
			Сезонные миграции смотрятся очень эффектно: с одной точки можно увидеть
			30–40 тысяч копытных в степи. В конце августа — октябре большие стада,
			объединённые в многотысячные группировки, волнами, одна за другой,
			покидают летнюю территорию и по многолетним миграционным путям
			устремляются в районы зимовки. 
		</p>
	)
	return (
		<div>
			<Container className='pt-5 pb-sm-5 pb-4'>
				<Row className='d-flex align-items-center mb-5'>
					<Col
						xxl={10}
						xl={10}
						lg={10}
						md={12}
						sm={12}
						xs={12}
						className='offset-lg-1'
					>
						<TextBox element={text_1} yellow mode={4} />
					</Col>
				</Row>
				<Row className='d-flex align-items-center mb-5'>
					<Col
						xxl={5}
						xl={5}
						lg={5}
						md={12}
						sm={12}
						xs={12}
						className='mb-lg-0 mb-sm-5 mb-5'
					>
						<TextBox element={text_2} yellow mode={3} clip={'yellow_tr'} />
					</Col>
					<Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12}>
						<ImgBox
							src={h2_p1}
							description='Стрельникова'
							mark_class={'mark-double'}
							description_class={'til-down'}
						/>
					</Col>
				</Row>
			</Container>
			<Container fluid className='p-0 mg-b-p-1'>
				<Fact text={text_3} color={1} />
			</Container>
		</div>
	)
}
export const Hint3_m1 = props => {
	const text_1 = (
		<p className='text-medium text-bold'>
			Даурский журавль (Grus vipio) - это один из самых крупных, редких и
			стройных представителей семейства журавлеобразных. В дикой природе на
			планете осталось лишь около 5500 - 6500 даурских журавлей. Занесен в
			Красный список глобально угрожаемых видов МСОП (его часто называют
			Международной Красной книгой), в Красные книги России и Забайкальского
			края.
		</p>
	)

	const text_2 = (
		<p className='text-medium text-bold'>
			Даурия – ключевое место обитания этих журавлей. Здесь они впервые были
			обнаружены, а затем в 1811 г. описаны великим путешественником П.С.
			Палласом. 
		</p>
	)
	const text_3 = (
		<p className='fact-text'>
			Сотрудники заповедника “Даурский” обычно называют этого журавля «даурцем»
			Отношение к нему особое. Ведь он – символ Даурского заповедника. А в 2011
			г. даурский журавль, кроме того, выбран официальной эмблемой
			Забайкальского края.
		</p>
	)
	return (
		<div>
			<Container className='pt-5 pb-sm-5 pb-4'>
				<Row className='d-flex align-items-center mb-5'>
					<Col
						xxl={5}
						xl={5}
						lg={5}
						md={12}
						sm={12}
						xs={12}
						className='mb-lg-0 mb-sm-5 mb-5'
					>
						<TextBox
							element={text_1}
							yellow
							mode={3}
							clip={'yellow_tr'}
							className='h-lg-3'
						/>
					</Col>
					<Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12}>
						<ImgBox
							src={h3_p1}
							description='Фото Стрельникова А. Даурский журавль'
							mark_class={'mark-double'}
							description_class={'til-down'}
						/>
					</Col>
				</Row>
				<Row className='d-flex align-items-center mb-5'>
					<Col
						xxl={8}
						xl={8}
						lg={8}
						md={12}
						sm={12}
						xs={12}
						className='offset-lg-2'
					>
						<TextBox element={text_2} yellow mode={2} className='h-lg-1' />
					</Col>
				</Row>
			</Container>
			<Container fluid className='p-0 mg-b-p-1'>
				<Fact text={text_3} color={1} />
			</Container>
		</div>
	)
}
export const Hint4_m1 = props => {
	const text_1 = (
		<p className='text-medium '>
			Государственный природный биосферный Заповедник «Даурский» расположен
			на юге Забайкальского края, практически на стыке трёх стран: России,
			Монголии и Китая. Он входит состав международного{' '}
			<a
				href='http://daurzapoved.com/index.php/ru/dauria'
				className='text-medium text-orange'
				target='_blank'
				rel='noreferrer'
			>
				(российско-монгольско-китайского) заповедника «Даурия».
			</a>{' '}
			Такой статус подчёркивает важность сохранения уникального уголка природы.
		</p>
	)

	const text_2 = (
		<p className='text-medium text-bold'>
			Территория заповедника это одно из прекрасных мест, где можно
			почувствовать величие, силу и красоту природы. <b>Даурские степи</b> —
			это бесконечные просторы «травяного моря» и бархатные сонные сопки,
			звонкое пение жаворонков, «столбики» любопытных тарбаганов и грациозные
			танцы журавлей… И пока ещё многочисленные стада азиатских антилоп —
			дзеренов, готовых в любую секунду умчаться вдаль со скоростью ветра.
		</p>
	)
	const text_3 = (
		<p className='text-italic'>
			Даурия — это и блюдца озёр, от маленьких до бескрайних, где вода сливается
			с горизонтом, а окаймляющие песчаные пляжи или заросли камышей
			и тростников служат прибежищем для тысяч уток, гусей, белоснежных лебедей,
			юрких куликов и неугомонных чаек. Светлые, залитые солнцем
			берёзово-осиновые колки и причудливые скалы Адон-Челона, островные
			сосновые боры среди бескрайней степи, издалека похожие на средневековое
			войско кочевников, – это тоже многоликая Даурия.
		</p>
	)

	const text_4 = (
		<p className='text-medium'>
			Заповедная территория создаёт условия для выживания редких видов животных.
			Среди них — <b>монгольский дзерен</b> и реликтовая чайка,{' '}
			<b>гусь сухонос</b> (всплывающее окно), дрофа, <b>даурский журавль</b>.
		</p>
	)

	const text_5 = (
		<p className='text-italic'>
			На первый взгляд, природа здесь скромна, не знает излишеств, но созданное
			ею не оставляет равнодушными даже самых искушённых путешественников.
			В 2000 году Всемирный фонд дикой природы включил Даурские степи в число
			200 экорегионов планеты, имеющих особое значение для сохранения жизни
			на Земле.
		</p>
	)
	const text_6 = (
		<p className='text-medium'>
			Сохранить уникальные водно-болотные, степные и лесостепные экосистемы
			Даурии — основная задача заповедника «Даурский».
		</p>
	)
	return (
		<div>
			<Container className='pt-5 pb-sm-5 pb-4'>
				<Row className='d-flex align-items-center mb-5'>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='mb-lg-0 mb-sm-5 mb-5'
					>
						<TextBox
							element={text_1}
							yellow
							mode={1}
							className='h-xl-2 h-lg-3'
						/>
					</Col>
					<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
						<TextBox element={text_2} yellow mode={3} clip={'yellow_tr'} />
					</Col>
				</Row>
				<Row className='d-flex align-items-center mb-5'>
					<Col xxl={7} xl={7} lg={12} md={12} sm={12} xs={12}>
						<ImgBox
							src={h4_p1}
							description='Фото Горошко О.'
							mark_class={'mark-d-r-t'}
							description_class={'til-down'}
							className='mb-xl-0 mb-sm-5 mb-5'
						/>
					</Col>
					<Col xxl={5} xl={5} lg={12} md={12} sm={12} xs={12}>
						<TextBox
							element={text_3}
							yellow
							mode={4}
							className='h-xxl-4 h-xl-3 '
						/>
					</Col>
				</Row>
				<Row className='d-flex align-items-center mb-5'>
					<Col
						xxl={10}
						xl={10}
						lg={10}
						md={12}
						sm={12}
						xs={12}
						className='mb-sm-5 mb-5'
					>
						<ImgBox
							src={h4_p2}
							description='Фото Горошко О.'
							mark_class={'mark-double'}
							description_class={'tir-down'}
							className='offset-lg-1'
						/>
					</Col>
				</Row>
				<Row className='d-flex align-items-start mb-5'>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='mb-sm-5 mb-5'
					>
						<TextBox
							element={text_4}
							yellow
							mode={2}
							clip={'orange_tr'}
							className='mb-sm-5 mb-5'
						/>
						<ImgBox
							src={h4_p3}
							description='Фото Горошко О.'
							description_class={'tir-down'}
							className='mb-sm-5 mb-5'
						/>
						<TextBox element={text_5} yellow mode={4} />
					</Col>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='mb-sm-5 mb-5'
					>
						<ImgBox
							src={h4_p5}
							description={
								<p className='text-img'>
									Фото Горошко О.
									<br />
									Гусь сухонос
								</p>
							}
							description_class={'tir-up'}
							className='mb-sm-5 mb-5'
						/>
						<ImgBox
							src={h4_p4}
							description='Фото Андрея Стрельникова
              Журавль'
							description_class={'tir-down'}
							className='mb-sm-5 mb-5'
						/>
						<TextBox element={text_6} yellow mode={3} />
					</Col>
				</Row>
			</Container>
		</div>
	)
}
export const Hint1_m2 = props => {
	return <Lection3_m2 />
}
export const Hint2_m2 = props => {
	const text_1 = (
		<p className='text-italic'>
			Они представляют собой схематичные изображения людей, которые двумя руками
			держат у живота некий сосуд или чашу. Причем, пропорции человеческого тела
			заметно искажены: статуи имеют массивные плечи, короткие ноги, большие
			головы с плоскими лицами. Некоторые исследователи полагают, что древние
			мастера при создании каменных баб стремились, чтобы они были узнаваемы с
			большого расстояния, эти и объясняется нарушение пропорций.
		</p>
	)
	const text_2 = (
		<p className='text-medium'>
			Ролик о каменных бабах в Ростовском Краеведческом музее можно посмотреть{' '}
			<a
				className='text-medium text-pink  underline-pink'
				href='https://rostovmuseum.ru/visitors/detalnaya/?ID=2588&sphrase_id=29582'
				target='_blank'
				rel='noreferrer'
			>
				здесь
			</a>
			.
		</p>
	)
	return (
		<Container className='pt-5 pb-sm-5 pb-4'>
			<Row className='d-flex align-items-center mb-5'>
				<Col
					xxl={10}
					xl={10}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					className='offset-lg-1'
				>
					<ImgBox
						src={h2_m2_p1}
						description='Каменная баба - статуи со времен древних народов.'
						mark_class={'mark-double-d-2'}
						description_class={'tir-up'}
					/>
				</Col>
			</Row>
			<Row className='d-flex align-items-end mb-5'>
				<Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
					<TextBox
						element={text_1}
						purple
						mode={4}
						className='mb-lg-0 mb-sm-5 mb-5'
					/>
				</Col>
				<Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
					<TextBox element={text_2} purple mode={1} />
				</Col>
			</Row>
			<Row className='d-flex align-items-center mb-5'>
				<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
					<ImgBox
						src={h2_m2_p2}
						mark_class={'mark-d-l-b'}
						description_class={'til-down'}
						className='clip-blue-top-right p-0'
					/>
				</Col>
			</Row>
		</Container>
	)
}
export const Hint3_m2 = props => {
	const text_1 = (
		<p className='text-italic'>
			Даже с приходом на просторы евразийских степей современной цивилизации остались народы,
			не бросившие кочевой образ жизни. Да, у них есть мобильные телефоны и Интернет, но они
			по-прежнему живут в переносных жилищах, и, следуя за своими стадами, так же, как и их
			предки, кочуют по Великой Степи.
		</p>
	)

	return (
		<Container className='pt-5 pb-sm-5 pb-4'>
			<Row className='d-flex align-items-center  mb-sm-5 mb-5'>
				<Col
					xxl={5}
					xl={5}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className='mb-xl-0 mb-sm-5 mb-5'
				>
					<TextBox element={text_1} purple mode={3} clip={'blue_tl'} />
				</Col>
				<Col xxl={7} xl={7} lg={12} md={12} sm={12} xs={12}>
					<ImgBox
						src={h3_m2_p1}
						mark_class={'mark-double-r-t  '}
						description_class={'tir-up'}
					/>
				</Col>
			</Row>
		</Container>
	)
}
export const Hint4_m2 = props => {
	const text_1 = (
		<p className='text-italic'>
			Это были маленькие, свободно пасущиеся лошади, не выше 140 см. Коренастые,
			с густой гривой, хвостом и тёмными зеброидными полосками, они смотрелись
			гармонично на фоне бескрайних степей. Животные водились табунами, которые
			распадались на маленькие группы с жеребцом во главе. Тарпаны были крайне
			дики, осторожны и пугливы.
		</p>
	)
	const fact = (
		<p className='fact-text'>
			Одним из типичных животных, населявших степь в то время, был тарпан. Слово
			«Тарпан» происходит от названия на тюркском языке, означающего «дикая
			лошадь». Этот вид вымер в конце XVIII-начале XX вв. в результате распашки
			степей и длительного вытеснения стадами домашних животных.
		</p>
	)

	return (
		<div>
			<Container className='pt-5 pb-sm-5 pb-4'>
				<Row className='d-flex align-items-start justify-content-start mb-5 mb-sm-5 '>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='md-md-0 mb-5
					mb-lg-0 mb-sm-5 '
					>
						<ImgBox src={h4_m2_p2} mark_class={'mark-h'} />
					</Col>
					<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} className='mb-0'>
						<ImgBox src={h4_m2_p1} mark_class={'mark-d-r-t'} />
					</Col>
				</Row>
				<Row className='d-flex align-items-start justify-content-start mb-5 mb-sm-5 '>
					<Col
						xxl={10}
						xl={10}
						lg={10}
						md={12}
						sm={12}
						xs={12}
						className='md-md-0 mb-5
					mb-lg-0 mb-sm-5 offset-lg-1'
					>
						<TextBox element={text_1} purple mode={4} />
					</Col>
				</Row>
			</Container>
			<Container fluid className='mg-sm-b-p-1 p-0'>
				<Fact text={fact} color={2} />
			</Container>
		</div>
	)
}
export const Hint5_m2 = props => {
	const text_1 = (
		<p className='text-medium text-white'>
			Цимлянское водохранилище на границе Ростовской и Волгоградской области
			было в полностью заполнено водой в 1955 году. Но под ее водами скрывается
			хазарская крепость Саркел, построенная в IX веке. Здесь было пересечение
			торговых сухопутных дорог с водным путем по Дону. 
		</p>
	)
	const text_2 = (
		<p className='text-medium'>
			На этом месте проводились археологические экспедиции в 1934-1936 гг. и
			1949-1951 гг. в рамках Волго-Донской экспедиции. Обследовано менее трети
			площади памятника. Этих лет оказалось слишком мало и 2/3 бывшего Саркела,
			так и оставшихся неисследованными, скрываются под толщами донской воды. В
			1952 г. место, где стояла крепость Саркел, было затоплено при
			строительстве Цимлянского водохранилища. Найденные предметы хранятся в
			Эрмитаже, Историческом музее (Москва), в{' '}
			<a
				className='link-purple text-medium'
				href='http://www.novochmuseum.ru'
				target='_blank'
				rel='noreferrer'
			>
				Новочеркасском музее истории донского казачества
			</a>{' '}
			и{' '}
			<a
				className='link-purple text-medium'
				href='http://tsimlyansk-museum.ru'
				target='_blank'
				rel='noreferrer'
			>
				Цимлянском районном краеведческом музее.
			</a>
			 
		</p>
	)

	return (
		<Container className='pt-5 pb-sm-5 pb-4'>
			<Row className='d-flex align-items-center  mb-sm-5 mb-5'>
				<Col
					xxl={5}
					xl={5}
					lg={5}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-sm-5 mb-5'
				>
					<TextBox
						element={text_1}
						purple
						mode={2}
						clip={'blue_tr'}
						className='h-lg-3'
					/>
				</Col>
				<Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12}>
					<ImgBox
						src={h5_m2_p1}
						mark_class={'mark-double-r-t  '}
						description_class={'tir-up'}
					/>
				</Col>
			</Row>
			<Row className='d-flex align-items-center justify-content-end mb-sm-5 mb-5'>
				<Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
					<TextBox element={text_2} purple mode={1} />
				</Col>
			</Row>
			<Row className='d-flex align-items-center justify-content-center mb-sm-5 mb-5'>
				<Col
					xxl={10}
					xl={10}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-sm-5 mb-5 '
				>
					<ImgBox
						src={h5_m2_p2}
						mark_class={'mark-double-r-t  '}
						description={
							'Правобережная Цимлянская крепость. Реконструкция на основе раскопок и плана 1743 г. Автор: археолог Флеров В.С. Акварель Олега Фёдорова. Публикуется впервые. Журнал «Восточная коллекция». Лето // 2006 № 2 (25)'
						}
						description_class={'description-big tir-down'}
					/>
				</Col>
			</Row>
		</Container>
	)
}

export const Hint6_m2 = props => {
	const text_1 = (
		<p className='text-italic'>
			Шёлковый путь, протянувшийся более чем на 6400 км, играл важнейшую роль
			в установлении экономических, культурных, политических и религиозных
			отношений между Востоком и Западом.
		</p>
	)
	const text_2 = (
		<p className='text-medium'>
			Своим названием Великий Шёлковый путь обязан крайне прибыльной торговле
			шёлковыми тканями, которые производились в Китае. С Востока
			также поставляли чай, красители, ароматические масла, фарфор. Запад
			обменивал их на лошадей, мёд, вино и золото.
		</p>
	)
	const text_3 = (
		<p className='text-medium'>
			За свою полуторатысячелетнюю историю Шёлковый путь стал свидетелем
			рождения и гибели многочисленных империй и пережил крупнейшие исторические
			события, например, монгольское завоевание Евразии и эпидемию Чёрной Смерти
			(чумы).
		</p>
	)
	const text_animal = (
		<p className='text-medium'>
			<b className='text-title text-underline-purple'>
				Великий
				<br />
				Шёлковый путь{' '}
			</b>
			<br />
			<br /> сеть торговых маршрутов, связывавших Европу с Азией в период
			со 2 века до н.э. по 15 в. н.э. 
		</p>
	)

	return (
		<Container className='pt-5 pb-5'>
			<Row className='mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={8}
					sm={8}
					xs={8}
					className='offset-lg-0 offset-2 offset-sm-2 mb-5 mb-sm-5 mb-lg-0'
				>
					<ImgBox src={h6_m2_p1} />
				</Col>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={10}
					sm={10}
					xs={10}
					className='d-flex flex-column align-items-end justify-content-center'
				>
					<TextBox element={text_animal} className='p-0' />
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={10}
						sm={10}
						xs={10}
						className='offset-lg-0 offset-1 offset-sm-1'
					>
						<ImgBox src={mouse_tl} />
					</Col>
				</Col>
			</Row>
			<Row className='mb-5'>
				<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
					<TextBox element={text_1} purple mode={4} />
				</Col>
			</Row>
			<Row className='mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-5 mb-sm-5 '
				>
					<TextBox element={text_2} purple mode={1} className='h-lg-2' />
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<TextBox element={text_3} purple mode={1} className='h-lg-2' />
				</Col>
			</Row>
		</Container>
	)
}

export const Hint1_m3 = props => {
	const text_1 = (
		<p className='text-medium text-center'>
			Официальный сайт:{' '}
			<a
				className='link-green text-medium'
				href='https://orenzap.ru/'
				target='_blank'
				rel='noreferrer'
			>
				https://orenzap.ru/
			</a>
		</p>
	)
	const text_2 = (
		<p className='text-medium text-white'>
			Заповедник “Оренбургский” является самым степным заповедником в России.
			Это значит, что более 90% всех территорий заповедника занимают степи. 
		</p>
	)
	const text_3 = (
		<p className='text-medium text-white'>
			Работа заповедника направлена на сохранение эталонных экосистем уральских
			степей и охраны редких видов степной флоры и фауны на стыке Европейской
			части России и Сибири. Увидеть своими глазами живописную природу уральских
			степей можно по{' '}
			<a
				className='link-green-dark text-medium'
				href='https://www.youtube.com/watch?v=33jSIEQDvdY'
				target='_blank'
				rel='noreferrer'
			>
				ссылке.
			</a>
		</p>
	)
	const text_4 = (
		<ul>
			<p className='text-bold mb-5'>Заповедник включает 5 участков:</p>
			<li className='text-bold list-item mb-4'>Таловская степь</li>
			<li className='text-bold list-item mb-4'>Буртинская степь</li>
			<li className='text-bold list-item mb-4'>Предуральская степь</li>
			<li className='text-bold list-item mb-4'>Ащисайская степь</li>
			<li className='text-bold list-item mb-4'>Айтуарская степь</li>
		</ul>
	)
	const text_5 = (
		<a
			className='link-green text-medium'
			href='https://www.youtube.com/watch?v=xU57iuswMKk'
			target='_blank'
			rel='noreferrer'
		>
			Программа реинтродукции лошади Пржевальского в заповеднике «Оренбургский».
		</a>
	)
	const text_6 = (
		<p className='text-italic'>
			С 2015 г. на территории участка «Предуральская степь» работает{' '}
			<b>Центр реинтродукции лошади Пржевальского</b> . Это уникальный Центр,
			направленный на создание необходимых условий для сохранения лошади
			Пржевальского на территории Российской Федерации. 
		</p>
	)
	const text_7 = (
		<p className='text-italic'>
			Первые шесть лошадей приехали из Франции. Как и положено, французские
			лошади имели свои имена: жеребец Авен, кобылы Сангрия, Селена, Олива и
			Лаванда, а также полуторагодовалый жеребенок Паприка. После
			акклиматизационного вольера лошадей выпустили на заповедную территорию.
		</p>
	)
	const text_8 = (
		<p className='text-medium text-white'>
			Благодаря работе специалистов центра животные хорошо чувствуют себя в
			Оренбургской степи. Популяция центра регулярно пополняется и обновляется
			кровь, благодаря обмену с зоопарками и другими центрами. Сейчас
			численность лошадей в Центре составляет 85 особей.
		</p>
	)
	const text_9 = (
		<p className='text-medium '>
			В Центре реинтродукции действует проект -{' '}
			<a
				href='https://orenzap.ru/node/20491'
				className='link-green text-medium'
				target='_blank'
				rel='noreferrer'
			>
				"Окно в степь"
			</a>
			! Теперь по{' '}
			<a
				href='https://orenzap.ru/node/20491'
				className='link-green text-medium'
				target='_blank'
				rel='noreferrer'
			>
				ссылке
			</a>{' '}
			абсолютно каждый желающий с помощью онлайн-камер может в реальном времени
			понаблюдать за жизнью диких лошадей в Оренбургских степях.
		</p>
	)

	return (
		<Container className='pt-5 pb-sm-5 pb-4'>
			<Row className='d-flex align-items-center  mb-sm-5 mb-5'>
				<Col
					xxl={4}
					xl={4}
					lg={5}
					md={5}
					sm={6}
					xs={12}
					className='mb-xl-0 mb-sm-0 mb-5'
				>
					<TextBox element={text_1} green mode={1} className='h-lg-1 h-sm-2' />
				</Col>
				<Col xxl={8} xl={8} lg={7} md={7} sm={6} xs={12}>
					<TextBox element={text_2} green mode={2} className='h-lg-1 h-sm-2' />
				</Col>
			</Row>
			<Row className='d-flex align-items-center  mb-sm-5 mb-5'>
				<Col
					xxl={10}
					xl={10}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					className='offset-lg-1'
				>
					<TextBox element={text_3} green mode={3} clip={'green_tr'} />
				</Col>
			</Row>
			<Row className='d-flex align-items-start  mb-sm-5 mb-5'>
				<Col xxl={4} xl={4} lg={6} md={7} sm={9} xs={12} className='m-auto'>
					<TextBox
						element={text_4}
						dote
						green
						mode={5}
						className='h-lg-4 mb-lg-0 mb-sm-5 mb-5'
					/>
				</Col>
				<Col xxl={8} xl={8} lg={6} md={12} sm={12} xs={12}>
					<TextBox
						element={text_5}
						green
						mode={1}
						clip={'green_tl'}
						className='mb-sm-5 mb-5'
					/>
					<TextBox element={text_6} green mode={4} />
				</Col>
			</Row>
			<Row className='d-flex align-items-center  mb-sm-5 mb-5'>
				<Col
					xxl={7}
					xl={7}
					lg={7}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-sm-5 mb-5'
				>
					<TextBox element={text_7} green mode={4} />
				</Col>
				<Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
					<TextBox element={text_8} green mode={3} />
				</Col>
			</Row>
			<Row className='d-flex align-items-center  mb-sm-5 mb-5'>
				<Col
					xxl={10}
					xl={10}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					className='offset-lg-1'
				>
					<TextBox element={text_9} green mode={2} />
				</Col>
			</Row>
		</Container>
	)
}
export const Hint2_m3 = props => {
	const fact = (
		<p className='fact-text'>
			До начала XX века слово заповедный применялось только в значениях
			“заповедные леса” и “заповедные имения”. С 1845 г. большие территории
			земли у крупных дворянских родов передавались в наследство. Их и называли
			“заповедные имения”. освобождение от крепостного права в 1961 г. позволило
			крестьянам иметь свою землю и свободно заниматься земледелием. Таким
			образом к концу XIX века практически не осталось нетронутых степных угодий
			и “заповедные имения” дворян оставались нетронутым природным “островком”
			среди распаханных крестьянами полей.
		</p>
	)
	const description_text = (
		<p className='text-medium'>
			Источник: официальный сайт ФГБУ{' '}
			<a
				className='text-medium link-green'
				href='https://orenzap.ru'
				target='_blank'
				rel='noreferrer'
			>
				“Заповедники Оренбуржья”
			</a>
		</p>
	)

	return (
		<div>
			<Container className='pt-5 pb-sm-5 pb-4'>
				<Row className='d-flex align-items-center justify-content-center mb-5 mb-sm-5 '>
					<Col xxl={10} xl={10} lg={10} md={12} sm={12} xs={12}>
						<ImgBox
							src={h2_m3_p1}
							mark_class={'mark-d-r-t'}
							description={description_text}
							description_class={'til-down description-big'}
						/>
					</Col>
				</Row>
			</Container>
			<Container fluid className='mg-b-p-1 p-0'>
				<Fact text={fact} color={3} />
			</Container>
		</div>
	)
}

export const Hint3_m3 = props => {
	const text_1 = (
		<p className='text-medium text-bold'>
			Центрально-Черноземный заповедник. Курская область. Официальный сайт:{' '}
			<a
				className='text-medium link-green'
				href='http://zapoved-kursk.ru'
				target='_blank'
				rel='noreferrer'
			>
				http://zapoved-kursk.ru
			</a>
		</p>
	)
	const text_2 = (
		<p className='text-medium text-white'>
			Центрально-Черноземный заповедник это один из первых заповедников. Он был
			создан 1935 г. Его создание тесно связано с трудами профессора Василия
			Васильевича Алехина, в связи с этим носит свое название -
			Центрально-Черноземный государственный природный биосферный заповедник
			имени профессора В.В. Алехина.
		</p>
	)
	const text_3 = (
		<p className='text-medium text-white'>
			Василий Васильевич положил начало систематическому изучению образования
			растительного покрова степной целины. В 1924 г. Алехин по личной
			инициативе снова обследовал растительность Курской области. Благодаря его
			работам был создан заповедник.
		</p>
	)
	const text_4 = (
		<p className='text-italic'>
			Это один из самых маленьких заповедников России, что ничуть не умаляет его
			достоинств: заповедник с 1979 г. входит в международную сеть биосферных
			резерватов ЮНЕСКО.
		</p>
	)

	const text_5 = (
		<p className='text-medium'>
			Тут настоящая страна живых ископаемых! На территории заповедника
			произрастают настоящие реликтовые растения. Реликтами считаются такие
			виды, как: проломник Козо-Полянского, володушка многожилковая,
			волчеягодник боровой, шиверекия подольская, дендрантема Завадского.
		</p>
	)
	return (
		<div>
			<Container className='pt-5 pb-sm-5 pb-4'>
				<Row className='d-flex align-items-start justify-content-center mb-5 mb-sm-5 '>
					<Col
						xxl={5}
						xl={5}
						lg={5}
						md={12}
						sm={12}
						xs={12}
						className='mb-lg-0 mb-5 mb-sm-5 '
					>
						<TextBox element={text_1} green mode={1} />
					</Col>
					<Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12}>
						<TextBox element={text_2} green mode={2} clip={'green_tr'} />
					</Col>
				</Row>
				<Row className='d-flex align-items-start justify-content-center mb-5 mb-sm-5 '>
					<Col
						xxl={5}
						xl={5}
						lg={5}
						md={12}
						sm={12}
						xs={12}
						className='mb-lg-0 mb-5 mb-sm-5 '
					>
						<TextBox element={text_3} green mode={3} />
					</Col>
					<Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12}>
						<ImgBox
							src={h3_m3_p1}
							description='Фото Сошнина В.П. Волчеягодник боровой '
							description_class={'til-down'}
							mark_class={'mark-d-r-t'}
						/>
					</Col>
				</Row>
				<Row className='d-flex align-items-start justify-content-center mb-5 mb-sm-5 '>
					<Col
						xxl={7}
						xl={7}
						lg={7}
						md={12}
						sm={12}
						xs={12}
						className='mb-lg-0 mb-5 mb-sm-5 '
					>
						<ImgBox
							src={h3_m3_p2}
							description='Фото Сошнина В.П. Волчеягодник боровой '
							description_class={'til-down'}
							mark_class={'mark-d-l-t'}
						/>
					</Col>
					<Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
						<TextBox
							element={text_4}
							green
							mode={4}
							className='mb-5 mb-sm-5 '
						/>
						<TextBox element={text_5} green mode={1} />
					</Col>
				</Row>
			</Container>
		</div>
	)
}
export const Hint4_m3 = props => {
	const text_1 = (
		<p className='text-medium text-white'>
			В зоне сухих степей и полупустынь Республики Калмыкия располагается
			заповедник “Черные земли”. Название региона «Черные земли» появилось в
			связи с тем, что зимой степь здесь практически не покрывается снегом и
			остается темным пятном среди заснеженных равнин Северо-Западного
			Прикаспия. С древних времен эта территория использовалась кочевниками для
			зимнего выпаса скота, а для сайгаков сегодня территория «Черных земель» –
			это это одно из важных мест сохранения этого вида. 
		</p>
	)
	const text_2 = (
		<p className='text-medium'>
			Заповедник “Черные земли”. Республика Калмыкия. Официальный сайт:{' '}
			<a
				className='link-green text-medium'
				href='https://zapovednik-chernyezemli.ru/'
				target='_blank'
				rel='noreferrer'
			>
				https://zapovednik-chernyezemli.ru/
			</a>
		</p>
	)
	const text_3 = (
		<p className='text-medium text-white'>
			За последнее столетие сайгаков почти полностью истребили и сейчас они
			внесены в Международную Красную книгу со статусом “Вид на грани
			исчезновения”. 
		</p>
	)
	const text_4 = (
		<p className='text-italic'>
			Сейчас сайгаки обитают только в Казахстане, Западной Монголии, Узбекистане
			и России (Астраханская область и Калмыкия).
		</p>
	)

	const text_5 = (
		<p className='text-medium'>
			Пять лет назад на территории России оставалось всего около 5 тысяч этих
			животных ( для сравнения 50 лет назад стада сайгаков достигали 800 тысяч
			голов).
		</p>
	)
	const fact = (
		<p className='fact-text'>
			Отношение к природе всегда почиталось у калмыков и всегда прослеживалось в
			их традициях.
		</p>
	)
	const text_6 = (
		<ul className='text-medium'>
			<p className='text-medim text-bold mb-5'>
				Вот несколько примеров таких национальных особенностей:
			</p>
			<li className='mb-5 list-number text-medium text-bold'>
				Запрещалось без нужды ранить землю острыми предметами, чертить по ней,
				вскрывать ее поверхность.
			</li>
			<li className='mb-5 list-number text-medium text-bold'>
				Чтобы не повредить верхний слой почвы, наши предки носили обувь с
				загнутыми носками.
			</li>
			<li className='mb-5 list-number text-medium text-bold'>
				Ламы, выходя на улицу, всегда брали с собой посох, издававший мелодичный
				звон благодаря прикрепленным металлическим кольцам. Этим звоном он
				предупреждал живые существа об опасности, давая им возможность спастись
				и не быть раздавленными.
			</li>
			 
		</ul>
	)
	const text_7 = (
		<p className='text-italic'>
			Заповедник бережно охраняет популяцию сайгаков. Как это происходит, можно
			посмотреть в ролике про заповедник.
		</p>
	)
	const text_8 = (
		<p className='text-italic'>Заповедник был основан 11 июня 1990 года.</p>
	)
	return (
		<div>
			<Container className='pt-5 pb-sm-5 pb-4'>
				<Row className='d-flex align-items-end justify-content-center mb-5 mb-sm-5 '>
					<Col
						xxl={7}
						xl={7}
						lg={7}
						md={12}
						sm={12}
						xs={12}
						className='mb-lg-0 mb-5 mb-sm-5 '
					>
						<TextBox element={text_1} green mode={2} clip={'green_tr'} />
					</Col>
					<Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
						<TextBox
							element={text_2}
							green
							mode={1}
							className='mb-5 mb-sm-5 '
						/>
						<TextBox element={text_3} green mode={3} />
					</Col>
				</Row>
				<Row className='d-flex align-items-start justify-content-center mb-5 mb-sm-5 '>
					<Col
						xxl={10}
						xl={10}
						lg={10}
						md={12}
						sm={12}
						xs={12}
						className='offset-lg-1'
					>
						<ImgBox
							src={h4_m3_p1}
							description='Фото Тяхта В. Сайгаки'
							description_class={'til-down'}
							mark_class={'mark-d-r-t'}
						/>
					</Col>
				</Row>
				<Row className='d-flex align-items-start justify-content-center mb-5 mb-sm-5 '>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='mb-lg-0 mb-5 mb-sm-5  '
					>
						<TextBox element={text_4} green mode={4} className='h-lg-1' />
					</Col>
					<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
						<TextBox element={text_5} green mode={1} className='h-lg-1' />
					</Col>
				</Row>
			</Container>
			<Container fluid className='p-0 mg-b-p-1'>
				<Fact text={fact} color={3} />
			</Container>
			<Container className='mb-sm-5 mb-5'>
				<Row className='d-flex align-items-start justify-content-center mb-5 mb-sm-5 '>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='mb-lg-0 mb-5 mb-sm-5  '
					>
						<TextBox element={text_6} green mode={1} />
					</Col>
					<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
						<TextBox element={text_7} green mode={4} className='mb-sm-5 mb-5' />
						<TextBox element={text_8} green mode={4} />
					</Col>
				</Row>
				<Row className='d-flex align-items-start justify-content-center mb-5 mb-sm-5 '>
					<Col
						xxl={10}
						xl={10}
						lg={10}
						md={12}
						sm={12}
						xs={12}
						className='offset-lg-1 mb-5 mb-sm-5'
					>
						<ImgBox
							src={h4_m3_p2}
							description='Фото Юсина Г. 
							Сайгак на водопое'
							description_class={'til-down description-big'}
							mark_class={'mark-double'}
						/>
					</Col>
				</Row>
			</Container>
		</div>
	)
}
export const Hint5_m3 = props => {
	const text_1 = (
		<p className='text-italic'>
			Крупнейший парящий хищник степи. Размах крыльев этой птицы достигает 2,6
			метров. В дикой природе орлы живут 4-8 лет, в неволе — до 20-30 лет.
			Главное условие для обитания и гнездования степного орла — наличие пищи. В
			основном, он питается сусликами, пищухами, большими и монгольскими
			песчанками. Иногда питается молодыми сурками, зайцами и даже птицами.
		</p>
	)
	const text_2 = (
		<p className='text-medium text-white'>
			Орел парит в воздухе, высматривая добычу. Острое зрение позволяет орлу
			рассмотреть мышь на расстоянии двух километров. Когда он видит цель,
			складывает крылья и бросается вниз, вытянув вперед свои когтистые лапы.
			Иногда орел устраивает засады на сусликов: он прячется за кочкой или
			кустом, лежит и выжидает зверька. Как только жертва уходит от норки,
			хищник появляется из засады и хватает добычу.
		</p>
	)
	const text_3 = (
		<p className='text-medium '>
			В наше время численность орла, к сожалению, уменьшилась. На это повлияло
			освоение степей человеком. Люди обрабатывают поля пестицидами и травят
			грызунов ядохимикатами, убивая, таким образом, и орлов. Также люди
			отстреливают птиц, которые, кроме того, еще и гибнут на линиях
			электропередач. Но сильнее всего сказалась целенаправленная борьба с
			основными кормовыми объектами: сусликами и песчанками. К сожалению, вслед
			за исчезновением сусликов стали исчезать и степные орлы.
		</p>
	)
	const text_4 = (
		<p className='text-medium '>
			Это всё обо мне!
		</p>
	)


	return (
		<div>
			<Container className='pt-5 pb-sm-5 pb-4'>
				<Row className='d-flex align-items-start justify-content-center mb-5 mb-sm-5 '>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='mb-lg-0 mb-5 mb-sm-5 '
					>
						<TextBox
							element={text_1}
							green
							mode={4}
							clip={'green_tl'}
							className='h-lg-3'
						/>
					</Col>
					<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
						<TextBox element={text_2} green mode={2} className='h-lg-3' />
					</Col>
				</Row>
				<Row className='d-flex align-items-center justify-content-center mb-5 mb-sm-5 '>
					<Col
						xxl={4}
						xl={4}
						lg={4}
						md={12}
						sm={12}
						xs={12}
						className='mb-lg-0 mb-5 mb-sm-5 '
					>
						<ImgBox src={h5_m3_p1} mark_class={'mark-double-d-2'} />
					</Col>
					<Col xxl={3} xl={3} lg={3} md={6} sm={6} xs={6}>
						<TextBox
							element={text_4}
							green
							mode={1}
							className='h-xxl-1 h-lg-1 d-flex align-items-center justify-content-center mb-5'
						/>
					</Col>
					<Col
						xxl={3}
						xl={3}
						lg={3}
						md={6}
						sm={6}
						xs={6}
						className='mb-lg-0 mt-5 mb-sm-5 '
					>
						<ImgBox src={h5_m3_p2} mark_class={'mark-double-d-0'} />
					</Col>
					
					
				</Row>
				<Row className='d-flex align-items-center justify-content-center mb-5 mb-sm-5 '>
				<Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
						<TextBox
							element={text_3}
							green
							mode={1}
							clip={'green_tr'}
							className='h-xxl-2 h-lg-3'
						/>
					</Col>
					
				</Row>
			</Container>
		</div>
	)
}

export const Hint1_m4 = props => {
	const text_1 = (
		<p className='text-italic'>
			Уже на протяжении многих лет в Ростовской области степь как магнит
			приманивает к себе тысячи гостей со всей России. Всему причиной не только
			цветение ирисов и тюльпанов, но и связанный с ними фестиваль
			экологического туризма “Воспетая степь”. Здесь можно, стать участником
			тематических площадок, отправиться на экскурсию в цветущую степь, или
			просто отдохнуть на лоне природы. 
		</p>
	)
	const text_2 = (
		<p className='text-medium text-white'>
			Фестиваль настоящая визитная карточка природы Донского края и добрая
			весенняя традиция долины Западного Маныча. Важно и то, что это не просто
			красивое зрелище, но и воспитание бережного отношения к окружающей среде.
		</p>
	)
	const text_3 = (
		<p className='text-medium '>
			Приглашаем принять участие в фестивале и окунуться в атмосферу цветущей
			степи!
		</p>
	)
	const text_4 = (
		<p className='text-medium text-white'>
			Приглашаем принять участие в фестивале и окунуться в атмосферу цветущей
			степи!
		</p>
	)
	const text_5 = (
		<p className='text-medium '>
			Важно! Каждый год фестиваль проводиться в разное время. Подробную
			информацию можно узнать ближе к апрелю на сайтах: Ассоциации{' '}
			<a
				href={'https://природастепи.рф'}
				className='link-blue text-medium'
				target='_blank'
				rel='noreferrer'
			>
				“Живая природа степи”
			</a>
			,{' '}
			<a
				href={'http://www.rgpbz.ru'}
				className='link-blue text-medium'
				target='_blank'
				rel='noreferrer'
			>
				заповедника “Ростовский”
			</a>{' '}
			или в социальных сетях. 
		</p>
	)

	return (
		<div>
			<Container className='pt-5 pb-sm-5 pb-4'>
				<Row className='d-flex align-items-end justify-content-center mb-5 mb-sm-5 '>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='mb-lg-0 mb-5 mb-sm-5 '
					>
						<TextBox
							element={text_1}
							blue
							mode={4}
							clip={'blue_tl'}
							className='mb-5 mb-sm-5'
						/>
						<TextBox element={text_3} blue mode={1} />
					</Col>
					<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
						<TextBox element={text_2} blue mode={3} className='mb-5 mb-sm-5' />
						<ImgBox src={h1_m4_p1} />
					</Col>
				</Row>
				<Row className='d-flex align-items-center justify-content-center'>
					<Col
						xxl={10}
						xl={10}
						lg={10}
						md={12}
						sm={12}
						xs={12}
						className='mb-lg-0 mb-5 mb-sm-5 offset-lg-1'
					>
						<iframe
							src='https://vk.com/video_ext.php?oid=-193258751&id=456276041&hd=4&autoplay=1'
							width='1920'
							height='1080'
							allow='autoplay; encrypted-media; fullscreen; picture-in-picture;'
							frameborder='0'
							allowfullscreen
						></iframe>
					</Col>
				</Row>
				<Row className='d-flex align-items-center justify-content-center mb-5 mb-sm-5 '>
					<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
						<TextBox
							element={text_5}
							blue
							mode={3}
							className='mb-lg-0 mb-5 mb-sm-5 h-lg-1'
						/>
					</Col>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='mb-lg-0 mb-5 mb-sm-5 '
					>
						<TextBox element={text_4} blue mode={2} className=' h-lg-1' />
					</Col>
				</Row>
				<Row className='d-flex align-items-center justify-content-center mb-5 mb-sm-5 '>
					<Col
						xxl={10}
						xl={10}
						lg={10}
						md={12}
						sm={12}
						xs={12}
						className='mb-lg-0 mb-5 mb-sm-5 offset-lg-1'
					>
						<ImgBox src={h1_m4_p2} mark_class={'mark-double'} />
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export const Hint2_m4 = props => {
	const text_1 = (
		<p className='text-italic'>
			<b>Название.</b>
			<br />
			Русское название этого рода возникло благодаря языкам, относящимся к
			тюркской группе, в которых этому животному соответствует понятие «чагат»
			или «saiɣak».
		</p>
	)
	const text_2 = (
		<p className='text-italic'>
			<b>Популяция.</b>
			<br />С распадом СССР началась неконтролируемая добыча сайгаков с целью
			вывоза рогов в Китай. Согласно данным журнала Geo, в период с 1990 по
			2003-2006 годы численность сайгаков в мире сократилась на 94-97 % - с
			порядка миллиона до 31-62,5 тысячи особей.
		</p>
	)
	const text_3 = (
		<p className='text-italic'>
			<b>Запрет.</b>
			<br />
			Охота на сайгака запрещена во всех странах, где обитает эта антилопа,
			незаконная добыча сайгака является уголовным преступлением, карается
			тюремным заключением и огромным штрафом.
		</p>
	)
	const text_4 = (
		<p className='text-italic'>
			<b>Покровитель.</b>
			<br />
			Покровителем сайгаков у калмыков считался Белый Старец - буддийское
			божество плодородия и долголетия. Запрещалось во время охоты стрелять в
			сайгаков, сбившихся в кучу: считалось, что в это время их доит сам Белый
			Старец.
		</p>
	)
	const text_5 = (
		<p className='text-italic'>
			<b>Браконьерство.</b>
			<br />
			Браконьерство и, прежде всего, незаконная охота на половозрелых самцов
			из-за рогов, является самым мощным фактором негативного воздействия на
			популяции сайгака, ставя вид под угрозу уничтожения. Традиционная
			китайская медицина считает порошок, получаемый из рогов сайгака целебным.
		</p>
	)

	const text_6 = (
		<p className='text-italic'>
			<b>Местонахождение.</b>
			<br />
			Попробовать увидеть сайгака своими глазами можно и в Ростовской области:
			Ассоциация "Живая природа степи" успешно занимается восстановлением и
			сохранением вида!
		</p>
	)

	const text_7 = <p className='text-center text-medium'>Это всё обо мне!</p>

	return (
		<div>
			<Container className='pt-5 pb-sm-5 pb-4'>
				<Row className='d-flex align-items-end justify-content-center mb-5 mb-sm-5 '>
					<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
						<ImgBox
							src={h2_m4_p1}
							className='mb-5 mb-sm-5'
							mark_class={'mark-double'}
						/>
					</Col>
				</Row>
				<Row className='d-flex align-items-start mb-5 mb-sm-5 '>
					<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
						<TextBox
							element={text_1}
							blue
							mode={4}
							className='mb-5 mb-sm-5 h-lg-2 '
						/>
						<TextBox
							element={text_2}
							blue
							mode={4}
							className='mb-5 mb-sm-5 h-lg-2'
						/>
						<TextBox
							element={text_3}
							blue
							mode={4}
							clip={'blue_tr'}
							className='mb-5 mb-sm-5 h-lg-2'
						/>
					</Col>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='mb-lg-0 mb-5 mg-lg-t-p-1'
					>
						<TextBox
							element={text_4}
							blue
							mode={4}
							className='mb-5 mb-sm-5 h-lg-2'
							clip={'blue_tr'}
						/>
						<TextBox
							element={text_5}
							blue
							mode={4}
							className='mb-5 mb-sm-5 h-lg-2'
						/>
						<TextBox element={text_6} blue mode={4} />
					</Col>
				</Row>

				<Row className='d-flex align-items-center justify-content-center mb-5 mb-sm-5 '>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={7}
						sm={7}
						xs={6}
						className='mb-lg-0 mb-5 mb-sm-5 '
					>
						<ImgBox src={saigak} />
					</Col>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={4}
						sm={5}
						xs={6}
						className='mb-lg-0 mb-5 mb-sm-5 '
					>
						<TextBox element={text_7} blue mode={1} className='border-lt' />
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export const Hint3_m4 = props => {
	const fact = (
		<p className='fact-text'>
			Сильные ветра озера Маныч-Гудило, постоянно дующие над водой, поднимают
			волны до 15 метров высотой и так гудят и воют в оврагах, что местные
			жители в древности были уверены: это духи гневаются на людей. Потому и
			назвали озеро Маныч-Гудило. По-татарски «маныч» означает «соленый,
			горький», а «гудило» — от русского слова «гудеть».
		</p>
	)
	const text_1 = (
		<p className='text-medium text-white'>
			Озеро Маныч-Гудило раскинулось на границе трех регионов —{' '}
			<b>Ставропольского края, Ростовской области и Калмыкии</b>. Озеро
			Маныч-Гудило с водой, солёность которой превышает почти вдвое солёность
			Чёрного моря, является одним из крупнейших озер Европы. По озеру проходит
			современная граница между Европой и Азией. 
		</p>
	)

	const text_2 = (
		<p className='text-medium'>
			Сильные ветры, поднимающие на озере большие волны, соленая вода, крутые
			берега озера, действительно, напоминают облик моря.
		</p>
	)

	return (
		<div>
			<Container className='pt-5 pb-sm-5 pb-4'>
				<Row className='d-flex align-items-center justify-content-center mb-5 mb-sm-5 '>
					<Col
						xxl={5}
						xl={5}
						lg={5}
						md={12}
						sm={12}
						xs={12}
						className='mb-lg-0 mb-5 mb-sm-5'
					>
						<TextBox element={text_1} blue mode={3} className='h-xl-3' />
					</Col>
					<Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12}>
						<ImgBox src={h3_m4_p1} mark_class={'mark-d-r-t'} />
					</Col>
				</Row>
				<Row className='d-flex align-items-center justify-content-end mb-5 mb-sm-5 '>
					<Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12}>
						<TextBox element={text_2} blue mode={1} />
					</Col>
				</Row>
			</Container>
			<Container fluid className='mg-b-p-1 p-0'>
				<Fact text={fact} color={4} />
			</Container>
		</div>
	)
}

export const Hint4_m4 = props => {
	const text_1 = (
		<p className='text-medium'>
			<b>Прыткая ящерица</b> - это самая часто встречающаяся рептилия в степях.
			Ее можно встретить в Европе, в Сибири, в Монголии и Китае, ну и, конечно, 
			в донских степях. Размеры могут достигать 20 сантиметров!
		</p>
	)
	const text_2 = (
		<p className='text-medium text-white'>
			Самки окрашены довольно скромно: они имеют зеленовато-бурый или просто
			бурый цвет, а вот самцы весной становятся ярко-зелеными и очень
			агрессивными. Это связано с брачным поведением: самцы активно конкурируют
			за внимание самки и могут устраивать настоящие поединки.
		</p>
	)

	const text_3 = (
		<p className='text-italic'>
			Сама ящерица - насекомоядный хищник, и также служит кормом для более
			крупных животных: ежей, лисиц, цапель, различных соколов и многих других.
			Не брезгуют ящерицами и другие рептилии: змеи-медянки, полозы, гадюки. При
			опасности ящерица умеет отбрасывать хвост, оставляя противнику
			извивающийся обрубок, а сама успевает скрыться.
		</p>
	)

	return (
		<div>
			<Container className='pt-5 pb-sm-5 pb-4'>
				<Row className='d-flex align-items-center justify-content-center mb-5 mb-sm-5 '>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='mb-lg-0 mb-5 mb-sm-5'
					>
						<TextBox element={text_1} blue mode={2} className='h-lg-2' />
					</Col>
					<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
						<TextBox element={text_2} blue mode={3} className='h-lg-2' />
					</Col>
				</Row>

				<Row className='d-flex align-items-center   mb-5 mb-sm-5 '>
					<Col
						xxl={7}
						xl={7}
						lg={8}
						md={12}
						sm={12}
						xs={12}
						className='mb-sm-5 mb-5'
					>
						<ImgBox
							src={h4_m4_p1}
							description={'Самец прыткой ящерицы'}
							description_class={'arrow-tr'}
						/>
					</Col>
					<Col
						xxl={7}
						xl={7}
						lg={8}
						md={12}
						sm={12}
						xs={12}
						className='offset-xl-5 offset-lg-4'
					>
						<ImgBox
							src={h4_m4_p2}
							description={'Самка прыткой ящерицы'}
							description_class={'arrow-tl'}
						/>
					</Col>
				</Row>

				<Row className='d-flex align-items-center  mb-5 mb-sm-5 '>
					<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
						<TextBox element={text_3} blue mode={4} />
					</Col>
					<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
						<ImgBox
							src={h4_m4_p3}
							description={'Ну, привет!'}
							description_class={'animal-t-l-1'}
						/>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export const Hint5_m4 = props => {
	const text_1 = (
		<p className='text-italic'>
			<b>Дрофа</b> - крупная птица, обитающая в степях. Дрофа отличается не
			только внушительными размерами, но и уникальным окрасом, но и сильными
			конечностями, на которых не растут перья. Это является свидетельством
			того, что птица больше приспособлена к перемещению по земле. Окрас птицы
			яркий, включает рыжие, черные, белые и серые оттенки. Несмотря на такой
			пестрый окрас, в степи ее обнаружить очень трудно - она сливается с
			оттенками степи.
		</p>
	)
	const text_2 = (
		<p className='text-medium text-white'>
			Самцы в период токования невероятно преображаются: они распускают свой
			хвост, увеличивают грудь и начинают свои необычные танцы. Дрофы, как и
			многие другие виды пернатых, питаются весьма разнообразной пищей как
			растительного, так и животного происхождения. Дрофа признана одной из
			самых тяжелых летающих птиц. Вес самцов составляет 7–16 кг при длине 1,05
			м, в то время как самки весят в среднем 4–8 кг при длине 0,8 м.
		</p>
	)

	const text_3 = (
		<p className='text-medium'>
			Начиная с 20-го века, территории, на которых обитали дрофы, заметно
			сократились. Вид начали включать в Красные книги и Международные списки.
			Все это связано с исчезновением мест обитания и использованием токсичных
			удобрений на полях.
		</p>
	)

	return (
		<div>
			<Container className='pt-5 pb-sm-5 pb-4'>
				<Row className='d-flex align-items-center justify-content-center mb-5 mb-sm-5 '>
					<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
						<TextBox element={text_1} blue mode={4} clip={'blue_tr'} />
					</Col>
				</Row>

				<Row className='d-flex align-items-center  mb-5 mb-sm-5 '>
					<Col xxl={3} xl={3} lg={12} md={12} sm={12} xs={12}>
						<TextBox
							element={text_2}
							blue
							mode={3}
							className='h-xl-5 mb-5 mb-sm-5 mb-xl-0'
						/>
					</Col>
					<Col xxl={9} xl={9} lg={12} md={12} sm={12} xs={12}>
						<ImgBox
							src={h5_m4_p1}
							description={'Фото Толчеевой С.В.'}
							description_class={'tir-up'}
							mark_class={'mark-d-l-b'}
						/>
					</Col>
				</Row>

				<Row className='d-flex align-items-center  mb-5 mb-sm-5 '>
					<Col
						xxl={9}
						xl={9}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='mb-5 mb-sm-5 mb-lg-0'
					>
						<ImgBox src={h5_m4_p2} description_class={'animal-t-l-1'} />
					</Col>
					<Col xxl={3} xl={3} lg={6} md={12} sm={12} xs={12}>
						<TextBox element={text_3} blue mode={4} className='h-xl-4' />
					</Col>
				</Row>
			</Container>
		</div>
	)
}
