import { Col, Container, Row } from 'react-bootstrap'
import { Fact, ImgBlock, ImgBox, OverlayBox, TextBox } from '../UI_Kit/UI'

import l2p1 from './image/l2_p1.png'
import l2p2 from './image/l2_p2.png'

import l3p1 from './image/l3_p1.png'
import l3p2 from './image/l3_p2.png'
import l3p3 from './image/l3_p3.png'
import l4p1 from './image/l4_p1.png'

import man_3 from './image/man_3.png'

import bobre from './image/bobre.png'
import goose from './image/goose.png'
import l1_m2_p1 from './image/l1_m2_p1.png'
import l1_m4_p12 from './image/l1_m4_p12.png'
import l1_m4_p2 from './image/l1_m4_p2.png'
import l1p1 from './image/l1_p1.jpg'
import l2_m2_p1 from './image/l2_m2_p1.png'
import l2_m2_p2 from './image/l2_m2_p2.png'
import l2_m4_p1 from './image/l2_m4_p1.png'
import l3_m2_p1 from './image/l3_m2_p1.png'
import l3_m2_p2 from './image/l3_m2_p2.png'
import l3_m4_p4 from './image/l3_m4_p4.png'
import l3_m4_p5 from './image/l3_m4_p5.png'
import l5p1 from './image/l5_p1.png'
import l5p2 from './image/l5_p2.png'
import l5p3 from './image/l5_p3.png'
import l5p4 from './image/l5_p4.png'
import l6p1 from './image/l6_p1.png'
import l7p1 from './image/l7_p1.png'
import l7p2 from './image/l7_p2.png'
import l7p3 from './image/l7_p3.png'
import l7p4 from './image/l7_p4.png'
import l8p1 from './image/l8_p1.png'
import l9p11 from './image/l9_1.png'
import l9p12 from './image/l9_p1_2.png'
import l9p13 from './image/l9_p1_3.png'
import l9p14 from './image/l9_p1_4.png'
import l9p2 from './image/l9_p2.png'
import l9p3 from './image/l9_p3.png'
import l9p4 from './image/l9_p4.png'

import l4_m2_p2_1 from './image/l4_m2_p2_1.png'
import l4_m2_p2_2 from './image/l4_m2_p2_2.png'
import l4_m2_p2_3 from './image/l4_m2_p2_3.png'

import l4_m2_p1 from './image/l4_m2_p1.png'
import l5_m2_p1 from './image/l5_m2_p1.png'
import l5_m2_p2 from './image/l5_m2_p2.png'
import l5_m2_p3 from './image/l5_m2_p3.png'
import l5_m2_p4 from './image/l5_m2_p4.png'

import l6_m2_p1 from './image/l6_m2_p1.png'
import l6_m2_p3 from './image/l6_m2_p3.png'

import l7_m2_p1 from './image/l7_m2_p1.png'
import l8_m2_p1 from './image/l8_m2_p1.png'
import l8_m2_p2 from './image/l8_m2_p2.png'
import l8_m2_p3 from './image/l8_m2_p3.png'
import l8_m2_p4 from './image/l8_m2_p4.png'

import l1_m4_p1 from './image/l1_m4_p1.png'
import l1_m4_p3 from './image/l1_m4_p3.png'
import l1_m4_p7 from './image/l1_m4_p7.png'
import l1_m4_p8 from './image/l1_m4_p8.png'
import l2_m3_p1 from './image/l2_m3_p1.png'
import l3_m3_p1 from './image/l3_m3_p1.png'
import l9_m2_p1 from './image/l9_m2_p1.png'

import l1_m4_p4 from './image/l1_m4_p4.png'
import l1_m4_p5 from './image/l1_m4_p5.png'

import l1_m4_p6 from './image/l1_m4_p6.png'
import l2_m4_p2 from './image/l2_m4_p2.png'
import l2_m4_p3 from './image/l2_m4_p3.png'

import bird_blue from './image/bird_blue.png'
import bird_chaika from './image/bird_chaika.png'
import horse from './image/horse.png'
import l0_p1 from './image/l0_p1.png'
import l0_p2 from './image/l0_p2.png'
import l0_p3 from './image/l0_p3.png'
import l1_m3_p1 from './image/l1_m3_p1.png'
import l1_m4_p10 from './image/l1_m4_p10.png'
import l1_m4_p11 from './image/l1_m4_p11.png'
import l1_m4_p9 from './image/l1_m4_p9.png'
import l1p2 from './image/l1_p2.jpg'
import l1p3 from './image/l1_p3.jpg'
import l1p4 from './image/l1_p4.jpg'
import l2_m4_p4 from './image/l2_m4_p4.png'
import l2_m4_p5 from './image/l2_m4_p5.png'
import l3_m4_p1 from './image/l3_m4_p1.png'
import l3_m4_p2 from './image/l3_m4_p2.png'
import l3_m4_p3 from './image/l3_m4_p3.png'
import l3_m4_p6 from './image/l3_m4_p6.png'
import l3_m4_p7 from './image/l3_m4_p7.png'
import l4_m3_p1 from './image/l4_m3_p1.png'
import l4_m4_p1 from './image/l4_m4_p1.png'
import l4_m4_p2 from './image/l4_m4_p2.png'
import man from './image/man.png'
import man_2 from './image/man_2.png'
import mouse_tl from './image/mouse_tl.png'
import mouse_tr from './image/mouse_tr.png'
import rabbit_tr from './image/rabbit_tl.png'

export const Lection0 = props => {
	const text_1 = (
		<p className='text-medium'>
			В 1877 году русский путешественник и первопроходец Николай Михайлович
			Пржевальский возвращался из экспедиции в Центральную Азию. На Зайсанском
			пограничном посту его начальник А.К. Тихонов подарил учёному шкуру и череп
			неизвестной науке лошади.
		</p>
	)
	const text_2 = (
		<p className='text-italic'>
			По его словам, эта лошадь водилась в Южной Джунгарии и была там добыта
			охотником-киргизом. В 1881 году лошадь была описана как самостоятельный
			вид ученым Императорского зоологического музея Иваном Семеновичем
			Поляковым, назвавшим ее лошадью Пржевальского-Полякова. 
		</p>
	)
	const text_3 = (
		<p className='text-medium'>
			Так, благодаря одному подарку в мире был описан новый вид!
		</p>
	)
	const text_4 = (
		<p className='text-medium text-bold'>
			В следующее путешествие Николай Пржевальский смог вживую встретить табун
			этих удивительных лошадей в пустыне Гоби
		</p>
	)

	const text_5 = (
		<p className='text-italic'>
			Лошадь Пржевальского была широко распространена в Европе и Азии,
			но с наступлением цивилизации животное постепенно вытеснялось на восток,
			в самые отдалённые степные районы Центральной Азии, а его популяция быстро
			сокращалась. В 1969 году лошадь Пржевальского видели последний раз в дикой
			природе в Монголии. Лошадь Пржевальского официально признана в исчезнувшей
			в природе и в следующие десятилетия лошадь Пржевальского сохранялась лишь
			в нескольких зоопарках.
		</p>
	)
	const text_6 = (
		<p className='text-medium'>
			Количество оставшихся лошадей Пржевальского по ориентировочным данным
			составляет около 2300 - 2500 особей во всем мире!
		</p>
	)
	const text_7 = (
		<p className='text-bold '>
			Сейчас действуют программы, направленные на восстановление лошади
			Пржевальского.С 2015 г. в России работает{' '}
			<a
				className='color_span_orange text-bold'
				href='https://orenzap.ru/node/19444'
				target='_blank'
				rel='noreferrer'
			>
				центр по восстановлению популяции лошади Пржевальского
			</a>{' '}
			в государственном природном заповеднике «Оренбургский».
		</p>
	)
	return (
		<Container className='pt-5 pb-sm-5 pb-4'>
			<Row className='d-flex align-items-center mb-5'>
				<Col
					xxl={4}
					xl={4}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5 '
				>
					<TextBox
						element={text_1}
						yellow
						mode={1}
						className='h-xl-3'
						clip={'orange_tr'}
					/>
				</Col>
				<Col xxl={8} xl={8} lg={6} md={12} sm={12} xs={12}>
					<ImgBox
						src={l0_p1}
						description='Фото Тимофеенко Ю.В.'
						mark_class={'mark-d-r-t'}
						description_class={'til-down'}
					/>
				</Col>
			</Row>
			<Row className='d-flex align-items-center mb-5'>
				<Col
					xxl={8}
					xl={8}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5 '
				>
					<ImgBox
						src={l0_p2}
						description='Фото Тимофеенко Ю.В.'
						description_class={'til-down'}
					/>
				</Col>
				<Col xxl={4} xl={4} lg={12} md={12} sm={12} xs={12}>
					<TextBox
						element={text_2}
						yellow
						mode={1}
						className='mb-sm-5 mb-5 pt-5 pb-5 pb-sm-5 pt-sm-5'
					/>
					<TextBox
						element={text_3}
						yellow
						mode={2}
						clip={'orange_tr'}
						className='h-xl-1 '
					/>
				</Col>
			</Row>
			<Row className='d-flex align-items-start mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-5 mb-sm-5'
				>
					<TextBox
						element={text_4}
						yellow
						mode={3}
						clip={'yellow_tl'}
						className='mb-sm-5 mb-5'
					/>
					<ImgBox
						src={l0_p3}
						mark_class='mark-d-l-t'
						description='Фото Тимофеенко Ю.В.'
						description_class={'til-down'}
						className='mb-sm-5 mb-5'
					/>
					<TextBox element={text_6} yellow mode={1} />
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<TextBox element={text_5} yellow mode={4} className='mb-sm-5 mb-5' />
					<TextBox element={text_7} yellow mode={1} />
				</Col>
			</Row>
		</Container>
	)
}
export const Lection1 = props => {
	const text_1 = (
		<p className='text-medium'>
			Государственный природный биосферный Заповедник «Даурский» расположен на
			юге Забайкальского края, практически на стыке трех стран: России, Монголии
			и Китая. Он входит состав международного{' '}
			<a
				className='color_span_orange text-medium'
				href='http://daurzapoved.com/index.php/ru/dauria'
				target='_blank'
				rel='noreferrer'
			>
				(российско-монгольско-китайского) заповедника «Даурия»
			</a>
			.Такой статус подчеркивает важность сохранения уникального уголка природы.
		</p>
	)
	const text_2 = (
		<p className='text-italic'>
			Территория заповедника это одно прекрасных мест из мест, где можно
			почувствовать величие, силу и красоту природы. Даурские степи – это
			бесконечные просторы «травяного моря» и бархатные сонные сопки, звонкое
			пение жаворонков, «столбики» любопытных тарбаганов и грациозные танцы
			журавлей... И пока еще многочисленные стада азиатских антилоп - дзеренов,
			готовых в любую секунду умчаться вдаль со скоростью ветра.
		</p>
	)
	const text_3 = (
		<p className='text-medium'>
			Даурия - это и блюдца озер, от маленьких до бескрайних, где вода
			сливается с горизонтом, а окаймляющие песчаные пляжи или заросли камышей и
			тростников служат прибежищем для тысяч уток, гусей, белоснежных лебедей,
			юрких куликов и неугомонных чаек. Светлые, залитые солнцем
			березово-осиновые колки и причудливые скалы Адон-Челона, островные
			сосновые боры среди бескрайней степи, издалека похожие на средневековое
			войско кочевников, – это тоже <b>многоликая Даурия</b>. 
		</p>
	)
	const text_4 = (
		<p className='text-medium'>
			Заповедная территория создает условия для выживания редких видов животных.
			Среди них -{' '}
			<a
				href='http://daurzapoved.com/index.php/ru/home/o-prirode/redkie-vidy/129-mongolskij-dzeren'
				className='color_orange'
				target='_blank'
				rel='noreferrer'
			>
				монгольский дзерен
			</a>{' '}
			и{' '}
			<a
				href='http://daurzapoved.com/index.php/ru/home/o-prirode/redkie-vidy/125-reliktovaya-chajka'
				className='color_orange'
				target='_blank'
				rel='noreferrer'
			>
				реликтовая чайка
			</a>
			,{' '}
			<a
				href='http://daurzapoved.com/index.php/ru/home/o-prirode/redkie-vidy/123-sukhonos'
				className='color_orange'
				target='_blank'
				rel='noreferrer'
			>
				гусь сухонос
			</a>
			,{' '}
			<a
				href='http://daurzapoved.com/index.php/ru/home/o-prirode/redkie-vidy/126-drofa'
				className='color_orange'
				target='_blank'
				rel='noreferrer'
			>
				дрофа
			</a>
			,{' '}
			<a
				href='http://daurzapoved.com/index.php/ru/home/o-prirode/redkie-vidy/127-daurskij-zhuravl'
				className='color_orange'
				target='_blank'
				rel='noreferrer'
			>
				даурский журавль
			</a>
			.  
		</p>
	)

	const text_5 = (
		<p className='text-medium'>
			Сохранить уникальные водно-болотные, степные и лесостепные экосистемы
			Даурии – основная задача заповедника «Даурский».
		</p>
	)
	const text_6 = (
		<p className='text-medium'>
			На первый взгляд, природа здесь скромна, не знает излишеств, но созданное
			ею не оставляет равнодушными даже самых искушенных путешественников. В
			2000 году Всемирный фонд дикой природы включил Даурские степи в число 200
			экорегионов планеты, {' '}
			<b className='text-bold'>
				имеющих особое значение для сохранения жизни на Земле.
			</b>
		</p>
	)
	return (
		<Container className='pt-5 pb-sm-5 pb-4'>
			<Row className='d-flex  mb-5 justify-content-center'>
				<Col
					xxl={3}
					xl={4}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className=' mb-5
					mb-sm-5'
				>
					<TextBox
						element={text_1}
						yellow
						mode={1}
						className='mb-sm-5 mb-5 h-xl-4'
					/>
					<TextBox element={text_4} yellow mode={2} />
				</Col>
				<Col xxl={8} xl={8} lg={12} md={12} sm={12} xs={12}>
					<TextBox element={text_2} yellow mode={4} className='mb-sm-5 mb-5' />
					<ImgBox
						src={l1p4}
						mark_class={'mark-d-l-b'}
						description='Фото Бородина Е. Степи заповедника “Даурия”.'
						description_class='tir-down'
					/>
				</Col>
				
			</Row>
			<Row className='d-flex  mb-5'>
			
				<Col
					xxl={3}
					xl={3}
					lg={4}
					md={12}
					sm={12}
					xs={12}
					className=' mb-lg-0 mb-sm-5 mb-5 mt-5'
				>
					<TextBox
						yellow
						mode={1}
						element={text_5}
						clip={'yellow_tl'}
						className='h-xl-2 h-md-2 h-lg-2'
					/>
				</Col>
				<Col xxl={9} xl={9} lg={8} md={12} sm={12} xs={12} className='mb-5 mt-5'>
					<TextBox
						yellow
						mode={3}
						element={text_3}
						clip={'orange_tr'}
						className='h-xl-2 h-md-2 h-lg-2'
					/>
				</Col>
				<Col xxl={6} xl={7} lg={7} md={12} sm={12} xs={12}>
					<ImgBox src={l1p2} mark_class={'mark-d-l-t'} description='Фото Бородина Е. Дзерен'  description_class='tir-down'/>
				</Col>
				<Col xxl={6} xl={7} lg={7} md={12} sm={12} xs={12}>
					<ImgBox src={l1p3} mark_class={'mark-d-l-t'} description='Фото Горошко О. Даурский ёж.' description_class='tir-down' />
				</Col>
			</Row>

			<Row className='d-flex align-items-end mb-5'>
				<Col
					xxl={6}
					xl={5}
					lg={5}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-5 mb-sm-5'
				>
					<TextBox element={text_6} yellow mode={3} />
				</Col>
				<Col xxl={6} xl={7} lg={7} md={12} sm={12} xs={12}>
					<ImgBox src={l1p1} mark_class={'mark-d-l-t'}  description='Фото Ищенко И. Гуси-сухоносы.' description_class='tir-down'/>
				</Col>
			</Row>
		</Container>
	)
}
export const Lection2 = props => {
	const text_1 = (
		<p className='text-italic'>
			В течение миллионов лет назад наша планета претерпевала изменения.
			Преображался облик Земли: сходились и расходились континенты; появлялись
			и исчезали моря; видовое разнообразие растительного и животного мира
			причудливо заменялось другим. 20–30 миллионов лет назад с изменением
			климата на смену тропическим лесам стали приходить степи. Более прохладный
			и сухой климат не подходил густым лесам, требующим большой влажности
			и тепла.
		</p>
	)
	const text_2 = (
		<span className='text-medium'>
			К тому же в те времена уже появились травянистые растения, которые
			начали заполнять освободившиеся пространства. Так  на нашей планете в
			умеренном климате сформировались открытые равнины –{' '}
			<OverlayBox
				text={'степь'}
				afterText={
					'Степь – это открытое, безлесное пространство, поросшее травянистой растительностью. '
				}
				mode_class={'overlay-tl overlay-bcg-1 text-medium'}
			/>
			.
		</span>
	)
	return (
		<Container className='pt-5 pb-5'>
			<Row className='d-flex align-items-center mb-5 mb-sm-5'>
				<Col
					xxl={10}
					xl={10}
					lg={10}
					md={10}
					sm={12}
					xs={12}
					className='offset-md-1'
				>
					<ImgBox
						src={l2p1}
						mark_class={'mark-double'}
						description={'Фото Узденова А.М. Степи'}
						description_class={' text-medium tir-down'}
					/>
				</Col>
			</Row>
			<Row className='d-flex align-items-start mb-5 mb-sm-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-sm-5 mb-5'
				>
					<TextBox element={text_1} yellow mode={4} className='h-lg-3' />
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} className='h-lg-3'>
					<TextBox element={text_2} yellow mode={1} className='h-100' />
				</Col>
				<Col
					xxl={10}
					xl={10}
					lg={10}
					md={10}
					sm={12}
					xs={12}
					className='mt-5 offset-md-1'
				>
					<ImgBox
						src={l2p2}
						mark_class={'mark-h'}
						description={'Фото Узденова А.М. Степи'}
						description_class={' text-medium til-down'}
					/>
				</Col>
			</Row>
		</Container>
	)
}
export const Lection3 = props => {
	const text_1 = (
		<p className='text-medium'>
			Степь характерна для регионов с контрастным климатом. В таких регионах
			отмечается жаркое засушливое лето и холодная ветреная зима. В степи
			большая разница между дневной и ночной температурой. Перепады могут
			достигать от 15 до 20°С. Похожие перепады наблюдаются и в пустыне.
		</p>
	)
	const text_2 = (
		<p className='text-medium'>
			Несмотря на то, что степь - это царство трав, деревья и даже небольшие
			леса в ней тоже присутствуют. Это <b className='text-bold'>пойменные</b>{' '}
			(растущие по берегам рек) и <b className='text-bold'>байрачные</b>{' '}
			(растущие в оврагах и балках) леса, они служат убежищем для тех животных,
			которым некомфортно на открытой местности.
		</p>
	)
	const text_3 = (
		<p className='text-medium'>
			Летом небольшое количество осадков и высокие температуры (до 50 градусов
			тепла) приводят к <b className='text-bold'>засухам</b>. Высыхают реки и
			водоемы, растительность выгорает под палящим солнцем. Этим она схожа с
			пустыней.
		</p>
	)
	const text_4 = (
		<p className='text-medium'>
			До самого горизонта степь покрывают травы и кустарники, приспособленные к
			экстремальным условиям. В степном пейзаже можно увидеть{' '}
			<b className='text-bold'>балки</b> и <b className='text-bold'>овраги</b>.
		</p>
	)
	const text_5 = (
		<p className='text-medium'>
			Овраг резко обрывается крытым склоном, а склоны балок - покатые, заросшие
			обильными степными травами. Они спасают от сильных ветров{' '}
			<b className='text-bold'>аборигенных</b> жителей степи.
		</p>
	)
	return (
		<Container className='pt-5 pb-5'>
			<Row className='d-flex align-items-center mb-5'>
				<Col
					xxl={8}
					xl={8}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className='mb-xl-0 mb-sm-5 mb-5'
				>
					<ImgBox
						src={l3p1}
						mark_class={'mark-l'}
						description_class={' text-medium til-down'}
						description='Фото Узденова А.М.
Степи'
					/>
				</Col>
				<Col
					xxl={4}
					xl={4}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className='mb-xl-0 '
				>
					<TextBox
						element={text_1}
						yellow
						mode={1}
						clip='orange_tr'
						className='h-xl-3'
					/>
				</Col>
			</Row>
			<Row className='d-flex align-items-start mb-5'>
				<Col xxl={7} xl={7} lg={6} md={12} sm={12} xs={12}>
					<TextBox
						element={text_2}
						yellow
						mode={2}
						clip='yellow_tl'
						className='h-lg-2 mb-lg-0 mb-sm-5 mb-5'
					/>
				</Col>
				<Col xxl={5} xl={5} lg={6} md={12} sm={12} xs={12}>
					<TextBox element={text_3} yellow mode={3} className='h-lg-2' />
				</Col>
			</Row>
			<Row className='d-flex align-items-start mb-5'>
				<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
					<TextBox element={text_4} yellow mode={4} className='h-lg-1 ' />
				</Col>
			</Row>

			<Row className='d-flex align-items-center mb-5'>
				<Col xxl={6} xl={6} lg={12} md={12} sm={12} xs={12} className='mb-sm-5'>
					<ImgBox
						src={l3p2}
						mark_class={'mark-h-t-l'}
						description='Балки'
						description_class={' text-medium til-down'}
					/>
				</Col>
				<Col
					xxl={6}
					xl={6}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-5 md-sm-5'
				>
					<ImgBox
						src={l3p3}
						description='Овраги'
						mark_class={'mark-h-b-r'}
						description_class={' text-medium til-up'}
					/>
				</Col>
			</Row>
			<Row className='d-flex align-items-center'>
				<Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} className='mb-sm-5'>
					<ImgBox
						src={mouse_tr}
						description='Чем отличаются балки от оврагов?'
						description_class={'text-medium animal-t-r'}
					/>
				</Col>
				<Col
					xxl={8}
					xl={8}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-5 md-sm-5 '
				>
					<TextBox element={text_5} yellow mode={1} className=' mb-sm-5 mb-5' />
				</Col>
			</Row>
		</Container>
	)
}
export const Lection4 = props => {
	const text_1 = (
		<p className='text-medium'>
			Бескрайние равнины очень плодородны. Еще В.В. Докучаев писал о великом
			русском черноземе.
		</p>
	)
	const text_2 = (
		<p className='text-medium'>
			«Чернозем - это плодороднейшая почва, которая тысячелетиями формировалась
			под богатым степным травостоем в условиях засушливого климата с
			чередованием влажных и сухих лет». (Чибилев, 1990).
		</p>
	)
	const text_3 = (
		<p className='text-italic'>
			Много солнца, длинное и теплое лето, плодородные почвы – все это позволяет
			выращивать здесь многие ценные культуры. Издавна степь называли{' '}
			<b>житницей России</b>.
		</p>
	)
	const fact = (
		<p className='fact-text'>
			Житница происходит от слова <b>“жито”</b>. Житом в древние времена
			называли хлеб, ячмень или другие злаки. В современный период житница - это
			территория, которая из-за богатства почвы и благоприятного для этих
			культур климата производит большое количество пшеницы или другого зерна.
		</p>
	)
	return (
		<div>
			<Container className='pt-5 pb-5'>
				<Row className='d-flex align-items-center '>
					<Col
						xxl={10}
						xl={10}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						className=' mb-sm-5 mb-5 offset-xl-1'
					>
						<ImgBox
							src={l4p1}
							mark_class={'mark-l h-xl-4'}
							description_class={' text-medium tir-up'}
							description='Архип Иванович Куинджи
						«Степь. Нива». 1875 г.'
						/>
					</Col>
				</Row>
				<Row className='d-flex align-items-end mg-lg-t-m-1'>
					<Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
						<TextBox
							element={text_1}
							doubleBox
							yellow
							mode={1}
							className=' mg-sm-b-p-1 mb-5 '
						/>
						<TextBox
							element={text_2}
							yellow
							mode={1}
							className='mb-lg-0 mb-5 mb-sm-5 h-lg-3'
						/>
					</Col>
					<Col
						xxl={7}
						xl={7}
						lg={7}
						md={12}
						sm={12}
						xs={12}
						className='mg-lg-t-p-1 h-lg-3'
					>
						<TextBox element={text_3} yellow mode={4} className='h-lg-3' />
					</Col>
				</Row>
			</Container>
			<Container fluid className='p-0'>
				<Row className='d-flex align-items-start mg-sm-b-p-1 mg-xs-b-p-1'>
					<Fact text={fact} color={1} />
				</Row>
			</Container>
		</div>
	)
}
export const Lection5 = props => {
	const text_1 = (
		<p className='text-italic'>
			Степи и лесостепи – основные поставщики хлеба в нашей стране.
		</p>
	)
	const text_2 = (
		<p className='text-medium'>
			Чтобы попасть на полки магазина, хлебу пришлось проделать долгий путь,
			но начало этого пути — в наших степях. Пшеница довольно требовательна
			к теплу и плохо переносит кислые почвы, поэтому её посевы, в основном,
			сосредоточены в южных степных районах страны.
		</p>
	)

	const text_3 = (
		<p className='text-bold'>
			Кроме пшеницы, здесь можно встретить еще кукурузу, подсолнечник, клевер и
			многие другие культуры. В России и в Америке уже на рубеже ХIХ и ХХ веков
			степь начала активно использоваться людьми для земледелия и
			животноводства. Проезжая по степной зоне, мы можем наблюдать, как чередой
			тянутся сельскохозяйственные поля, разделенные лесополосами; и пастбища,
			на которых кормятся, коровы, овцы и домашние лошади.
		</p>
	)
	const text_4 = (
		<p className='text-bold'>
			Но не надо забывать об еще одном богатстве степей. Степи - кладезь
			лекарственных трав. Издревле людей привлекали целебные свойства растений.
			Знахари, травники, ведуньи, собиратели… В общинах по-разному называли
			человека, который мог лечить от болезней с помощью растений. Благодаря
			травничеству складывались обряды, ритуалы и приметы.
		</p>
	)
	const text_5 = (
		<p className='text-italic'>
			<b>Ромашка</b>, подорожник, клевер, тысячелистник, <b>шалфей</b>, чабрец и
			многие другие растения используются для лечения болезней и для
			приготовления травяных чаев. До сих пор многие степные растения
			используются местными жителями в лечебных целях.
		</p>
	)

	return (
		<Container className='pt-5 pb-5'>
			<Row className='d-flex align-items-center mb-5'>
				<Col xxl={4} xl={4} lg={12} md={12} sm={12} xs={12}>
					<TextBox
						element={text_1}
						yellow
						mode={4}
						className=' mg-sm-b-p-1 mb-5 '
					/>
					<TextBox
						element={text_2}
						yellow
						mode={1}
						className=' h-xxl-3 h-xl-2 mb-xl-0 mb-sm-5 mb-5'
					/>
				</Col>
				<Col
					xxl={8}
					xl={8}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className='mb-xl-0 mb-sm-5 '
				>
					<ImgBox src={l5p1} mark_class={'mark-d-r-b '} />
				</Col>
			</Row>
			<Row className='mb-5'>
				<Col
					xxl={5}
					xl={5}
					lg={5}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-5 h-auto'
				>
					<TextBox
						element={text_3}
						yellow
						mode={3}
						className='h-100'
						clip={'orange_tl'}
					/>
				</Col>
				<Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12} className=''>
					<ImgBox src={l5p2} />
				</Col>
			</Row>
			<Row className='mb-5'>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} className=' mb-5'>
					<TextBox
						element={text_4}
						yellow
						mode={2}
						className='h-lg-2 '
						clip={'yellow_tr'}
					/>
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} className=''>
					<TextBox element={text_5} yellow mode={4} className='h-lg-3 mb-5' />
				</Col>
			</Row>
			<Row className='mg-lg-t-m-1 mb-5 d-flex align-items-end'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-5 '
				>
					<ImgBox
						src={l5p3}
						description={
							'Фото Тимофеенко Ю.В. Шалфей в заповеднике “Ростовский”.'
						}
						description_class={'til-up'}
					/>
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} className=''>
					<ImgBox
						src={l5p4}
						description={'Ромашковое поле'}
						description_class={'tir-down'}
					/>
				</Col>
			</Row>
		</Container>
	)
}
export const Lection6 = props => {
	const text_1 = (
		<p className='text-italic'>
			Степь характерна для регионов с контрастным климатом. В таких регионах
			отмечается жаркое засушливое лето и холодная ветреная зима. В степи
			большая разница между дневной и ночной температурой. Перепады могут
			достигать от 15 до 20°С. Похожие перепады наблюдаются и в пустыне.
		</p>
	)
	const text_2 = (
		<p className='text-medium'>
			В зависимости от материка степь может называться{' '}
			<b>прерией, пампасами, льяносом, саванной</b> и <b>туссоками</b>.
		</p>
	)
	const text_3 = (
		<p className='text-bold'>
			Самая большая зона степей находится в Евразии. Великая Евразийская степь
			тянется от Монголии до Румынии. А это более 7000 километров!
		</p>
	)
	const text_4 = (
		<p className='text-italic'>
			Степная зона России простирается от Черного моря до Алтая на юге страны.
			Визуальной северной границей является Тула, реки Кама и Белая. На юге
			степи доходят до Кавказских гор.
		</p>
	)
	const text_5 = (
		<p className='text-medium'>
			Часть зоны лежит на Восточно-Европейской равнине, другая
			частьрасполагается на Западно-Сибирской равнине. При движении с юга на
			север степные ландшафты встречаются еще в котловинах Забайкалья.
		</p>
	)
	const text_6 = (
		<p className='text-medium'>
			Зона степи граничит с лесостепью на севере, а также - полупустынями и
			пустынями на юге. Природные условия на территории степи неодинаковые.
			<b>Отсюда разница в составе растительного мира.</b>
		</p>
	)

	return (
		<Container className='pt-5 pb-5'>
			<Row className='d-flex align-items-end  mb-5'>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<TextBox
						element={text_1}
						yellow
						mode={4}
						className=' mb-lg-0 mb-5 h-lg-2'
					/>
				</Col>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className=' d-flex align-items-end'
				>
					<TextBox element={text_2} yellow mode={1} className='h-lg-2' />
				</Col>
			</Row>
			<Row className='d-flex align-items-end  mb-5'>
				<Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
					<TextBox
						element={text_3}
						yellow
						mode={3}
						className=' mb-lg-0 mb-5 h-lg-2'
					/>
				</Col>
				<Col
					xxl={8}
					xl={8}
					lg={8}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-sm-5 d-flex align-items-end'
				>
					<TextBox element={text_4} yellow mode={4} className='h-lg-2' />
				</Col>
			</Row>

			<Row>
				<Col
					xxl={10}
					xl={10}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					className=' mb-5 offset-lg-1'
				>
					<ImgBox src={l6p1} />
				</Col>
			</Row>
			<Row className='d-flex align-items-end  mb-5 d-flex align-items-center justify-content-center'>
				<Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
					<TextBox
						element={text_5}
						yellow
						mode={1}
						className=' mb-lg-0 mb-5 h-lg-2'
						doubleBox_up
					/>
				</Col>
				<Col
					xxl={5}
					xl={5}
					lg={5}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-sm-5 d-flex align-items-end'
				>
					<TextBox
						doubleBox_up
						element={text_6}
						yellow
						mode={1}
						className='h-lg-2'
					/>
				</Col>
			</Row>
		</Container>
	)
}
export const Lection7 = props => {
	const text_1 = (
		<p className='text-medium'>
			Все мы знаем, что леса бывают хвойные, лиственные, смешанные. Так и степи
			богаты своим многообразием. Ученые насчитывают более двадцати
			разновидностей степных экосистем! У каждой из них есть свои особенности,
			своя флора и фауна.
		</p>
	)
	const text_2 = (
		<ul className='list'>
			<li className='list-item text-bold mb-4'>горные</li>
			<li className='list-item text-bold mb-4'>луговые (разнотравные)</li>
			<li className='list-item text-bold mb-4'>настоящие или ковыльные</li>
			<li className='list-item text-bold mb-4'>сазовые</li>
			<li className='list-item text-bold'>пустнынные</li>
		</ul>
	)

	const text_3 = (
		<p className='text-medium'>
			<b>Луговые (разнотравные)</b>. В таких местах можно встретить самое
			большое разнообразие растительного мира. Эти зоны расположены на границе с
			лесами, их почва более плодородна. Эти степи занимают большую часть
			европейской России и Западную Сибирь.
		</p>
	)
	const text_4 = (
		<p className='text-medium'>
			<b>Горные.</b> Для них характерна пышная растительность. Степи Кавказа и
			горного Крыма – яркий пример этого типа.
		</p>
	)
	const text_5 = (
		<p className='text-medium'>
			<b>Сазовые.</b> В них распространены растения, у которых есть надземные
			части, приспособленные к длительным засухам, однако, им все же нужно
			получать увлажнение из почвы. Такие степи часто формируются по берегам
			крупных рек и в низинах.
		</p>
	)
	const text_6 = (
		<p className='text-medium'>
			<b>Настоящие или ковыльные.</b> Здесь преобладают злаковые растения, чаще
			всего – ковыль.
		</p>
	)
	const text_7 = (
		<p className='text-medium'>
			<b>Пустынные.</b> Почва здесь не плодородна, поэтому встречаются, в
			основном, неприхотливые растения: полынь, прутняк. Порой некогда богатые
			природные зоны превращаются в такие степи из-за разрушительной
			деятельности человека и климата.
		</p>
	)

	return (
		<Container className='pt-5 pb-5'>
			<Row className='d-flex align-items-center  mb-5'>
				<Col xxl={4} xl={4} lg={4} md={4} sm={7} xs={7}>
					<ImgBox src={goose} />
				</Col>
				<Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12}>
					<TextBox
						element={text_1}
						yellow
						mode={1}
						className=' mb-lg-0 mb-5 border-lt'
					/>
				</Col>
			</Row>
			<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className='mb-5'>
				<h1 className='text-subtitle'>
					Давайте рассмотрим основные
					<br />
					виды степей:
				</h1>
			</Col>
			<Row className='d-flex align-items-center justify-content-center mb-5'>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<TextBox element={text_2} yellow mode={5} dote className='h-100' />
				</Col>
			</Row>
			<Row className='d-flex mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-5 '
				>
					<TextBox
						element={text_4}
						yellow
						mode={2}
						clip='yellow_tr'
						className='h-100'
					/>
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} className='mb-lg-0'>
					<ImgBox src={l7p1} mark_class={'mark-d-r-t'} />
				</Col>
			</Row>
			<Row className='d-flex mb-5'>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0'
				>
					<TextBox element={text_3} yellow mode={3} className='h-100' />
				</Col>
			</Row>
			<Row className='d-flex mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-5 '
				>
					<ImgBox src={l7p3} mark_class={'mark-h'} />
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} className='mb-lg-0 '>
					<TextBox
						element={text_6}
						yellow
						mode={3}
						clip='yellow_tr'
						className='h-100'
					/>
				</Col>
			</Row>
			<Row className='d-flex mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-5 '
				>
					<TextBox
						element={text_5}
						yellow
						mode={2}
						clip='yellow_tr'
						className='h-100'
					/>
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} className='mb-lg-0'>
					<ImgBox src={l7p2} mark_class={'mark-d-l-b'} />
				</Col>
			</Row>

			<Row className='d-flex mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-5 '
				>
					<ImgBox src={l7p4} mark_class={'mark-h'} />
				</Col>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-5 '
				>
					<TextBox
						element={text_7}
						yellow
						mode={3}
						clip='yellow_tr'
						className='h-100'
					/>
				</Col>
			</Row>
		</Container>
	)
}
export const Lection8 = props => {
	const text_1 = (
		<p className='text-italic'>
			На слух степь — <b>оркестр контрастов</b>. Это ощущение сильных резких
			движений воздуха, часто чередующихся с периодами затишья. Кузнечики,
			саранча, жаворонки, зяблики, чеканы, стрепеты, луни и многие другие
			создают уникальную мелодию природы.
		</p>
	)
	const text_2 = (
		<p className='text-medium'>
			Как мы выяснили, степь для проживания достаточно сурова. Здесь нет густых
			лесов, многочисленных быстрых рек. Зато есть сильный порывистый ветер,
			гуляющий по бескрайним просторам, и резкие перепады температур. Даже к
			таким условиям животные очень хорошо приспособились.
		</p>
	)

	const text_3 = (
		<p className='text-bold'>
			Начнем с насекомых. Огромное количество этих небольших животных обитает в
			почве, на поверхности земли и в растениях. Присутствие насекомых очень
			важно для растений: они участвуют в формировании почвы и опыляют цветковые
			растения. Без насекомых не существовало бы цветов! Они являются кормом для
			многих животных. Без них степь полноценно существовать не может.
		</p>
	)
	const text_4 = (
		<p className='text-medium'>
			<b>Степная дыбка</b> - самый крупный кузнечик в степи. Узнать ее можно,
			прежде всего, по необычной форме тела. Размеры зеленого кузнечика (того
			самого, которого мы считаем обычным кузнечиком) - 28-36 мм. А длина тела
			степной дыбки может достигать 80 миллиметров!
		</p>
	)
	const text_5 = (
		<p className='text-medium'>
			Ее местом обитания являются ковыльные целинные степи со
			злаково-разнотравной растительностью.
		</p>
	)
	const text_6 = (
		<p className='text-italic'>
			Активность степной дыбки приходится на ночное время суток. Степная дыбка
			охотится на кобылок и кузнечиков, а также на иных насекомых – богомолов,
			небольших жуков и клопов. Выходя на охоту, дыбка может простоять, застыв,
			в одной и той же позе в течение несколько часов, чтобы выловить хорошую
			добычу. Поймав свою жертву, степная дыбка крепко держит тело несчастной и
			делает смертельный укус. После обильного приема пищи, степная дыбка
			переходит в неподвижное состояние.
		</p>
	)
	const text_7 = (
		<p className='fact-text'>
			У дыбки нет самцов. Самки откладывают неоплодотворенные яйца, из которых
			выходят только самки. Такой способ размножения называется{' '}
			<b>партеногенезом</b>.
		</p>
	)
	const text_8 = (
		<p className='text-bold'>
			К сожалению, численность вида сокращается из-за интенсивного разрушения
			степных экосистем. Этот вид находится в Красной книге.
		</p>
	)
	

	return (
		<div>
			<Container className='pt-5 pb-5'>
				<Row className='d-flex align-items-center  mb-5'>
					<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
						<TextBox
							element={text_1}
							yellow
							mode={4}
							className=' mb-lg-0 mb-5 h-lg-2'
						/>
					</Col>
					<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
						<TextBox
							element={text_2}
							yellow
							mode={1}
							className=' mb-lg-0 h-lg-2'
						/>
					</Col>
				</Row>

				<Row className='d-flex flex-lg-row flex-sm-column-reverse  flex-column-reverse justify-content-lg-between  mb-5'>
					<Col
						xxl={3}
						xl={3}
						lg={3}
						md={4}
						sm={5}
						xs={5}
						className='h-lg-3 d-flex align-items-lg-end align-items-sm-start mg-xs-b-p-1'
					>
						<ImgBox
							src={bobre}
							description='Если бы не было маленьких помощников - насекомых, то степь осталась бы без пестрых пейзажей из тюльпанов, ирисов, льна, шалфея и многих других цветов.'
							description_class='animal-t-r'
						/>
					</Col>
					<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} className='mb-5'>
						<TextBox
							element={text_3}
							yellow
							mode={1}
							doubleBox_up
							className='h-lg-3 '
						/>
					</Col>
				</Row>

				<Row className='mb-5'>
					<Col
						xxl={10}
						xl={10}
						lg={10}
						md={12}
						sm={12}
						xs={12}
						className='offset-lg-1'
					>
						<ImgBox src={l8p1} mark_class={'mark-double'} />
					</Col>
				</Row>
				<Row className='d-flex align-items-end  mb-5 mg-lg-t-m-1'>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='mb-sm-5 mb-lg-0'
					>
						<Row className='d-flex  flex-column mb-sm-0 mb-5'>
							<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
								<TextBox
									element={text_4}
									yellow
									mode={1}
									doubleBox
									className=' mb-5 h-lg-2'
								/>
							</Col>
							<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
								<TextBox
									element={text_5}
									yellow
									mode={1}
									className=' mb-lg-0 h-lg-1'
								/>
							</Col>
						</Row>
					</Col>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className=' pd-lg-t-p-1'
					>
						<TextBox element={text_6} yellow mode={4} />
					</Col>
				</Row>
			</Container>
			<Container fluid className='p-0'>
				<Row className='mb-5'>
					<Fact text={text_7} color={1} />
				</Row>
			</Container>
			<Container>
				<Row className='mb-5 justify-content-center'>
					<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className='mb-5'>
						<TextBox
							element={text_8}
							yellow
							mode={2}
							clip={'yellow_tr'}
							className={'h-100'}
						/>
					</Col>
					
				</Row>
			</Container>
		</div>
	)
}
export const Lection9 = props => {
	const imgMap = [
		{
			src: l9p11,
			name: 'Стадная жизнь',
		},
		{
			src: l9p12,
			name: 'Норы',
		},
		{
			src: l9p13,
			name: 'Гнёзда на земле',
		},
		{
			src: l9p14,
			name: 'Движение',
		},
	]
	const text_1 = (
		<p className='text-medium'>
			Млекопитающие приспособились к жизни по-разному. Ниже описаны несколько
			способов, которые помогли животным адаптироваться к открытому ландшафту,
			суровому климату и защититься от хищников, нападающих с неба.
		</p>
	)
	const text_2 = (
		<p className='text-italic'>
			<b>Стадная жизнь.</b> Чтобы спастись от хищников, многие травоядные
			животные держатся стадами. Пока одни особи едят или отдыхают, другие
			осматривают окрестности. Таким образом, хищнику будет сложно остаться
			незамеченным. Куланы, становясь задом к ветру, образуют плотное кольцо,
			которое создает укрытие от сильных ветров для самок с жеребятами.
		</p>
	)

	const text_3 = (
		<p className='text-bold'>
			<b>Норы.</b> Маленьким зверькам помогает умение строить норы под землей.
			Типичными представителями степной фауны являются: суслик, степная пищуха,
			степной сурок, большой тушканчик, джунгарский хомячок.
		</p>
	)
	const text_4 = (
		<p className='text-bold'>
			В норах - благоприятный микроклимат. Здесь можно прятать запасы корма и на
			длительное время впадать в спячку, не опасаясь за свою жизнь. Для норников
			важно как можно раньше заметить угрозу или получить предупреждение от
			соседа, чтобы успеть спрятаться в своих норах.
		</p>
	)
	const text_5 = (
		<p className='text-bold'>
			Суслики и сурки присаживаются на задние лапы возле нор, вытягиваются в
			полный рост для изучения местности. Стойка «столбиком» — характерный
			ландшафтный признак степей. Так ведут себя не только грызуны, но и хищники
			— ласки, хорьки. Норные грызуны, чтобы иметь хороший обзор, специально
			предпочитают места с разреженным травостоем (то есть там, где не очень
			густая трава), где и селятся большими колониями.
		</p>
	)
	const text_6 = (
		<p className='fact-text'>
			Жизнь 72 из 92 видов степных млекопитающих Евразии связана с норами.
		</p>
	)
	const text_7 = (
		<p className='text-medium'>
			<b>Гнезда на земле.</b> Птицы тоже являются важными и очень заметными
			обитателями степей. В траве гнездятся не только жаворонки, но и стрепеты,
			дрофы и даже такой гигант, как степной орел.
		</p>
	)
	const text_8 = (
		<p className='text-medium'>
			<b>Движение.</b> Степные копытные обладают исключительно быстрым бегом, а
			также хорошим зрением. Они выносливы, что позволяет им проходить многие
			километры в поисках воды. Раньше в степях обитали дикие лошади, туры,
			сайгаки и другие крупные животные. Крупные млекопитающие вносят важный
			вклад в формирование степной экосистемы, поддерживая естественный
			растительный покров степи.
		</p>
	)

	return (
		<div>
			<Container className='pt-5 pb-5'>
				<Row className='d-flex align-items-center  mb-5'>
					<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
						<ImgBlock element={text_1} imgs={imgMap} yellow mode={1} />
					</Col>
				</Row>

				<Row className='mb-5'>
					<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
						<TextBox element={text_2} yellow mode={4} />
					</Col>
				</Row>

				<Row className='mb-5'>
					<Col
						xxl={4}
						xl={4}
						lg={4}
						md={12}
						sm={12}
						xs={12}
						className='mb-5 mb-lg-0'
					>
						<TextBox
							element={text_3}
							yellow
							mode={2}
							className='h-100 '
							clip={'yellow_tl'}
						/>
					</Col>
					<Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
						<Row className='d-flex  flex-column '>
							<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
								<TextBox element={text_4} yellow mode={3} className=' mb-5 ' />
							</Col>
							<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
								<TextBox
									element={text_5}
									yellow
									mode={3}
									className=' mb-0'
									clip={'orange_tr'}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>

			<Container fluid className='p-0'>
				<Row className='mb-5'>
					<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
						<Fact text={text_6} color={1} />
					</Col>
				</Row>
			</Container>
			<Container>
				<Row className='mb-5'>
					<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className='mb-5'>
						<ImgBox src={l9p2} mark_class={'mark-d-l-t'} />
					</Col>
					<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className='mb-5'>
						<ImgBox
							src={l9p3}
							mark_class={'mark-d-r-b'}
							description='Фото Тимофеенко Ю.В. Бизоны в степи'
							description_class='tir-up'
						/>
					</Col>
				</Row>
				<Row className='mb-5'>
					<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
						<TextBox
							element={text_7}
							yellow
							mode={1}
							className='h-lg-2 h-md-3 mb-md-0 mb-5'
						/>
					</Col>
					<Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
						<TextBox
							element={text_8}
							yellow
							mode={1}
							className='h-lg-2 h-md-3 mb-0'
						/>
					</Col>
				</Row>
				<Row className='mb-5'>
					<Col
						xxl={12}
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						className='mb-5'
					>
						<ImgBox
							src={l9p4}
							description='Фото Кирилюка В. Дзерены в заповеднике.'
							description_class='tir-down'
						/>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export const Lection1_m2 = props => {
	const text_1 = (
		<p className='text-medium text-white'>
			Первые люди появились в степях Евразии очень давно. Обширные степные
			пространства привлекали наших далёких предков плодородными землями,
			реками, богатыми рыбой, обильными пастбищами, где можно было встретить
			самых разных животных и обеспечить пропитанием свои стада. Открытые
			пространства позволили в короткое время заселить всю территорию Великой
			Степи.
		</p>
	)
	const text_2 = (
		<p className='text-italic'>
			Сначала здесь обитали <b className='text-purple'>неандертальцы</b>,
			а затем им на смену пришли <b className='text-purple'>кроманьонцы</b> —
			люди современного типа, наши с вами предки. Они занимались собирательством
			и охотой, а орудия труда и оружие изготавливали из камня. Поэтому первый
			период истории человечества называется каменным веком.
		</p>
	)
	const text_3 = (
		<p className='text-medium text-white'>
			Постоянных поселений у древних людей ещё не было, поскольку они вынуждены
			были постоянно двигаться вслед за стадами животных, на которых охотились.
			Однако они организовывали временные охотничьи лагеря — стоянки. На таких
			стоянках археологи находят каменные орудия, а также кости животных.
			Поэтому мы знаем, что добычей степных охотников становились бизоны, дикие
			быки-туры, олени, косули и сайгаки, лисы, волки, медведи и другие
			животные.
		</p>
	)

	return (
		<Container className='pt-5 pb-5'>
			<Row className='d-flex align-items-start mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
				>
					<TextBox
						element={text_1}
						purple
						mode={3}
						className='mb-lg-4 mb-sm-5 mb-5'
					/>
					<ImgBox
						src={l1_m2_p1}
						description='Неандерталец и кроманьонец'
						description_class='til-down'
					/>
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<TextBox element={text_2} purple mode={4} className=' mb-5' />
					<TextBox element={text_3} purple mode={2} />
				</Col>
			</Row>
		</Container>
	)
}
export const Lection2_m2 = props => {
	const text_1 = (
		<p className='text-medium'>
			Важным этап в жизни человека стал процесс одомашнивания диких животных.
			Первым был одомашнен волк, со временем ставший домашней собакой. Волки,
			а затем собаки помогали человеку охотиться, а затем стали использоваться
			и в качестве сторожей.
		</p>
	)
	const text_2 = (
		<p className='text-medium'>
			Так вот кому мы должны сказать «спасибо» за наших четверолапых любимцев —
			людям каменного века!
		</p>
	)
	const link = (
		<a
			href='https://ru.wikipedia.org/wiki/Происхождение_домашней_собаки#'
			className='text-link'
			target='_blank'
			rel='noreferrer'
		>
			Копия наскального изображения волкоподобного пса. Пещера Фон де Госм,
			Франция. 19 тыс. лет назад.
		</a>
	)
	return (
		<Container>
			<Row className='d-flex align-items-center justify-content-start mb-5'>
				<Col xxl={4} xl={4} lg={12} md={12} sm={12} xs={12} className='md-md-0'>
					<TextBox
						element={text_1}
						purple
						mode={1}
						className='mb-xl-0 mb-sm-5 mb-5 h-xl-2'
					/>
				</Col>
				<Col xxl={8} xl={8} lg={12} md={12} sm={12} xs={12}>
					<ImgBox
						src={l2_m2_p1}
						description={link}
						description_class='tir-down description-big'
					/>
				</Col>
			</Row>
			<Row className='d-flex align-items-center justify-content-center mb-5'>
				<Col
					xxl={8}
					xl={8}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className=' mb-sm-5 mb-5 '
				>
					<ImgBox
						src={l2_m2_p2}
						description={
							'Калмыков Григорий Одиссеевич.Крымская степь. Холст, масло. 136,8х213. 1899.'
						}
						mark_class={'mark-h'}
						description_class='til-down description-big'
					/>
				</Col>
				<Col xxl={4} xl={4} lg={12} md={12} sm={12} xs={12}>
					<TextBox
						element={text_2}
						purple
						mode={1}
						className='mb-xl-0 h-xl-2'
						clip={'blue_tr'}
					/>
				</Col>
			</Row>
		</Container>
	)
}
export const Lection3_m2 = props => {
	const text_1 = (
		<p className='text-medium '>
			Заповедник «Аскания-Нова» — первый в России частный заповедник, основанным
			бароном Ф.Э. Фальц-Фейном в 1874 г. У заповедника очень богатая
			и интересная история создания.
		</p>
	)
	const text_2 = (
		<p className='text-medium'>
			Степь в «Аскания-Нова» простиралась целинным участком и имела большое
			значение для природы. Когда барон Фридрих Фальц-Фейн обустраивал своё
			поместье, то, вероятно, и не предполагал, что станет одним из основателей
			заповедного дела в России.
		</p>
	)
	const text_3 = (
		<p className='text-italic '>
			Ф.Э. Фальц-Фейн отказался от распашки земельных угодий и объявил «защитным
			на вечные времена» собственный участок целинной степи. Это уберегло степь
			от разрушения стадами домашнего скота и засеивания культурами.
		</p>
	)
	const text_4 = (
		<p className='text-medium text-white'>
			Фридрих Фальц-Фейн говорил о своей цели так: «В Аскании должен быть создан
			величайший зоопарк мира, рай для животных в огромном заповеднике. Каждое
			растение станет жить, как предопределено природой. И животные, которые
			здесь будут жить, не должны испытывать никакого страха при шагах человека,
			который к ним приближается. Спокойно и безмятежно они должны строить свои
			гнёзда и норы, выращивать своих детёнышей… Степь должна принадлежать
			животным».
		</p>
	)

	const text_5 = (
		<p className='text-medium text-white'>
			Только в «Аскания-Нова» можно увидеть в степи пасущихся у воды зебр,
			отдыхающих верблюдов или резвящуюся стайку антилоп.
		</p>
	)

	const text_6 = (
		<p className='text-italic'>
			Живут здесь африканские страусы и европейские лани, сайгаки, буйволы,
			бизоны, яки, пятнистые олени и горные козлы и другие представители
			животного мира. «Аскания-Нова» — это дом для многих видов животных родом
			из Африки, Азии, Европы и Америки.
		</p>
	)
	const text_7 = (
		<p className='text-medium'>
			Фридрих Фальц-Фейн также внёс большой вклад в сохранение лошади
			Пржевальского. Благодаря его питомнику, увеличилось количество особей
			и происходил обмен между зоопарками. Сейчас в мире насчитывается чуть
			больше 2300 лошадей Пржевальского, и они произошли от тех самых
			одиннадцати особей, завезённых основателем заповедника «Аскания-Нова».
		</p>
	)

	return (
		<Container className='pt-5 pb-5'>
			<Row className='d-flex align-items-center justify-content-center '>
				<Col
					xxl={11}
					xl={11}
					lg={11}
					md={12}
					sm={12}
					xs={12}
					className=' mb-5
					mb-sm-5 offser-lg-1'
				>
					<ImgBox src={l3_m2_p1} mark_class='mark-double' />
				</Col>
			</Row>
			<Row className='mg-lg-t-m-1 mb-5 mb-sm-5 d-flex align-items-end justify-content-end'>
				<Col xxl={4} xl={4} lg={5} md={12} sm={12} xs={12}>
					<TextBox
						element={text_1}
						purple
						doubleBox
						mode={1}
						className=' mb-5 h-lg-2'
					/>
					<TextBox
						element={text_2}
						purple
						mode={1}
						className='h-lg-3 mb-lg-0 mb-sm-5 mb-5'
					/>
				</Col>
				<Col
					xxl={8}
					xl={8}
					lg={7}
					md={12}
					sm={12}
					xs={12}
					className='mg-lg-t-p-1  '
				>
					<TextBox element={text_3} purple mode={4} className=' mb-5 ' />
					<TextBox
						element={text_4}
						purple
						mode={2}
						clip='blue_tr'
						className=' h-lg-2'
					/>
				</Col>
			</Row>
			<Row className='mb-5 mb-sm-5'>
				<Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
					<TextBox
						element={text_5}
						purple
						mode={3}
						className='h-lg-2 mb-5'
						clip='pink_tl'
					/>
				</Col>
				<Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12}>
					<TextBox
						element={text_6}
						purple
						mode={4}
						className='h-lg-2 mb-lg-0'
					/>
				</Col>
			</Row>
			<Row className='mb-5 mb-sm-5 d-flex align-items-center'>
				<Col
					xxl={7}
					xl={7}
					lg={7}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-sm-5 mb-5'
				>
					<ImgBox
						src={l3_m2_p2}
						description={`Фото Чибилева А.А. \nБизоны в «Аскания — Нова». Фотография из Степной экспедиции Русского географического общества. `}
						description_class='tir-down description-big'
					/>
				</Col>
				<Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
					<TextBox element={text_7} purple mode={1} className='mb-lg-0' />
				</Col>
			</Row>
		</Container>
	)
}
export const Lection4_m2 = props => {
	const imgMap = [
		{
			src: l4_m2_p2_1,
			name: 'Рыболовный крючок',
		},
		{
			src: l4_m2_p2_2,
			name: 'Скульптуры рыб',
		},
		{
			src: l4_m2_p2_3,
			name: 'Долблёная лодка',
		},
	]
	const text_1 = (
		<p className='text-medium text-white'>
			Первые постоянные жилища древних людей в степи также появились в каменном
			веке. Люди заметили, что определённые места (водопои, броды) постоянно
			притягивают большое количество животных. Значит, если поселиться рядом,
			то не нужно будет постоянно следовать за стадами.
		</p>
	)
	const text_2 = (
		<p className='text-italic '>
			Примерно 10 тыс. лет назад наступил неолит —{' '}
			<b className='text-bold'>новый каменный век</b>. Это время ознаменовалось
			возникновением земледелия и скотоводства. Однако такие коренные изменения
			в жизни древних людей происходили постепенно, и новые занятия существовали
			параллельно со старыми — охотой и собирательством.
		</p>
	)
	const text_3 = (
		<p className='text-medium text-white'>
			Люди, поселившиеся у крупных рек, могли заниматься рыболовством. Для этого
			использовали удочки и крючки, косяки рыб ловили сетями; для ловли
			в придонных водах использовали плетёные ловушки. На крупную рыбу охотились
			с гарпунами.
		</p>
	)
	const text_4 = (
		<p className='text-italic '>
			В то же время на открытых степных пространствах происходит одомашнивание
			крупного и мелкого рогатого скота и начинается постепенный переход к
			кочевому скотоводству — занятию, которое определило облик евразийской
			степи на многие тысячелетия вперёд.
		</p>
	)
	const link = (
		<a
			href='https://zatermir.ru/muzei/ekskursii'
			className='text-medium text-link'
			target='_blank'
			rel='noreferrer'
		>
			Посёлок каменного века. Этномузей «Затерянный мир». Ростовская обл., х.
			Пухляковка.
		</a>
	)

	return (
		<Container className='pt-5 pb-5'>
			<Row className='d-flex align-items-start justify-content-center mb-5 mb-sm-5'>
				<Col
					xxl={8}
					xl={7}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-xl-0 mb-sm-5 '
				>
					<TextBox
						element={text_1}
						purple
						mode={3}
						className='mb-lg-0 mb-sm-5 mb-5 h-xl-2'
						clip='pink_tr'
					/>
				</Col>
				<Col xxl={4} xl={5} lg={12} md={12} sm={12} xs={12}>
					<TextBox element={text_2} purple mode={4} />
				</Col>
			</Row>

			<Row className='mb-5 mb-sm-5 d-flex align-items-xl-end align-items-center mg-xl-t-m-1'>
				<Col
					xxl={7}
					xl={7}
					lg={7}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-sm-5 mb-5'
				>
					<ImgBox
						src={l4_m2_p1}
						description={link}
						description_class='tir-down description-big'
					/>
				</Col>
				<Col
					xxl={5}
					xl={5}
					lg={5}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-sm-5 mb-5'
				>
					<TextBox
						element={text_3}
						purple
						mode={2}
						clip='blue_tl'
						className='h-lg-2'
					/>
				</Col>
			</Row>
			<Row className='mb-5 mb-sm-5 pe-2 ps-2'>
				<ImgBlock imgs={imgMap} purple mode={1} doubleMark />
			</Row>
			<Row className='mb-5 mb-sm-5 pe-2 ps-2'>
				<TextBox element={text_4} purple mode={4} />
			</Row>
		</Container>
	)
}
export const Lection5_m2 = props => {
	const text_1 = (
		<p className='text-medium '>
			Обширные открытые пространства Великой Степи, распростёршейся от Дуная до
			Алтая, стали домом для целого ряда народов. Особые природные условия степи
			определили образ жизни и мировоззрение населявших её племён. На протяжении
			большей части истории Великой Степи преобладающим типом хозяйства здесь
			было кочевое скотоводство, когда в поисках новых пастбищ люди со своими
			стадами переселялись на многие километры от основного места жительства.
		</p>
	)
	const text_2 = (
		<p className='text-medium'>
			Становлению и распространению кочевого образа жизни способствовал целый
			ряд нововведений, коренным образом менявших жизнь древних людей.
		</p>
	)
	const text_3 = (
		<p className='text-medium text-white'>
			Первым значимым новшеством стало одомашнивание дикой лошади — тарпана.
			Учёные спорят, где и когда это произошло впервые. По наиболее
			распространённой версии, лошадь приручили племена, проживавшие
			в евразийских степях примерно 5,5 тыс. лет назад. С тех пор люди
			использовали лошадь по‑разному: и как источник пищи (молока и мяса),
			и как транспорт, в том числе, в военных действиях.
		</p>
	)
	const fact = (
		<p className='fact-text'>
			Одним из типичных животных, населявших степь в то время, был тарпан. Слово
			«Тарпан» происходит от названия на тюркском языке, означающего «дикая
			лошадь». Этот вид вымер в конце XVIII-начале XX вв. в результате распашки
			степей и длительного вытеснения стадами домашних животных.
		</p>
	)
	const text_4 = (
		<p className='text-italic'>
			Это были маленькие, свободно пасущиеся лошади, не выше 140 см. Коренастые,
			с густой гривой, хвостом и тёмными зеброидными полосками, они смотрелись
			гармонично на фоне бескрайних степей. Животные водились табунами, которые
			распадались на маленькие группы с жеребцом во главе. Тарпаны были крайне
			дики, осторожны и пугливы.
		</p>
	)
	return (
		<div>
			<Container className='pt-5 pb-5'>
				<Row className='d-flex align-items-start justify-content-start mb-5 mb-sm-5 '>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='md-md-0 mb-5
					mb-lg-0 mb-sm-5 offser-lg-1'
					>
						<ImgBox
							src={l5_m2_p1}
							mark_class={'mark-d-l-b'}
							description='Архип Иванович Куинджи.
						«Полдень. Стадо в степи». 1890–1895.'
							description_class='tir-up'
						/>
					</Col>
					<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} className='mb-0'>
						<TextBox element={text_1} purple mode={1} className=' h-lg-2' />
					</Col>
				</Row>
				<Row className='mg-lg-t-m-1 mb-5 mb-sm-5 d-flex align-items-end justify-content-start '>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='mt-lg-5 mb-5
					mb-lg-0 mb-sm-5'
					>
						<TextBox element={text_2} purple mode={1} />
					</Col>{' '}
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='md-md-0
					mb-lg-0  '
					>
						<ImgBox
							src={l5_m2_p2}
							description='Тарпан'
							description_class='til-down'
						/>
					</Col>
				</Row>
				<Row className='mb-5 mb-sm-5 d-flex align-items-end justify-content-start '>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='mt-lg-5 mb-5
					mb-lg-0 mb-sm-5'
					>
						<ImgBox
							src={l5_m2_p3}
							description='Фото Узденова А.М.'
							description_class='til-down'
						/>
					</Col>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='md-md-0 mb-5
					mb-lg-0 mb-sm-5 '
					>
						<TextBox element={text_3} purple mode={3} />
					</Col>
				</Row>
			</Container>
			<Container fluid className='p-0'>
				<Row className='d-flex align-items-start '>
					<Fact text={fact} color={2} />
				</Row>
			</Container>{' '}
			<Container className='pt-5 pb-5'>
				<Row className='d-flex align-items-center justify-content-center mb-5 mb-sm-5 '>
					<Col
						xxl={10}
						xl={10}
						lg={10}
						md={12}
						sm={12}
						xs={12}
						className='mb-0'
					>
						<ImgBox src={l5_m2_p4} mark_class={'mark-d-r-t'} />
					</Col>
				</Row>

				<Row className='mb-5 mb-sm-5 d-flex align-items-end justify-content-start offset-lg-1'>
					<Col
						xxl={11}
						xl={11}
						lg={11}
						md={12}
						sm={12}
						xs={12}
						className='md-md-0 mb-5
					mb-lg-0 mb-sm-5 '
					>
						<TextBox element={text_4} purple mode={4} />
					</Col>
				</Row>
			</Container>
		</div>
	)
}
export const Lection6_m2 = props => {
	const text_1 = (
		<p className='text-medium text-white'>
			Как вы думаете, появилось бы в парках аттракционов колесо обозрения, если бы люди когда-то не изобрели колеса?
		</p>
	)
	const text_2 = (
		<p className='text-medium text-white'>
			Ещё бы: одним из самых значимых событий стало изобретение колёсного
			транспорта, хотя, по всей видимости, первые повозки тянули быки, а не
			лошади. Повозки стали настолько важной частью жизни кочевника, что ими не
			только пользовались в повседневной жизни, но и помещали в могилу, чтобы
			душа могла привычным образом отправиться в загробный мир.
		</p>
	)
	const text_3 = (
		<p className='text-medium'>
			Начиная со скифского времени, широко была распространена практика вместе
			с умершим хоронить его коня. Правда, зачастую древние люди немного
			хитрили: вместо повозки могли поместить в могилу её миниатюрную модель,
			а вместо лошади — детали конской упряжи.
		</p>
	)
	const text_4 = (
		<p className='text-italic'>
			В погребении кургана Улан IV (Ростовская область) были обнаружены остатки
			повозки, изготовленной более 4200 лет назад.
		</p>
	)

	return (
		<Container className='pt-5 pb-5 mt-5'>
			<Row className='d-flex align-items-center justify-content-center mb-5'>
				<Col
					xxl={2}
					xl={3}
					lg={3}
					md={4}
					sm={6}
					xs={7}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
				>
					<ImgBox
						src={l6_m2_p3}
						
					/>
				</Col>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={6}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
				>
					<TextBox element={text_1} purple mode={2} />
				</Col>
				
			</Row>
			<Row className='d-flex align-items-center mb-5'>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
				>
					<TextBox element={text_2} purple mode={1} />
				</Col>
			</Row>
			<Row className='d-flex align-items-center mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
				>
					<ImgBox
						src={l6_m2_p1}
						description='Иван Константинович Айвазовский «Обоз в степи». Холст, масло, XIXвек.'
						description_class='tir-up'
					/>
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<TextBox element={text_3} purple mode={1} className=' mb-5' />
					<TextBox element={text_4} purple mode={4} />
				</Col>
			</Row>
		</Container>
	)
}
export const Lection7_m2 = props => {
	const text_1 = (
		<p className='text-medium text-white'>
			Огромным шагом в развитии человечества стало открытие технологии
			изготовления <b>бронзы</b> — сплава меди с оловом (либо с некоторыми
			другими металлами). Это открытие было настолько значимо, что целый период
			истории так и называют — бронзовый век. Из бронзы изготавливались
			не только орудия труда, но и оружие, позволявшее кочевникам более
			эффективно охранять от чужаков свои стада, а также совершать набеги.
		</p>
	)
	const text_3 = (
		<p className='text-medium text-white'>
			На рубеже II и I тысячелетий до н.э. бронза сменяется железом, а степи
			обретают новых хозяев — скифов.
		</p>
	)
	const text_2 = (
		<p className='text-italic'>
			Технология бронзы, одомашнивание лошади и изобретение колёсного транспорта
			сыграло огромную роль в освоении обширных степных пространств, позволив
			кочевым племенам перемещаться на более дальние расстояния.
		</p>
	)
	const text_4 = (
		<p className='text-italic '>
			«Так называемая „Скифская пустыня“ представляет собою равнину, изобилующую
			травою, но лишённую деревьев и умеренно орошённую: по ней текут большие
			реки, которые отводят воду с степей. Здесь‑то и живут скифы; называются
			они кочевниками потому, что у них нет домов, а живут они в кибитках,
			из которых наименьшие бывают четырехколесные, а другие — шестиколесные;
			они кругом закрыты войлоками и устроены подобно домам, одни с двумя,
			другие с тремя [отделениями]; они непроницаемы ни для воды, ни для света,
			ни для ветров. В эти повозки запрягают по две и по три пары безрогих
			волов: рога у них не растут от холода. В таких кибитках помещаются
			женщины, а мужчины ездят верхом на лошадях: за ними следуют их стада овец
			и коров и [табуны] лошадей. На одном месте они остаются столько времени,
			пока хватает травы для стад, а когда её не [хватит], переходят в другую
			местность. Сами они едят вареное мясо, пьют кобылье молоко и едят
			„гиппаку“ [это сыр из кобыльего молока]. Таков образ жизни и обычаи
			скифов.
		</p>
	)

	return (
		<Container className='pt-5 pb-5'>
			<Row className='d-flex align-items-start mb-5'>
				<Col
					xxl={8}
					xl={8}
					lg={8}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
				>
					<TextBox
						element={text_1}
						purple
						mode={3}
						clip='pink_tr'
						className='mb-5'
					/>
					
					<TextBox element={text_3} purple mode={2} />
				</Col>
				<Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
				<TextBox element={text_2} purple mode={4} className='' />
				</Col>
			</Row>
			<Row className='d-flex align-items-center mg-sm-t-p-1 mg-t-p-1'>
				<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
					<TextBox
						element={text_4}
						purple
						mode={4}
						description={
							<p className='text-italic'>
								Псевдо-Гиппократ.
								<br />
								О воздухах водах и местностях. Гл. 18.
							</p>
						}
						description_class='textbox-arrow-tr-2'
					/>
				</Col>
			</Row>
		</Container>
	)
}
export const Lection8_m2 = props => {
	const text_1 = (
		<p className='text-medium'>
			Обширные пространства евразийских степей таковы, что можно несколько дней
			ехать и не встретить ни одного человека. Однако это не значит, что
			кочевники не сталкивались с другими народами, которые вели оседлый (т.е.
			связанный с проживанием в одном месте) образ жизни.
		</p>
	)
	const text_3 = (
		<p className='text-medium text-white'>
			Жилищами их были либо кибитки, либо разборные конструкции (юрты, шатры).
			Посуда должна была быть лёгкой и небьющейся, поэтому изготавливалась из
			дерева или кожи. Из кожи, шерсти и меха шилась и одежда, хотя кочевники
			всегда были не прочь заполучить дорогие и красивые материалы, вроде шелка.
		</p>
	)
	const text_2 = (
		<p className='text-italic'>
			Естественно, мировоззрение кочевых народов серьёзно отличалось
			от земледельческого. Постоянная необходимость перемещаться с места
			на место вслед за стадами сделала кочевников очень лёгкими на подъем.
		</p>
	)
	const text_4 = (
		<p className='text-medium text-white'>
			Дети с самого юного возраста учились ездить на коне, охотиться и владеть
			оружием (прежде всего, луком и стрелами). Наличие большого числа
			вооружённых всадников делало кочевые народы грозными воинами. Война,
			наравне со скотоводством, была для них привычным способом заработка.
			Отсюда обычное для кочевников почитание воинов и героев-предков.
		</p>
	)
	const text_5 = (
		<p className='text-medium'>
			<b className='text-purple'>Оседлые</b> соседи кочевников, занимавшиеся
			земледелием и торговлей, с опаской относились к кочевым народам, считая
			их дикарями и постоянно ожидая от них (не без оснований) вооружённых
			нападений. Кочевники же считали оседлые народы трусами и слабаками,
			живущими по странным обычаям и неспособными постоять за себя. Имущество
			подобных племён считалось среди кочевников законной добычей. Однако
			со временем два мира неизменно сближались.
		</p>
	)
	const text_6 = (
		<p className='text-italic'>
			Расцвет любых «кочевых империй» всегда связан с существовавшими
			по соседству с земледельческими цивилизациями и зависел от поступавших
			оттуда товаров.
		</p>
	)
	const text_7 = (
		<p className='text-italic'>
			В одних случаях кочевники вымогали подарки и дань на расстоянии (скифы,
			хунну, тюрки и др.).
			<br />
			<br />
			В других — они подчиняли земледельцев и взимали дань (Золотая Орда).
		</p>
	)

	const text_8 = (
		<p className='text-medium text-white'>
			В‑третьих, они завоёвывали земледельцев и переселялись на их территорию,
			сливаясь с местным населением (авары, булгары и др.). Кроме того, вдоль
			маршрутов Великого Шёлкового пути, проходившего также и по землям
			кочевников, возникали стационарные поселения с <b>караван-сараями</b> —
			постоялыми дворами для караванов.
		</p>
	)
	const text_9 = (
		<p className='text-medium text-white'>
			В хуннское время были установлены прямые контакты между Китаем и Римом.
			Особенно важную роль сыграли монгольские завоевания. В результате
			сформировалась единая цепь международной торговли, технологических
			и культурных обменов. По‑видимому, в результате этих процессов в Западную
			Европу попали порох, компас и книгопечатание.
		</p>
	)
	const text_10 = (
		<p className='text-italic'>
			Шёлковый путь, протянувшийся более чем на 6400 км, играл важнейшую роль
			в установлении экономических, культурных, политических и религиозных
			отношений между Востоком и Западом.
		</p>
	)
	const text_11 = (
		<p className='text-medium'>
			Своим названием Великий Шёлковый путь обязан крайне прибыльной торговле
			шёлковыми тканями, которые производились в Китае. С Востока
			также поставляли чай, красители, ароматические масла, фарфор. Запад
			обменивал их на лошадей, мёд, вино и золото.
		</p>
	)
	const text_12 = (
		<p className='text-medium'>
			За свою полуторатысячелетнюю историю Шёлковый путь стал свидетелем
			рождения и гибели многочисленных империй и пережил крупнейшие исторические
			события, например, монгольское завоевание Евразии и эпидемию Чёрной Смерти
			(чумы).
		</p>
	)
	const text_animal = (
		<p className='text-medium'>
			<b className='text-title text-underline-purple'>Великий Шёлковый путь </b>
			<br />
			<br /> сеть торговых маршрутов, связывавших Европу с Азией в период
			со 2 века до н.э. по 15 в. н.э. 
		</p>
	)

	return (
		<Container className='pt-5 pb-5'>
			<Row className='d-flex align-items-center justify-content-between mb-5'>
				<Col
					xxl={4}
					xl={4}
					lg={4}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
				>
					<TextBox
						element={text_1}
						purple
						mode={1}
						className='h-xxl-4 h-xl-3'
					/>
				</Col>
				<Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
					<ImgBox
						src={l8_m2_p1}
						description='Смирнов Борис Васильевич.
						Степь. Картон, масло. 23,3х35,2. 1939. Красндарский краевой художественный музей имени Ф.А. Коваленко'
						description_class='tir-up description-big'
					/>
				</Col>
			</Row>
			<Row className='d-flex align-items-start mb-5'>
				<Col
					xxl={7}
					xl={7}
					lg={7}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-sm-5 mb-5 '
				>
					<TextBox element={text_2} purple mode={4} />
				</Col>
				<Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
					<TextBox element={text_3} purple mode={3} />
				</Col>
			</Row>
			<Row className='d-flex align-items-center mb-5'>
				<Col
					xxl={7}
					xl={7}
					lg={7}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-sm-5 mb-5'
				>
					<ImgBox
						src={l8_m2_p2}
						description='Прянишников Илларион Михайлович.
						Степь. Доска, масло. 26,7х40,7. 19 век. Алупкинский дворцово-парковый музей-заповедник'
						description_class='til-up description-big'
					/>
				</Col>
				<Col
					xxl={5}
					xl={5}
					lg={5}
					md={12}
					sm={12}
					xs={12}
					className='pt-5 mb-5'
				>
					<TextBox
						element={text_4}
						purple
						mode={2}
						clip='blue_tr'
						className=' h-xl-4'
					/>
				</Col>
			</Row>
			<Row>
				<Col
					xxl={10}
					xl={10}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					className='pt-5 mb-5 offset-lg-1'
				>
					<ImgBox
						src={l8_m2_p3}
						description='Корнеев. Е.
						«Киргизские кибитки в степи под Оренбургом»'
						mark_class={'mark-h-t-r '}
						description_class='til-up description-big'
					/>
				</Col>
			</Row>
			<Row className='mb-5'>
				<Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12} className='mb-5'>
					<TextBox element={text_5} purple mode={1} className=' h-xl-3' />
				</Col>
				<Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12} className=' mg-lg-0'>
					<TextBox
						element={text_6}
						purple
						mode={4}
						className='mb-5 mb-xxl-5 mb-lg-2 mb-sm-5 h-lg-1'
					/>
					<TextBox element={text_7} purple mode={4} />
				</Col>
			</Row>
			<Row className='mb-5'>
				<Col xxl={10} xl={10} lg={10} md={12} sm={12} xs={12}>
					<TextBox element={text_8} purple mode={2} />
				</Col>
			</Row>
			<Row className='mb-5'>
				<Col
					xxl={10}
					xl={10}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					className='offset-lg-2'
				>
					<TextBox element={text_9} purple mode={3} />
				</Col>
			</Row>
			<Row className='mb-5'>
				<p className='text-subtitle'>Великий шЁлковый путь</p>
			</Row>
			<Row className='mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={8}
					sm={8}
					xs={8}
					className='offset-lg-0 offset-2 offset-sm-2 mb-5 mb-sm-5 mb-lg-0'
				>
					<ImgBox src={l8_m2_p4} />
				</Col>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={10}
					sm={10}
					xs={10}
					className='d-flex flex-column align-items-end justify-content-center'
				>
					<TextBox element={text_animal} className='p-0' />
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={10}
						sm={10}
						xs={10}
						className='offset-lg-0 offset-1 offset-sm-1'
					>
						<ImgBox src={mouse_tl} />
					</Col>
				</Col>
			</Row>
			<Row className='mb-5'>
				<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
					<TextBox element={text_10} purple mode={4} />
				</Col>
			</Row>
			<Row className='mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-5 mb-sm-5 '
				>
					<TextBox element={text_11} purple mode={1} className='h-lg-2' />
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<TextBox element={text_12} purple mode={1} className='h-lg-2' />
				</Col>
			</Row>
		</Container>
	)
}
export const Lection9_m2 = props => {
	const text_1 = (
		<p className='text-medium text-white'>
			Мы привыкли думать, что в древности человек жил в гармонии с природой
			и лишь недавно начал менять природные ландшафты для своих нужд. Однако
			на самом деле, люди меняли облик степи в течение тысяч лет. Речь идёт
			о строительстве курганов.
		</p>
	)
	const text_2 = (
		<p className='text-medium text-white'>
			Если ехать по бескрайним степным просторам нашей страны, то можно заметить
			холмы разных размеров, то здесь, то там разбросанные по полям и лугам.
			Большая часть этих холмов — курганы.
		</p>
	)
	const text_3 = (
		<p className='text-medium '>
			<b>Курган</b> — это земляной холм, сооружённый над погребением. В степях
			Юга России курганы начали возводить около 6-7 тыс. лет назад. Сменялись
			эпохи, народы приходили и уходили, а традиция хоронить своих умерших
			под курганной насыпью сохранялась вплоть до средневековья.
		</p>
	)
	const text_4 = (
		<p className='text-medium '>
			<b>Сооружение кургана</b> — дело непростое. Зачем же древние люди тратили
			так много сил? Дело в том, что они верили: после смерти человек продолжит
			жить в загробном мире. Но чтобы его душа могла туда попасть, важно
			соблюсти ритуал: выкопать могилу определённой формы, разместить тело
			в правильном положении, а также снабдить умершего нужными предметами:
			напутственной пищей в дорогу и предметами, которые подскажут,
			кем был человек в жизни — рабом, воином или правителем.
		</p>
	)
	const text_5 = (
		<p className='text-medium '>
			Поскольку погребения знатных людей содержали много ценных вещей, всегда
			были желающие эти вещи заполучить. Курганы грабили в древности, и,
			к сожалению, грабят до сих пор. Древние люди старались бороться
			с грабителями самыми разными способами: накладывали проклятия, карали
			смертью за расхищение гробниц, прятали погребения или устраивали тайники.
			Сейчас курганы и другие памятники археологии охраняются государством,
			однако не всегда это спасает от грабителей.
		</p>
	)
	const text_6 = (
		<p className='text-medium text-white'>
			Курганы служили древним народам не только для совершения погребений.
			Высокие холмы на бескрайних просторах степи были прекрасными ориентирами,
			отмечающими границы владений, маршруты перекочевок и места переправ.
		</p>
	)
	const text_7 = (
		<p className='text-medium  text-white'>
			По‑своему стали использовать высокие курганные насыпи половцы — кочевой
			народ, обитавший в средние века (IX–XIII вв.) на обширных пространствах
			Великой Степи. На вершинах курганов половцы помещали свои святилища,
			в центре которых располагались каменные статуи — бабы.
		</p>
	)
	const text_8 = (
		<p className='text-italic '>
			Когда степная зона перестала быть перекрёстком миров и границы России
			отодвинулись дальше на юг, курганы продолжали защищать свои земли. Немало
			их, в военных сводках названных просто «безымянными высотами», сыграли
			свою скромную, но такую важную роль в Великой Отечественной войне.
		</p>
	)
	const text_9 = (
		<p className='text-italic '>
			После войны земли степи начали активно осваиваться. Проводилась высадка
			защитных лесополос, строительство оросительных каналов.
		</p>
	)
	const text_10 = (
		<p className='text-medium '>
			В 1952 году при строительстве Цимлянского водохранилища был затоплен
			Саркел — один из крупнейших городов Хазарского каганата, существовавшего в
			VII–X вв. степного государства. На тот момент археологами была исследована
			лишь треть всего поселения.
		</p>
	)
	const text_11 = (
		<p className='text-medium '>
			Негативным образом сказалось на степных экосистемах освоение целины: были
			уничтожены десятки миллионов гектаров различных природных зон, а также
			территории пастбищ и сенокосов. В 1950-х годах в СССР для преодоления
			проблем в сельском хозяйстве было решено начать освоение целинных земель
			Поволжья, Северного Казахстана, Урала и Западной Сибири.
		</p>
	)
	const text_12 = (
		<p className='text-medium '>
			Целинные степи с удивительной быстротой начали исчезать.
		</p>
	)

	return (
		<Container className='pt-5 pb-5'>
			<Row className='d-flex align-items-start mb-5'>
				<Col xxl={3} xl={3} lg={3} md={3} sm={6} xs={6}>
					<ImgBox src={man_3} />
				</Col>
				<Col
					xxl={8}
					xl={8}
					lg={8}
					md={12}
					sm={12}
					xs={12}
					className='mt-0 mt-lg-3'
				>
					<Col
						xxl={12}
						xl={12}
						lg={12}
						md={12}
						sm={12}
						xs={12}
						className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
					>
						<TextBox
							element={text_1}
							purple
							mode={3}
							className='mb-lg-4 mb-sm-5 mb-5'
						/>
					</Col>
					<Col
						xxl={10}
						xl={10}
						lg={10}
						md={12}
						sm={12}
						xs={12}
						className='md-md-0 mb-5
					mb-lg-0 mb-sm-5 '
					>
						<TextBox
							element={text_2}
							purple
							mode={2}
							clip='pink_tr'
							className='mb-lg-4 mb-sm-5 mb-5'
						/>
					</Col>
				</Col>
			</Row>
			<Row className='d-flex align-items-start mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-sm-5 mb-5'
				>
					<ImgBox src={l9_m2_p1} mark_class='mark-d-r-b' />
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<TextBox
						element={text_3}
						purple
						mode={4}
						className='h-xl-3 mb-sm-5 mb-5'
					/>
				</Col>
			</Row>

			<Row className='d-flex align-items-start mb-5'>
				<Col
					xxl={5}
					xl={5}
					lg={5}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-sm-5 mb-5'
				>
					<TextBox element={text_4} purple mode={1} />
				</Col>
				<Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12}>
					<TextBox element={text_5} purple mode={1} />
				</Col>
			</Row>
			<Row className='d-flex align-items-start mb-5 '>
				<Col
					xxl={4}
					xl={4}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className='mb-xl-0 mb-sm-5 mb-5 offset-xl-1'
				>
					<TextBox element={text_6} purple mode={2} clip='blue_tr' />
				</Col>
				<Col xxl={7} xl={7} lg={12} md={12} sm={12} xs={12}>
					<TextBox element={text_7} purple mode={3} clip='pink_tr' />
				</Col>
			</Row>

			<Row className='d-flex align-items-start mb-5 '>
				<Col
					xxl={8}
					xl={8}
					lg={8}
					md={12}
					sm={12}
					xs={12}
					className='mb-xl-0 mb-sm-5 mb-5'
				>
					<TextBox element={text_8} purple mode={4} className='h-lg-2' />
				</Col>
				<Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
					<TextBox element={text_9} purple mode={4} className='h-lg-2' />
				</Col>
			</Row>

			<Row className='d-flex align-items-start mb-5 '>
				<Col
					xxl={4}
					xl={4}
					lg={4}
					md={12}
					sm={12}
					xs={12}
					className='mb-xl-0 mb-sm-5 mb-5'
				>
					<TextBox element={text_10} purple mode={1} />
				</Col>
				<Col
					xxl={7}
					xl={7}
					lg={7}
					md={12}
					sm={12}
					xs={12}
					className='offset-lg-1'
				>
					<TextBox element={text_11} purple mode={1} className='mb-5' />

					<Col xxl={10} xl={10} lg={10} md={12} sm={12} xs={12}>
						<TextBox element={text_12} purple mode={1} doubleBox_up />
					</Col>
				</Col>
			</Row>
		</Container>
	)
}

export const Lection1_m3 = props => {
	const text_1 = (
		<p className='text-italic'>
			“Если будет заведена «Красная книга экосистем», то степь в нее занесут в
			первую очередь. Среди всех экосистем мира судьба степей наиболее
			драматична”.
			<br />
			<br />
			В.Г. Мордкович “Степные экосистемы”
		</p>
	)
	const text_2 = (
		<p className='text-medium'>
			<b className='text-subtitle border-green'>экосистема</b> —<br />
			<br />
			единый природный комплекс, образованный живыми организмами и средой их
			обитания (атмосфера, почва, водоем и т.п.), в котором они связаны между
			собой обменом вещества и энергии.
		</p>
	)
	const text_3 = (
		<p className='text-medium'>
			Уникальные степные экосистемы находятся под угрозой. Годы эксплуатации,
			распашки целинных участков, неконтролируемого выпаса животных, охоты
			привели к катастрофической ситуации со степями. На месте, где были
			целинные степные пейзажи, вы сейчас, чаще всего, сможете увидеть поля с
			пшеницей, подсолнухом, кукурузой и другими культурами.
		</p>
	)
	const text_4 = (
		<p className='text-medium'>
			Многие степные виды растений и животных утратили места обитания, что
			привело к сокращению их численности и исчезновению. Прежде всего,
			пострадали крупные травоядные млекопитающие. Из степей исчезли тарпан,
			европейский тур, степной зубр.
		</p>
	)
	const text_6 = (
		<p className='text-medium text-white'>
			Сайгак, дрофа, дзерен, лошадь Пржевальского и многие другие находятся под
			угрозой исчезновения.
		</p>
	)

	return (
		<Container className='pt-5 pb-5'>
			<Row className='d-flex align-items-start mb-5'>
				<Col
					xxl={7}
					xl={7}
					lg={7}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
				>
					<TextBox element={text_1} green mode={4} />
				</Col>
				<Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
					<TextBox element={text_2} green className='p-0' />
				</Col>
			</Row>
			<Row className='d-flex align-items-start mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
				>
					<TextBox
						element={text_3}
						doubleBox
						green
						mode={1}
						className='mb-5 h-lg-2'
					/>
					<TextBox element={text_4} green mode={1} className='h-lg-3' />
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<ImgBox
						className='mb-5 mb-sm-5'
						src={l1_m3_p1}
						mark_class={'mark-d-r-t '}
						description_class={'til-down description-big'}
						description={
							<p className='text-medium'>
								Фото Чибилева А.А.
								<br />
								Oтара овeц. Ногайская степь. Червленые Буруны. Фотография из
								Степной экспедиции Русского географического общества.
							</p>
						}
					/>
					<TextBox element={text_6} green mode={2} className='mg-lg-t-p-1' />
				</Col>
			</Row>
		</Container>
	)
}
export const Lection2_m3 = props => {
	const text_1 = (
		<p className='text-medium'>
			Каждый организм занимает свое место в экосистеме и обеспечивает круговорот
			веществ и энергии в природе.
		</p>
	)
	const text_2 = (
		<p className='text-medium text-white'>
			 Давайте посмотрим на рисунок ниже:
		</p>
	)
	const text_3 = (
		<p className='text-medium'>
			<b>Экологическая связь</b>: как мы видим, растениям нужно больше
			опыляться, пчелам - давать больше меда.
		</p>
	)
	const text_4 = [
		<ol className='list-number'>
			<li className='text-medium text-bold list-number mb-4'>
				В центре рисунка растение. Растение производит биомассу, обогащает
				почву, имеет мощную корневую систему.
			</li>
			<li className='text-medium text-bold list-number mb-4'>
				На растении сидит листоед - листоед может быть пищей для другого
				животного, например, сорокопута - сорокопут также участвует в пищевой
				цепочке и служит пищей для канюка.
			</li>
			<li className='text-medium text-bold list-number mb-4'>
				Растения привлекают и пчел - за пчелами охотятся птички с яркой
				окраской - золотистые щурки.
			</li>
			<li className='text-medium text-bold list-number mb-4'>
				Растение привлекает и зайцев - за зайцами охотятся лисы Сайгаки питаются
				растительностью.
			</li>
			<li className='text-medium text-bold list-number mb-4'>
				Сайгаки питаются растительностью. Сайгаки в степи выполняют очень важную
				функцию: они поддерживают естественный растительный покров в степи.
			</li>
		</ol>,
	]
	const text_5 = (
		<p className='text-italic '>
			Теперь представим, что все копытные исчезли из степей. Травостой 
			становится выше, забивается бурьянами и не дает прорастать многим
			растениям. Яркие цветные пейзажи меркнут из-за того, что растения не могут
			пробиться через толстый слой ветоши и многолетней неразлагающейся
			подстилки, так называемого «степного войлока». Если сокращаются виды
			растений, то и сокращается количество еды для ряда видов. 
		</p>
	)
	const text_6 = (
		<p className='text-medium'>
			 Многие норные животные предпочитают невысокий травостой, чтобы он не
			мешал перемещаться и позволял издали увидеть потенциальную угрозу.
			Заросшие высокие поля им не подойдут для жизни, и они уйдут в поисках
			благоприятных условий.
			<br />
			<br />
			Но будет ли куда им идти?
		</p>
	)

	return (
		<Container className='pt-5 pb-5'>
			<Row className='d-flex align-items-start mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5 offset-lg-1'
				>
					<TextBox element={text_1} green mode={1} className='h-lg-1' />
				</Col>
				<Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
					<Col xxl={6} xl={6} lg={6} md={8} sm={8} xs={8}>
						<TextBox
							element={text_2}
							green
							mode={3}
							className='border-rb h-lg-1'
						/>
					</Col>

					<Col
						xxl={3}
						xl={3}
						lg={3}
						md={3}
						sm={3}
						xs={3}
						className='offset-lg-7 offset-8'
					>
						<ImgBox src={bird_blue} />
					</Col>
				</Col>
			</Row>
			<Row className='d-flex align-items-start mb-5'>
				<Col
					xxl={10}
					xl={10}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5 offset-lg-1'
				>
					<ImgBox
						className='mb-5 mb-sm-5'
						src={l2_m3_p1}
						mark_class={'mark-d-l-t '}
					/>
				</Col>
			</Row>
			<Row className='d-flex align-items-start mb-5'>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className=' mb-5 mb-sm-5 '
				>
					<TextBox green mode={5} dote element={text_4} />
				</Col>
			</Row>
			<Row className='d-flex align-items-start mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className=' mb-5 mb-sm-5 '
				>
					<TextBox green mode={4} element={text_5} clip={'green_tr'} />
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<TextBox green mode={1} element={text_6} />
				</Col>
			</Row>
		</Container>
	)
}
export const Lection3_m3 = props => {
	const text_1 = (
		<p className='text-italic'>
			Нераспаханные степи исчезают с удивительной быстротой. Многолетнее
			использование полей истощает плодородный чернозем. Распашка и обработка
			полей удобрениями заставляет животных уходить с этих территорий.
		</p>
	)
	const text_2 = (
		<p className='text-medium'>
			Со временем распаханные, но неухоженные земли становятся бесплодными
			пустынями.
		</p>
	)
	const text_3 = (
		<p className='text-medium'>
			Ученые были очень обеспокоены состоянием степей и прогнозировали полное
			исчезновение этих уникальных экосистем. Известный ученый В.В. Докучаев
			говорил: “Чтобы сохранить оригинальный степной мир для потомков, не дать
			погибнуть характерным растительным и животным видам, необходимо степь
			заповедать.” И эта идея положила начало созданию первых заповедных степных
			участков.
		</p>
	)
	const text_4 = [
		<ol className='list-number'>
			<p className='text-italic'>Хронологическая линейка заповедного дела:</p>
			<li className='text-medium text-bold mb-4'>
				1895 – Идея заповедных научных стаций В.В. Докучаева
			</li>
			<li className='text-medium text-bold mb-4'>
				1898 – создание частного степного заповедника “Аскания-Нова” им. Ф.Э.
				Фальц-Фейна
			</li>
			<li className='text-medium text-bold mb-4'>
				1916-1917 – создание первого государственного заповедника “Баргузинский”
			</li>
			<li className='text-medium text-bold mb-4'>
				1917 – первый проект заповедной сети, представленный В.П.
				Семеновым-Тян-Шанским
			</li>
			<li className='text-medium text-bold mb-4'>
				1930-1940 – создано 42 новых заповедника
			</li>
			<li className='text-medium text-bold mb-4'>
				1960 – общее количество заповедников достигло 85. Принят закон “Об
				охране природы РСФСР”
			</li>
			<li className='text-medium text-bold mb-4'>
				2017 – год особо охраняемых природных территорий России
			</li>
			<li className='text-medium text-bold mb-4'>
				2023 – В России действуют 108 заповедников и 67 национальных природных
				парков
			</li>
		</ol>,
	]
	const text_5 = (
		<p className='text-italic '>
			<b>Заповедник</b> — охраняемая природная территория (акватория), на
			которой сохраняется в естественном состоянии весь природный комплекс —
			типичные или редкие для данной зоны ландшафты, редкие и ценные виды
			животных и растений и пр. Биологический энциклопедический словарь.
		</p>
	)
	const text_6 = (
		<p className='text-medium'>
			В XX веке начали активно создаваться заповедники на всей территории
			страны.
		</p>
	)

	const text_7 = (
		<p className='text-medium'>
			На этом{' '}
			<a
				className='link_green'
				href='https://www.iacgov.ru'
				target='_blank'
				rel='noreferrer'
			>
				сайте
			</a>{' '}
			вы можете посмотреть расположение всех заповедников.
		</p>
	)

	return (
		<Container className='pt-5 pb-5'>
			<Row className='d-flex align-items-end justify-content-between mb-5'>
				<Col
					xxl={7}
					xl={7}
					lg={7}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
				>
					<TextBox element={text_1} green mode={4} className='h-lg-2' />
				</Col>
				<Col
					xxl={2}
					xl={2}
					lg={3}
					md={5}
					sm={5}
					xs={4}
					className='offset-xl-0 offset-lg-1 offset-sm-6 offset-7'
				>
					<ImgBox
						src={rabbit_tr}
						description={'Где же мне теперь жить?'}
						description_class={'animal-t-l'}
					/>
				</Col>
			</Row>
			<Row className='d-flex align-items-start justify-content-end mb-5'>
				<Col xxl={6} xl={6} lg={6} md={6} sm={8} xs={8} className=''>
					<TextBox
						element={text_2}
						green
						mode={2}
						className='textbox-arrow-bl-1'
					/>
				</Col>
			</Row>
			<Row className='d-flex align-items-start justify-content-end mb-5'>
				<Col
					xxl={11}
					xl={11}
					lg={11}
					md={12}
					sm={12}
					xs={12}
					className=' mb-5 mb-sm-5 '
				>
					<TextBox green mode={3} element={text_3} />
					<Row>
						<Col
							xxl={6}
							xl={6}
							lg={6}
							md={12}
							sm={12}
							xs={12}
							className=' mt-5 mt-sm-5s'
						>
							<TextBox green mode={5} doubleBox_up element={text_4} />
						</Col>
						<Col
							xxl={6}
							xl={6}
							lg={6}
							md={12}
							sm={12}
							xs={12}
							className='d-flex  flex-column justify-content-between mt-5'
						>
							<TextBox
								green
								mode={4}
								element={text_5}
								className='mb-lg-0 mb-sm-5 mb-5'
							/>

							<TextBox
								green
								mode={1}
								element={text_6}
								className='mb-lg-0 mb-sm-5 mb-5'
							/>

							<TextBox
								green
								mode={1}
								element={text_7}
								className='mb-lg-0 mb-sm-5 mb-5'
							/>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row className='d-flex align-items-start mb-5'>
				<Col xxl={11} xl={11} lg={11} md={12} sm={12} xs={12}>
					<ImgBox
						src={l3_m3_p1}
						className='offset-lg-1 clip-green-top-left-img'
					/>
				</Col>
			</Row>
		</Container>
	)
}
export const Lection4_m3 = props => {
	const text_1 = (
		<p className='text-medium'>
			В России в настоящее время существует обширная сеть особо охраняемых
			природных территорий или сокращенно <b className='green'>ООПТ</b>.
		</p>
	)
	const text_2 = (
		<p className='text-italic'>
			<b>ООПТ</b> (особо охраняемые природные территории) — особо значимые
			территории, где ради сохранения природы частично или полностью запрещена
			хозяйственная деятельность и действуют другие ограничения.
		</p>
	)
	const text_3 = (
		<p className='text-white'>
			Заповедники - лишь маленькое звено
	
			в сети ООПТ!
			<br />В сеть ООПТ входят:
		</p>
	)
	const text_4 = [
		<p className='text-medium'>
			Все они различаются правилами посещения, режимом охраны, собственностью
			территорий и другим. 
		</p>,
	]
	const text_5 = (
		<p className='text-italic text-center'>
			Интересно! А ботанический сад - он тоже заповедный?
		</p>
	)
	const text_6 = (
		<p className='text-medium text-center'>
			Конечно! Задача ботанического сада - сохранить и восстановить редкие виды
			растений.
		</p>
	)
	const text_6_1 = (
		<p className='text-medium text-center'>
			Взять меня, к примеру. Я прекрасна, как редкий цветок, хотя я и Лошадь
			Пржевальского! 
			<br />
			Да, кстати, в ботанических садах тоже нельзя жечь костры и срывать цветы!
		</p>
	)

	const text_7 = (
		<p className='text-italic '>
			Заповедники имеют самый <b>строгий природоохранный статус!</b> Поэтому так
			важно, чтобы их было больше.
		</p>
	)

	const text_8 = (
		<p className='text-medium text-white'>
			Всего в России на сегодняшний день насчитывается более 13 тысяч ООПТ
			федерального, регионального и местного значения. Все заповедные территории
			Российской Федерации занимают 2 048 780 кв. км (четыре Франции! Или как
			площадь Франции, Германии, Норвегии, Швеции и Испании вместе взятых) или
			12% от площади России.
		</p>
	)
	const text_9 = (
		<p className='text-medium text-white'>
			Только около 1 % площади степей России обеспечено территориальной охраной
			в пределах особо охраняемых природных территорий (ООПТ).
		</p>
	)
	const text_10 = (
		<p className='text-italic '>
			В настоящее время существуют 28 степных заповедников, 8 национальных
			парков, 15 федеральных заказников. Есть даже{' '}
			<a
				href='http://savesteppe.org/ru/steppe-oopt/federal-steppe-oopt'
				className='link_green'
				target='_blank'
				rel='noreferrer'
			>
				список степных заповедников.
			</a>
			И первые 3 места самых степных заповедников занимают Оренбургский,
			Даурский и Ростовский заповедник.
		</p>
	)

	const text_11 = (
		<ul className='text-bold'>
			<p className='mg-1h-b text-bold'>
				Как помогают обществу особо охраняемые природные территории?
			</p>

			<li className='list-item text-bold mb-5 mb-sm-5'>
				они сохраняют живую природу, обеспечивают многим видам благоприятные
				условия для жизни
			</li>
			<li className='list-item text-bold mb-5 mb-sm-5'>
				запрещают любую хозяйственную деятельность: распашку, охоту, выпас скота
				и т.д.
			</li>
			<li className='list-item text-bold mb-5 mb-sm-5'>
				изучают экологию природных сообществ и отдельных их частей
			</li>
			<li className='list-item text-bold mb-5 mb-sm-5'>
				сохраняют здоровую среду для жизни людей
			</li>
			<li className='list-item text-bold mb-5 mb-sm-5'>
				являются убежищами для редких видов
			</li>
		</ul>
	)

	return (
		<Container className='pt-5 pb-5'>
			<Row className='d-flex align-items-start justify-content-between flex-lg-row flex-column-reverse mb-3'>
				<Col
					xxl={5}
					xl={5}
					lg={5}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
				>
					<TextBox element={text_1} green mode={1} className='mb-5 mb-sm-5' />
					<Col xxl={12} xl={12} lg={12} md={8} sm={8} xs={6} className=''>
						<TextBox
							element={text_3}
							green
							mode={2}
							className='textbox-arrow-br'
						/>
					</Col>
				</Col>
				<Col
					xxl={7}
					xl={7}
					lg={7}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-5 mb-sm-5'
				>
					<TextBox element={text_2} green mode={4} />
				</Col>
			</Row>
			<Row className='d-flex align-items-start justify-content-end mb-5'>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0'
				>
					<ImgBox src={l4_m3_p1} mark_class={'mark-double-d'} />
				</Col>
			</Row>
			<Row className='d-flex align-items-start justify-content-end'>
				<Col
					xxl={7}
					xl={7}
					lg={7}
					md={12}
					sm={12}
					xs={12}
					className=' mb-5 mb-sm-5 '
				>
					<TextBox green mode={3} element={text_4} />
				</Col>
			</Row>
			<Row className='d-flex align-items-start justify-content-start'>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className=' mb-5 mb-sm-5 d-flex flex-row justify-content-start align-items-start '
				>
					<Col
						xxl={3}
						xl={3}
						lg={3}
						md={5}
						sm={5}
						xs={5}
						className=' mb-5 mb-sm-5'
					>
						<ImgBox src={man} />
					</Col>
					<Col
						xxl={4}
						xl={4}
						lg={4}
						md={4}
						sm={6}
						xs={6}
						className=' mb-5 mb-sm-5'
					>
						<TextBox
							green
							mode={1}
							element={text_5}
							className='border-lb p-md-3 p-sm-3 p-2'
						/>
					</Col>
				</Col>
			</Row>
			<Row className='d-flex align-items-start justify-content-start mg-xl-t-m-3 mg-lg-t-m-2'>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className=' mb-5 mb-sm-5 d-flex flex-row justify-content-end align-items-end '
				>
					<Col
						xxl={4}
						xl={4}
						lg={4}
						md={7}
						sm={7}
						xs={7}
						className=' mb-5 mb-sm-5'
					>
						<TextBox
							green
							mode={1}
							element={text_6}
							className='border-rt p-md-3 p-sm-3 p-2 mb-5'
						/>{' '}
						<TextBox
							green
							mode={1}
							element={text_6_1}
							className='border-rt p-md-3 p-sm-3 p-2'
						/>
					</Col>
					<Col
						xxl={4}
						xl={4}
						lg={4}
						md={5}
						sm={5}
						xs={5}
						className=' mb-5 mb-sm-5'
					>
						<ImgBox src={horse} />
					</Col>
				</Col>
			</Row>
			<Row className='d-flex align-items-start justify-content-between flex-lg-row flex-column-reverse mb-3'>
				<Col
					xxl={7}
					xl={7}
					lg={7}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
				>
					<TextBox element={text_7} green mode={4} className='mb-5 mb-sm-5' />
					<TextBox element={text_8} green mode={2} className='mb-5 mb-sm-5' />
					<TextBox element={text_9} green mode={3} className='mb-5 mb-sm-5' />
					<TextBox element={text_10} green mode={4} className='mb-5 mb-sm-5' />
				</Col>
				<Col
					xxl={5}
					xl={5}
					lg={5}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-5 mb-sm-5'
				>
					<TextBox element={text_11} green mode={5} clip={'green_tr'} />
				</Col>
			</Row>
		</Container>
	)
}
export const Lection1_m4 = props => {
	const text_1 = (
		<p className='text-medium'>
			На юго-востоке Ростовской области в подзоне сухих степей с фрагментами
			полупустынь лежит цепь солёных озёр, окружённых низкотравными степными
			и солончаковыми пространствами.
		</p>
	)
	const text_2 = (
		<p className='text-medium text-white'>
			Здесь и был организован в 1995 году заповедник «Ростовский».
		</p>
	)
	const text_3 = (
		<ul className='p-0'>
			<li className='text-italic mb-3'>
				Их общая площадь составляет 9532,00 га, из них водной акватории —
				2677 га.
			</li>
			<li className='text-italic mb-3'>
				Охранная зона расположена только в Орловском районе — 74350 га.
			</li>
			<li className='text-italic'>
				Её задача — смягчить воздействие хозяйственного освоения на заповедную
				территорию.
			</li>
		</ul>
	)
	const text_4 = (
		<p className='text-medium text-white'>
			Главное богатство, сохраняемое заповедником, — <b>целинная степь</b>.
			Только здесь можно увидеть цветущие поля{' '}
			<b>тюльпанов, ирисов, ковылей.</b>
		</p>
	)

	const text_5 = (
		<p className='text-medium text-white'>
			Более 500 видов растений встречается на территории заповедника
			и его охранной зоны, 10 из них занесены в Красную книгу России.
		</p>
	)
	const text_6 = (
		<p className='text-medium text-white'>
			Путешествуя по заповедным просторам, можно встретить лисицу, корсака,
			енотовидную собаку или барсука.
		</p>
	)

	const text_7 = (
		<p className='text-medium text-white'>
			Фауна млекопитающих насчитывает 34 вида. Из них самые многочисленные —
			грызуны, один вид — хорь перевязка — внесён в Красную книгу России.
		</p>
	)

	const text_8 = (
		<p className='text-italic'>
			Пернатое население заповедника и прилегающих степей, по последним данным,
			включает более <b>270 видов птиц</b>. В составе орнитофауны преобладают
			водоплавающие и околоводные птицы, гнездящиеся вдоль побережьев
			многочисленных мелководных лиманов и на озере Маныч-Гудило. Небольшие,
			чуть видные из воды, острова этого озера буквально непроходимы
			из-за многочисленных птичьих гнёзд.
		</p>
	)

	const text_9 = (
		<p className='text-medium'>
			Здесь живут <b>чайки, утки, цапли, бакланы, колпицы.</b>
		</p>
	)

	const text_10 = (
		<p className='text-medium'>
			<b>Чайка-хохотунья</b> - наиболее массовый вид. А самые диковинные птицы
			островов - пеликаны.
		</p>
	)
	const text_11 = (
		<p className='text-medium'>
			Поэтому озеро Маныч-Гудило внесено в перечень объектов{' '}
			<b>международной Рамсарской конвенции об охране водно-болотных угодий</b>,
			а Островной участок заповедника признан ключевой орнитологической
			территорией международного значения.
		</p>
	)
	const text_12 = (
		<p className='text-italic'>
			Через район заповедника проходит один из крупнейших <b>путей</b> пролёта
			птиц <b>в Евразии</b>. Здесь пролетает почти вся мировая популяция
			белолобого гуся, тысячи журавлей, уток и куликов. Летят редкие во всем
			мире краснозобая казарка, пискулька, степная тиркушка.
		</p>
	)
	const text_13 = (
		<p className='text-medium'>
			Для тех, кто хочет поближе познакомиться с заповедной природой, действуют
			Музей природы, Информационный центр и Визит-центр, функционируют
			экологические тропы: «Загадки Манычской долины», «Донские мустанги»,
			«Лазоревый цветок».
		</p>
	)
	return (
		<Container className='pt-5 pb-sm-5 pb-4'>
			<Row className='d-flex align-items-center mb-5'>
				<Col
					xxl={8}
					xl={8}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
				>
					<TextBox element={text_1} blue mode={1} className='h-lg-1' />
				</Col>
				<Col xxl={4} xl={4} lg={6} md={12} sm={12} xs={12}>
					<TextBox element={text_2} blue mode={2} className='h-lg-1' />
				</Col>
			</Row>
			<Row className='d-flex align-items-center mb-5'>
				<Col
					xxl={10}
					xl={10}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					className=' mb-lg-0 mb-sm-5 mb-5'
				>
					<ImgBox
						src={l1_m4_p1}
						mark_class='mark-double'
						className='offset-lg-1'
					/>
				</Col>
			</Row>

			<Row className='d-flex align-items-center mb-5'>
				<Col
					xxl={8}
					xl={8}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className=' mb-xl-0 mb-sm-5 mb-5'
				>
					<ImgBox
						src={l1_m4_p2}
						description={'Фото Толчеевой С.В.'}
						description_class='tir-up'
					/>
				</Col>
				<Col xxl={4} xl={4} lg={12} md={12} sm={12} xs={12}>
					<TextBox element={text_3} blue mode={4} />
				</Col>
			</Row>
			<Row className='d-flex align-items-end mb-5'>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<TextBox
						element={text_4}
						blue
						mode={3}
						className='textbox-arrow-tr '
					/>
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<ImgBox
						src={l1_m4_p3}
						description={'Фото Толчеевой С.В.'}
						description_class='tir-up '
					/>
				</Col>
			</Row>
			<Row className='d-flex align-items-end mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className=' mb-lg-0 mb-sm-5 mb-5'
				>
					<ImgBox
						src={l1_m4_p4}
						description={'Фото Толчеевой С.В.'}
						description_class='til-up '
						mark_class={'mark-d-l-b'}
					/>
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<ImgBox
						src={l1_m4_p5}
						description={'Фото Толчеевой С.В.'}
						description_class='tir-down '
						mark_class={'mark-d-l-t'}
					/>
				</Col>
			</Row>

			<Row className='d-flex align-items-center mb-5'>
				<Col
					xxl={7}
					xl={7}
					lg={7}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-5 mb-sm-5'
				>
					<TextBox
						element={text_5}
						blue
						mode={2}
						clip={'blue_tr'}
						mark_class={'mark-d-r-t'}
						className={'h-md-1'}
					/>
				</Col>
				<Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
					<TextBox element={text_6} blue mode={3} className={'h-md-1'} />
				</Col>
			</Row>
			<Row className='d-flex align-items-center mb-5'>
				<Col
					xxl={5}
					xl={5}
					lg={5}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-5 mb-sm-5'
				>
					<TextBox element={text_7} blue mode={3} className={'h-md-2'} />
				</Col>
				<Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12}>
					<TextBox
						element={text_8}
						blue
						mode={2}
						clip={'blue_tr'}
						mark_class={'mark-d-r-t'}
						className={'h-md-2'}
					/>
				</Col>
			</Row>
			<Row className='d-flex align-items-center mb-5'>
				<Col
					xxl={5}
					xl={5}
					lg={5}
					md={12}
					sm={12}
					xs={12}
					className='mb-lg-0 mb-5 mb-sm-5'
				>
					<TextBox element={text_8} blue mode={4} />
				</Col>
				<Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12}>
					<ImgBox
						src={l1_m4_p6}
						description={'Фото Тимофеенко Ю.В. '}
						description_class='tir-down '
					/>
				</Col>
			</Row>
			<Row className='d-flex align-items-end mb-5'>
				<Col
					xxl={5}
					xl={5}
					lg={5}
					md={12}
					sm={12}
					xs={12}
					className=' mb-lg-0 mb-sm-5 mb-5'
				>
					<ImgBox
						src={l1_m4_p7}
						description={
							<p className='text-medium'>
								Фото Толчеевой С.В.
								<br />
								Чайки
							</p>
						}
						description_class='til-up '
					/>
				</Col>
				<Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12}>
					<ImgBox
						src={l1_m4_p8}
						description={
							<p className='text-medium'>
								Фото Толчеевой С.В.
								<br />
								Колпицы
							</p>
						}
						description_class='tir-up '
					/>
				</Col>
			</Row>
			<Row className='d-flex align-items-end mb-5'>
				<Col
					xxl={7}
					xl={7}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className=' mb-xl-0 mb-sm-5 mb-5'
				>
					<ImgBox
						src={l1_m4_p9}
						description={
							<p className='text-medium'>
								Фото Тимофеенко Ю. В. 
								<br />
								Бакланы на гнездах.
							</p>
						}
						mark_class={'mark-d-l-t'}
						description_class='tir-down '
					/>
				</Col>
				<Col xxl={5} xl={5} lg={12} md={12} sm={12} xs={12}>
					<TextBox element={text_9} blue mode={3} className=' mb-sm-5 mb-5 ' />
					<TextBox element={text_10} blue mode={2} className='mg-xxl-t-p-1' />
				</Col>
			</Row>
			<Row className='d-flex align-items-end mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className=' mb-lg-0 mb-sm-5 mb-5'
				>
					<ImgBox
						src={l1_m4_p10}
						description={'Чайка-хохотунья на чужом гнезде'}
						description_class='til-down '
					/>
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<ImgBox
						src={l1_m4_p11}
						description='Пеликаны'
						description_class='tir-down '
					/>
				</Col>
			</Row>
			<Row className='d-flex align-items-start mb-5'>
				<Col
					xxl={7}
					xl={7}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className=' mb-lg-0 mb-sm-5 mb-5'
				>
					<TextBox
						element={text_11}
						blue
						mode={1}
						clip={'blue_tr'}
						className='mb-sm-5 mb-5'
					/>
					<ImgBox
						src={l1_m4_p12}
						description={'Фото Узденова А.М.'}
						description_class='til-down '
					/>
				</Col>
				<Col xxl={5} xl={5} lg={6} md={12} sm={12} xs={12}>
					<TextBox element={text_12} blue mode={4} className='mb-sm-5 mb-5' />
					<TextBox element={text_13} blue mode={1} />
				</Col>
			</Row>
		</Container>
	)
}

export const Lection2_m4 = props => {
	const text_1 = (
		<p className='text-medium'>
			На острове Водный Островного участка заповедника «Ростовский» обитает
			вольный табун одичавших лошадей.
			<br />
			<br />
			Более 60 лет не знают они седла и уздечки. Живут по законам диких предков.
			Хотя эти кони и не являются исконно дикими обитателями степей, а лишь
			вольными одичавшими, их табун, пасущийся на степных травах, вызывает
			большой интерес. Уникальная популяция диких лошадей охраняется ЮНЕСКО.
		</p>
	)

	return (
		<Container className='pt-5 pb-sm-5 pb-4'>
			<Row className='d-flex align-items-center mb-5'>
				<Col
					xxl={5}
					xl={5}
					lg={5}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
				>
					<ImgBox
						src={l2_m4_p1}
						description={'Фото Узденова А.М.'}
						mark_class={'mark-d-l-t'}
						description_class='tir-down '
					/>
				</Col>
				<Col xxl={7} xl={7} lg={7} md={12} sm={12} xs={12}>
					<TextBox element={text_1} blue mode={1} className='h-xl-3' />
				</Col>
			</Row>
			<Row className='d-flex align-items-center mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
				>
					<ImgBox
						src={l2_m4_p2}
						description={'Фото Ануфриенко Ю.'}
						description_class='tir-down '
					/>
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<ImgBox src={l2_m4_p3} description_class='tir-down ' />
				</Col>
			</Row>
			<Row className='d-flex align-items-center mb-5'>
				<iframe
					src='https://vk.com/video_ext.php?oid=-75851720&id=456239069'
					width='100%'
					height='auto'
					min-height='320px'
					allow='autoplay; encrypted-media; fullscreen; picture-in-picture;'
					frameborder='0'
					allowfullscreen
				></iframe>
			</Row>

			<Row className='d-flex align-items-center mb-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
				>
					<ImgBox
						src={l2_m4_p4}
						description={'Фото Ануфриенко Ю.'}
						description_class='tir-down '
					/>
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<ImgBox
						src={l2_m4_p5}
						description_class='tir-down '
						description={'Фото Ануфриенко Ю.'}
						mark_class={'mark-d-r-t'}
					/>
				</Col>
			</Row>
		</Container>
	)
}
//TODO

export const Lection4_m4 = props => {
	const text_1 = (
		<p className='text-italic'>
			<b>Сайгак</b> — степная антилопа с «хоботом». Именно так многие описывают
			этот вид. Все из-за необычной внешности сайгака. По внешнему виду
			это небольшая антилопа размером с козу, с тонкими ногами и плотным
			телосложением. Украшением самцов являются лировидные заострённые рога,
			у самок рогов нет.
		</p>
	)
	const text_2 = (
		<p className='text-medium '>Что значит “полувольным содержанием”?</p>
	)
	const text_3 = (
		<p className='text-medium '>
			Это огромные территории, ограниченные по периметру забором. Вот представь:
			стоишь ты и конца вольера не видишь — вот такие полувольные условия!
			А забор нужен, чтобы защищать животных от браконьеров и хищников.
		</p>
	)
	const text_4 = (
		<p className='text-medium'>
			Сайгаки сейчас находятся на грани исчезновения. Большое влияние
			на это оказало активное вмешательство человека в условия обитания
			животных. Сайгак также занесён в Перечень особо ценных диких животных,
			принадлежащих к видам, занесённым в Красную книгу Российской Федерации
			и (или) охраняемым международными договорами.
		</p>
	)
	const text_5 = (
		<p className='text-medium'>
			<b className='text-subtitle'>тебенёвка</b> — пасьба копытных зимой,
			при которой животным приходится разбивать копытами снежный покров,
			чтобы достать пищу.
		</p>
	)
	const text_6 = (
		<p className='text-medium'>
			Сайгаки разгоняются до 80 км в час. Сайгаки спокойно могут перемещаться
			за сутки на 150 км.
		</p>
	)
	const fact = (
		<p className='fact-text text-center'>
			Необычный «хобот» у сайгака — это ещё одно важное приспособление к жизни
			в степи. Нос горбом и опущенные вниз мягкие ноздри помогают сайгакам
			прогревать воздух в морозные зимы и защищаться от пыльных бурь летом.
		</p>
	)

	return (
		<div>
			<Container className='pt-5 pb-sm-5 pb-4'>
				<Row className='d-flex align-items-center mb-5'>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
					>
						<TextBox element={text_1} blue mode={4} className='h-xl-2 h-lg-3' />
					</Col>
					<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
						<TextBox element={text_2} blue mode={3} className='h-xl-2 h-lg-3' />
					</Col>
				</Row>
				<Row className='d-flex align-items-center mb-5'>
					<Col
						xxl={6}
						xl={6}
						lg={6}
						md={12}
						sm={12}
						xs={12}
						className='md-md-0 mb-5
					mb-lg-0 mb-sm-5'
					>
						<TextBox element={text_3} blue mode={2} />
					</Col>
					<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
						<ImgBox
							src={l4_m4_p1}
							className='clip-blue-top-right p-0'
							description={'Фото из архива Ассоциации “Живая природа степи”'}
							description_class='til-down '
						/>
					</Col>
				</Row>
				<Row className='d-flex align-items-center mb-5'>
					<Col
						xxl={7}
						xl={7}
						lg={7}
						md={12}
						sm={12}
						xs={12}
						className='md-md-0 mb-5
					mb-lg-0 mb-sm-5 p-lg-5'
					>
						<ImgBox src={l4_m4_p2} mark_class={'mark-d-l-t'} />
					</Col>
					<Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
						<TextBox element={text_4} blue mode={1} className='h-xl-3' />
					</Col>
				</Row>

				<Row className='d-flex align-items-md-start align-items-sm-end justify-content-between mb-5'>
					<Col
						xxl={4}
						xl={4}
						lg={4}
						md={8}
						sm={9}
						xs={11}
						className=' offset-lg-3 offset-sm-0'
					>
						<TextBox element={text_5} />
					</Col>
					<Col
						xxl={5}
						xl={5}
						lg={5}
						md={5}
						sm={5}
						xs={5}
						className='offset-lg-0 offset-sm-7 offset-7'
					>
						<ImgBox src={horse} />
					</Col>
				</Row>
				<Row className='d-flex align-items-start mb-5  mg-sm-t-m-2'>
					<Col xxl={7} xl={7} lg={7} md={7} sm={7} xs={12}>
						<TextBox element={text_6} blue mode={4} />
					</Col>
				</Row>
			</Container>
			<Container fluid className='mg-sm-b-p-1 mg-xs-b-p-1 p-0'>
				<Fact text={fact} />
			</Container>
		</div>
	)
}
export const Lection3_m4 = props => {
	const text_1 = (
		<p className='text-medium'>
			Рядом с заповедником «Ростовский» располагается территория Ассоциации
			«Живая природа степи». Здесь воплощён инновационный проект — организован
			Центр редких животных европейских степей с вольерным и{' '}
			<b>полувольным содержанием</b> редких и исчезающих видов животных.
		</p>
	)
	const text_2 = (
		<p className='text-medium'>Что значит “полувольным содержанием”?</p>
	)
	const text_3 = (
		<p className='text-medium '>
			Это огромные территории, ограниченные по периметру забором. Вот представь:
			стоишь ты и конца вольера не видишь — вот такие полувольные условия!
			А забор нужен, чтобы защищать животных от браконьеров и хищников.
		</p>
	)
	const text_4 = [
		<p className='text-medium text-white'>
			Много лет специалисты Ассоциации разрабатывали методы по размножению
			сайгака в искусственных условиях. Сайгаки — очень свободолюбивые
			и пугливые животные. Они очень требовательны к условиям жизни. Этим
			степным животным обязательно нужны открытые пространства, разнообразный
			рацион, отсутствие фактора беспокойства и многое другое. В природе
			численность сайгака быстро сокращалась, а методов содержания и размножения
			сайгаков в вольерах на тот момент не было.
		</p>,
	]
	const text_5 = (
		<p className='text-medium   text-white'>
			Именно поэтому специалисты разработали успешный метод по содержанию
			и размножению сайгаков в вольерных условиях. С первых лет работы
			Ассоциации численность сайгаков активно увеличивалась и в настоящее время
			эти антилопы прекрасно проживают в стационаре Ассоциации «Живаяприрода
			степи».
		</p>
	)
	const text_6 = (
		<p className='text-medium text-white'>
			В России есть список <b>11 приоритетных видов животных</b>, которых нужно
			сохранить и преумножить (в рамках национального проекта «Экология»). В
			этот список входят сайгаки и лошади Пржевальского, которых тоже можно
			увидеть на территории Ассоциации «Живая природа степи».
		</p>
	)

	const text_7 = (
		<ul className='text-bold'>
			<li className='list-item list-number text-bold mb-4 mb-sm-4 fs-5 '>
				белый медведь
			</li>
			<li className='list-item list-number text-bold mb-4 mb-sm-4 fs-5'>
				амурский тигр
			</li>
			<li className='list-item list-number text-bold mb-4 mb-sm-4 fs-5'>
				дальневосточный леопард
			</li>
			<li className='list-item list-number text-bold mb-4 mb-sm-4 fs-5'>
				переднеазиатский леопард
			</li>
			<li className='list-item list-number text-bold mb-4 mb-sm-4 fs-5'>
				снежный барс
			</li>
			<li className='list-item list-number text-bold mb-4 mb-sm-4 fs-5'>
				зубр
			</li>
			<li className='list-item list-number text-bold mb-4 mb-sm-4 fs-5'>
				лошадь Пржевальского
			</li>
			<li className='list-item list-number text-bold mb-4 mb-sm-4 fs-5'>
				сайгак
			</li>
			<li className='list-item list-number text-bold mb-4 mb-sm-4 fs-5'>
				дзерен
			</li>
			<li className='list-item list-number text-bold mb-4 mb-sm-4 fs-5'>
				аргали
			</li>
			<li className='list-item list-number text-bold mb-4 mb-sm-4 fs-5'>
				стерх
			</li>
		</ul>
	)

	const text_8 = (
		<p className='text-medium '>
			По живописным степным просторам гуляют и другие обитатели Ассоциации:
			верблюды, яки, бизоны, буйволы, ламы и многие другие животные. Здесь
			в вольерах и в полувольных условиях содержатся краснокнижные и редкие виды
			степных животных: дрофа, олень Давида и Лошадь Пржевальского.
		</p>
	)
	const text_9 = (
		<p className='text-italic '>
			Первые лошади Пржевальского приехали из заповедника «Аскания-Нова». Лошади
			успешно прижились на донской земле и сейчас живут в полувольных условиях.
			В декабре 2021 года табун Ассоциации пополнился: из Оренбургской области
			перевезли жеребца лошади Пржевальского по кличке «Орго».
		</p>
	)

	const text_10 = (
		<p className='text-medium text-white '>
			Ассоциация «Живая природа степи» активно занимается экопросвещением.
			Ежегодно проводятся экологические программы, курсы, конкурсы и школьные
			мероприятия, направленные на развитие экологической культуры у жителей
			региона.
		</p>
	)

	return (
		<Container className='pt-5 pb-5'>
			<Row className='mb-5'>
				<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
					<TextBox element={text_1} blue mode={4} />
				</Col>
			</Row>

			<Row className='d-flex align-items-start justify-content-start'>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className=' mb-5 mb-sm-5 d-flex flex-row justify-content-start align-items-start '
				>
					<Col
						xxl={3}
						xl={3}
						lg={3}
						md={5}
						sm={5}
						xs={5}
						className=' mb-5 mb-sm-5'
					>
						<ImgBox src={man_2} />
					</Col>
					<Col
						xxl={4}
						xl={4}
						lg={4}
						md={6}
						sm={6}
						xs={6}
						className=' mb-5 mb-sm-5'
					>
						<TextBox blue mode={1} element={text_2} className='border-lb' />
					</Col>
				</Col>
			</Row>
			<Row className='d-flex align-items-end justify-content-lg-start  justify-content-end  mg-xxl-t-m-4 mg-xl-t-m-3 mg-lg-t-m-2'>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					xs={12}
					className=' mb-5 mb-sm-5 d-flex flex-row justify-content-end align-items-lg-end align-items-end '
				>
					<Col
						xxl={4}
						xl={4}
						lg={4}
						md={7}
						sm={7}
						xs={7}
						className=' mb-5 mb-sm-5'
					>
						<TextBox
							blue
							mode={1}
							element={text_3}
							className='border-rt  mb-5'
						/>
					</Col>
					<Col
						xxl={4}
						xl={4}
						lg={4}
						md={5}
						sm={5}
						xs={5}
						className=' mb-5 mb-sm-5'
					>
						<ImgBox src={bird_chaika} />
					</Col>
				</Col>
			</Row>

			<Row className='d-flex align-items-start justify-content-between flex-lg-row flex-column-reverse mb-5 mb-sm-5'>
				<Col
					xxl={7}
					xl={7}
					lg={7}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
						mb-lg-0 mb-sm-5'
				>
					<ImgBox
						mark_class={'mark-d-r-t'}
						src={l3_m4_p1}
						className='mb-5 mb-sm-5'
						description='Сайгаки в Ассоциации “Живая природа степи”'
						description_class='til-down'
					/>
					<TextBox element={text_4} blue mode={2} className='mb-5 mb-sm-5' />
					<TextBox element={text_5} blue mode={3} doubleBox_up />
				</Col>
				<Col xxl={5} xl={5} lg={5} md={12} sm={12} xs={12}>
					<TextBox
						element={text_6}
						blue
						mode={3}
						clip={'blue_tr'}
						className='mb-5 mb-sm-5'
					/>
					<TextBox
						element={text_7}
						dote
						blue
						mode={5}
						className='pd-xxl-t-p-1 pd-xxl-b-p-1 mb-lg-0 mb-sm-5 mb-5'
					/>
				</Col>
			</Row>
			<Row className='mb-5 mb-sm-5'>
				<Col
					xxl={10}
					xl={10}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					className='mb-5 mb-sm-5 offset-lg-1'
				>
					<ImgBox
						mark_class={'mark-double'}
						src={l3_m4_p2}
						description='Фото Узденова А.М.'
						description_class='tir-down '
					/>
				</Col>
				<Col
					xxl={10}
					xl={10}
					lg={10}
					md={12}
					sm={12}
					xs={12}
					className=' offset-lg-1'
				>
					<ImgBox
						mark_class={'mark-d-l-t'}
						src={l3_m4_p3}
						description='Сайгачонок'
						description_class='til-down'
					/>
				</Col>
			</Row>
			<Row className=' mb-5 mb-sm-5'>
				<Col
					xxl={6}
					xl={6}
					lg={6}
					md={12}
					sm={12}
					xs={12}
					className='md-md-0 mb-5
						mb-lg-0 mb-sm-5'
				>
					<TextBox
						element={text_8}
						blue
						mode={1}
						className='mb-5 mb-sm-5 h-xxl-3 h-lg-2'
					/>
					<TextBox element={text_9} blue mode={4} className='h-xl-3 h-lg-2' />
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<ImgBox
						mark_class={'mark-d-r-t'}
						src={l3_m4_p4}
						description='Яки в Ассоциации “Живая природа степи”'
						description_class='tir-down'
						className=' mb-5
						 mb-sm-5'
					></ImgBox>

					<ImgBox
						mark_class={'mark-d-l-b'}
						src={l3_m4_p5}
						description_class='til-down'
						className=' mb-5
						mb-sm-5'
					/>
				</Col>
			</Row>
			<Row className='d-flex align-items-end mb-5 mb-sm-5'>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<ImgBox
						src={l3_m4_p6}
						description='Фото из архива Ассоциации “Живая природа степи” '
						description_class='til-up'
						className=' mb-5
				 mb-sm-5 mb-lg-0'
					/>
				</Col>
				<Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
					<TextBox element={text_10} blue mode={3} className='h-xxl-3' />
				</Col>
			</Row>
			<Row className='d-flex align-items-end mb-5 mb-sm-5'>
				<Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
					<ImgBox
						mark_class={'mark-double-d-2'}
						src={l3_m4_p7}
						description='Фото Данькова В.И.
						Бизоны'
						description_class='til-down'
						className=' mb-5
				 mb-sm-5 mb-lg-0'
					/>
				</Col>
			</Row>
		</Container>
	)
}
