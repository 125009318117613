export function nextScreenTimer(setCurrentComic, currentComic, comicsName) {
	setTimeout(() => {
		setCurrentComic(currentComic + 1)
	}, 2000)
}

export function handleNextSlaid({ setCurrentComic, currentComic, comicsName }) {
	setCurrentComic(currentComic + 1)
}

export function handlePreviousSlaid({
	setCurrentComic,
	currentComic,
	comicsName,
}) {
	setCurrentComic(currentComic - 1)
}

export function handleSetSlaid({ setCurrentComic, slaidToSet, comicsName }) {
	setCurrentComic(slaidToSet)
}
export function findMaxProgress(attempts, moduleNumber) {
	return attempts
		.filter(attempt => attempt.module_number === moduleNumber)
		.reduce((max, current) =>
			max.module_progress > current.module_progress ? max : current
		)
}
function areArraysEqual(arr1, arr2) {
	return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort())
}

export function checkSubmit({ userAnswer, correctAnswer, setIsCorrect }) {
	const isArraysEquals = areArraysEqual(userAnswer, correctAnswer)
	isArraysEquals ? setIsCorrect(true) : setIsCorrect(false)
}

export function checkOneAnswer({
	userAnswer,
	correctAnswer,
	setIsCorrect,
	setHalfCorrect,
}) {
	console.log(userAnswer[0])
	console.log(correctAnswer)
	console.log(correctAnswer.find(i => i === userAnswer[0]))
	if (
		userAnswer.length === 1 &&
		correctAnswer.find(i => i === userAnswer[0]) !== undefined
	) {
		setHalfCorrect(true)
	} else if (userAnswer.length === 2) {
		const isArraysEquals = areArraysEqual(userAnswer, correctAnswer)
		isArraysEquals ? setIsCorrect(true) : setIsCorrect(false)
	} else {
		setIsCorrect(false)
	}
}

export function RenderBoards({ boards, styles }) {
	boards.map((board, index) => {
		if (board.description) {
			return (
				<p
					key={index}
					className={styles.boardDescriptionStyle}
					style={board.boardDescriptionStyle}
					dangerouslySetInnerHTML={{ __html: board.description }}
				></p>
			)
		} else {
			return null
		}
	})
}

export function formatDate(inputDate) {
	const date = new Date(inputDate)
	const day = date.getDate().toString().padStart(2, '0')
	const month = (date.getMonth() + 1).toString().padStart(2, '0') // Месяцы начинаются с 0
	const year = date.getFullYear().toString()

	return `${day}.${month}.${year}`
}
export function isValidEmail(email) {
	const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
	return emailPattern.test(email)
}

export function getNumList(currentValue, startValue) {
	const arr = []
	for (let i = startValue; currentValue <= i; i--) {
		arr.push(String(i))
	}

	return arr
}
