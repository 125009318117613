const FactUp = props => {
	return (
		<svg
			width='897'
			height='100'
			viewBox='0 0 897 100'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M845 64.5706C848.936 62.3314 868.28 60.3301 873.636 62.9257C878.991 65.5214 880.687 72.0285 879.403 74.6782C878.119 77.3279 875.168 78.8711 869.812 76.2754C864.456 73.6797 853.021 65.9697 845 64.5706Z'
				fill={props.firstColor}
				fill-opacity='0.8'
			/>
			<path
				d='M861.185 64.1001C863.51 63.3769 872.189 62.8008 874.389 64.7418C876.59 66.6829 877.509 69.13 876.424 70.3609C875.338 71.5919 873.425 70.7138 871.224 68.7727C869.023 66.8316 864.905 65.7172 861.185 64.1001Z'
				fill={props.secondColor}
				fill-opacity='0.8'
			/>
			<path
				d='M93.9991 69.3714C85.9523 61.8995 41.4094 46.4286 27.3265 49.5793C13.2436 52.73 4.10711 65.4749 5.66588 72.4423C7.22465 79.4097 32.5288 71.9962 46.6117 68.8456C60.6946 65.6949 74.2899 68.2171 93.9991 69.3714Z'
				fill={props.firstColor}
				fill-opacity='0.8'
			/>
			<path
				d='M36.0674 35.6062C33.9184 32.1833 23.2402 23.9569 14.2496 22.2186C4.98854 20.9996 -3.39718 32.0993 1.69455 35.4758C8.9259 38.3263 11.5039 33.1078 19.3239 33.4342C24.6308 33.6556 29.1704 33.3183 36.0674 35.6062Z'
				fill={props.firstColor}
				fill-opacity='0.8'
			/>
			<path
				d='M76.2249 49.4358C70.3278 48.4789 52.9833 38.6717 45.4442 27.4298C38.4565 15.5209 48.7461 -2.283 56.2728 2.70762C64.3142 10.9142 59.4223 15.8573 58.8461 25.4367C58.3747 33.2738 69.1418 41.3609 76.2249 49.4358Z'
				fill={props.firstColor}
				fill-opacity='0.8'
			/>
			<path
				d='M836.075 62.9562C837.959 56.1807 851.683 37.1593 865.814 29.7964C880.654 23.1652 900.15 37.5132 893.335 45.6723C882.69 54.0165 877.541 47.6519 866.408 45.7353C857.3 44.1674 846.441 55.7162 836.075 62.9562Z'
				fill={props.firstColor}
				fill-opacity='0.8'
			/>
			<path
				d='M16.9915 26.2873C15.6775 25.217 11.8263 24.6115 9.20356 24.9318C8.43317 25.0259 7.70166 25.3109 7.02468 25.6904C2.65889 28.1382 0.375579 32.0411 3.0415 32.7471C4.10994 32.7993 5.0411 32.5628 5.8833 32.1803C9.75862 30.4204 12.7353 26.3143 16.9915 26.2873Z'
				fill={props.secondColor}
				fill-opacity='0.8'
			/>
			<path
				d='M49.7889 26.8936C47.9933 25.9416 46.6542 20.9302 46.1301 17.7112C46.0053 16.9451 45.9717 16.1673 46.0499 15.3952C46.6493 9.47661 49.864 5.11512 51.8603 7.69459C52.1706 8.33924 52.3587 8.97852 52.4576 9.60999C53.3708 15.4452 47.5248 21.4386 49.7889 26.8936Z'
				fill={props.secondColor}
				fill-opacity='0.8'
			/>
			<path
				d='M865.876 34.9499C867.247 32.9367 873.48 32.0229 877.3 31.85C878.075 31.8149 878.853 31.8684 879.613 32.0243C886.569 33.4506 891.38 37.8427 888.071 39.8635C887.276 40.1429 886.504 40.2801 885.752 40.3138C878.806 40.6255 872.552 33.0084 865.876 34.9499Z'
				fill={props.secondColor}
				fill-opacity='0.8'
			/>
			<path
				d='M39.8307 55.8683C23.2121 53.3292 23.4611 52.2354 15.1725 58.4858C10.232 61.624 8.79438 68.2101 10.5498 70.9737C12.3052 73.7374 21.7787 64.5132 26.7192 61.3751C31.6598 58.2369 31.9973 57.8944 39.8307 55.8683Z'
				fill={props.secondColor}
				fill-opacity='0.8'
			/>
			<path
				d='M790.494 63.2227L728.127 70.4645L602.87 81.934C573.701 84.605 544.336 84.2815 515.233 80.9688L345.683 61.6693C312.333 57.8732 278.715 57.0045 245.214 59.0731L139.457 65.6035L140.424 64.9055C149.815 58.1246 160.995 54.2584 172.569 53.7887L240.669 51.0255C277.575 49.528 314.541 50.6505 351.289 54.3847L510.839 70.5976C536.881 73.2439 563.123 73.2684 589.171 70.6706L727.174 56.9069C748.491 54.7809 770.017 56.9281 790.494 63.2227Z'
				fill={props.firstColor}
				fill-opacity='0.8'
			/>
			<path
				d='M672.983 82.5316L624.329 73.0855L538.331 58.5203C507.626 53.3199 476.446 51.4783 445.343 53.0282L338.032 58.3755C302.667 60.1377 267.215 58.6295 232.128 53.8702L162.263 44.3934L166.282 46.852C170.113 49.1956 174.319 50.863 178.715 51.7817C247.502 66.1553 317.952 70.9007 388.044 65.882L437.197 62.3626C468.52 60.1199 500 61.7628 530.919 67.2541L622.778 83.5683C639.419 86.5237 656.478 86.1714 672.983 82.5316Z'
				fill={props.secondColor}
				fill-opacity='0.8'
			/>
		</svg>
	)
}

export default FactUp
