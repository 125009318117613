const BurgerIcon = (props) => {
  return (
    <svg
      width="29"
      height="20"
      viewBox="0 0 29 20"
      className={props.icon_styles}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="29" height="4" rx="2" className={props.icon_styles} />
      <rect y="8" width="29" height="4" rx="2" className={props.icon_styles} />
      <rect y="16" width="29" height="4" rx="2" className={props.icon_styles} />
    </svg>
  );
};

export default BurgerIcon;
