const BtnUpperBorder = props => {
	return (
		<svg
			width='26'
			height='27'
			viewBox='0 0 26 27'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={props.styles}
		>
			<path
				d='M16.2116 26.3831C16.6622 23.6404 13.6952 12.0799 11.0606 9.54984C8.42606 7.01974 4.45947 6.87514 3.20773 8.17857C1.95598 9.48199 7.05942 13.783 9.69397 16.3131C12.3285 18.8431 13.6982 22.0639 16.2116 26.3831Z'
				fill={props.firstColor}
			/>
			<path
				d='M15.2314 9.43943C15.6528 8.5073 15.8946 5.104 15.0036 2.96426C13.9654 0.840107 10.4509 0.623763 10.4508 2.17017C10.861 4.09436 12.3225 3.90824 13.3475 5.60354C14.0431 6.75399 14.7493 7.66448 15.2314 9.43943Z'
				fill={props.firstColor}
			/>
			<path
				d='M17.9317 19.845C17.3086 18.4671 16.9513 13.4365 18.2683 10.2736C19.803 7.13368 24.9981 6.81388 24.9982 9.09974C24.3918 11.944 22.6648 11.6035 20.5634 12.8219C18.8442 13.8187 18.6443 17.2213 17.9317 19.845Z'
				fill={props.firstColor}
			/>
			<path
				d='M14.5285 4.11163C14.554 3.85257 14.4126 3.42034 14.2005 3.00689C13.8461 2.31638 13.2008 1.80549 12.4483 1.6152C11.6548 1.4145 11.0454 1.57351 11.2144 2.07246C11.3529 2.30515 11.533 2.46849 11.7315 2.59265C12.6449 3.16399 13.9274 3.21757 14.5285 4.11163V4.11163Z'
				fill={props.secondColor}
			/>
			<path
				d='M18.9887 11.1152C18.9541 10.7632 19.3544 10.1884 19.8049 9.68128C20.3202 9.10099 20.9525 8.61203 21.6947 8.38514C22.7132 8.0738 23.5396 8.24367 23.3286 8.86662C23.236 9.02225 23.1274 9.15135 23.008 9.26053C21.9048 10.2694 19.8228 9.87455 18.9887 11.1152V11.1152Z'
				fill={props.secondColor}
			/>
			<path
				d='M11.4827 13.0678C9.69377 9.20686 9.95934 9.10637 7.48139 8.2322C6.1283 7.62895 4.53786 8.24696 4.20041 9.00384C3.86297 9.76072 7.13397 10.4689 8.48706 11.0721C9.84014 11.6754 9.9596 11.6987 11.4827 13.0678Z'
				fill={props.secondColor}
			/>
		</svg>
	)
}
export default BtnUpperBorder
