import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import birdBig from './birdBig.png'
import arrow from './btn_arrow.png'
import ishak from './ishak.png'

import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import styles from './styles.module.css'

const FullNewsItem = () => {
	const { slug } = useParams()

	const [post, setPost] = useState()

	const fetchData = useCallback(async () => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_SERVER_ENDPOINT}/posts?filters[slug][$eq]=${slug}`
			)
			setPost(response.data.data[0])
		} catch (error) {
			console.error('Error fetching data:', error)
		}
	}, [slug, setPost])

	const formDate = date => {
		const dateObject = new Date(date)
		const formattedDate = `${dateObject
			.getDate()
			.toString()
			.padStart(2, '0')}.${(dateObject.getMonth() + 1)
			.toString()
			.padStart(2, '0')}.${dateObject.getFullYear()}`
		return formattedDate
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<div className={styles.newsItem_full}>
			{post && (
				<div className={styles.container}>
					<div className={styles.navBar_full}>
						{post.id % 2 ? (
							<h1 className={styles.date_text_blue}>
								{formDate(post.attributes.createdAt)}
							</h1>
						) : (
							<h1 className={styles.date_text}>
								{formDate(post.attributes.createdAt)}
							</h1>
						)}
						{post.id % 2 ? (
							<img src={ishak} className={styles.animal_saiga} alt='animal' />
						) : (
							<img src={birdBig} className={styles.animal} alt='bird_animal' />
						)}
						<a href={`/news`} className={styles.btn_full}>
							<div
								className={`${styles.btn_container} ${styles.position_btn_container}`}
							>
								<img
									className={styles.btn_arrow_revert}
									src={arrow}
									alt='arrow_left'
								/>
								<h1 className={styles.btn_text}>Назад</h1>
							</div>
						</a>
					</div>
					<div className={styles.content}>
						<h1 className={styles.news_name}>{post.attributes.name}</h1>
						<p className={styles.date_mobile}>
							{formDate(post.attributes.createdAt)}
						</p>
						<div
							className={styles.news}
							dangerouslySetInnerHTML={{ __html: post.attributes.description }}
						/>
					</div>
					<Link to={'/news'} className={styles.btn_more}>
						<h1 className={styles.btn_text}>Назад</h1>
					</Link>
				</div>
			)}
		</div>
	)
}

export default FullNewsItem
