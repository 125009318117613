import React from "react";
import backArrowPicture from "./backArrow.png";
import { handlePreviousSlaid } from "../../Functions/Utils";
import cn from 'classnames'
import styles from './style.module.css'
const BackArrow = ({ setCurrentComic, currentComic, comicsName, lection, onClick}) => {
  const handlePrevious = () => {
    handlePreviousSlaid({ setCurrentComic, currentComic, comicsName });
  };
  return (
    <>
      {onClick ? ( <div
        loading="eager"
        className={styles.btn_back}
        onClick={onClick}
       
      >Вернуться</div>) :  <div
        loading="eager"
        className={styles.btn_back}
        onClick={handlePrevious}
    
     >Вернуться</div>}
    </>
   
  );
};

export default BackArrow;
