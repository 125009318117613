const BtnUpperBorder = props => {
	return (
		<svg
			width='222'
			height='28'
			viewBox='0 0 222 28'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={props.styles}
		>
			<path
				d='M17.3555 14.7944L38.4429 11.8589L63.8598 9.86659C90.1907 7.80261 116.743 8.17807 143.178 10.9882L145.576 11.2431C158.832 12.6523 172.151 12.6931 185.319 11.3647L210.888 8.78541V8.78541C203.852 14.0478 195.286 16.9705 186.349 17.1576L174.378 17.4083L123.659 13.3515C113.184 12.5136 102.688 12.5071 92.2714 13.3318L39.9829 17.4717C32.3898 18.0729 24.6991 17.1629 17.3555 14.7944V14.7944Z'
				fill={props.secondColor}
			/>
			<path
				d='M22.3398 13.5016C51.0121 16.9388 72.3221 14.484 90.3239 15.0098C97.0073 15.205 103.667 15.1143 110.309 15.0367C123.047 14.8878 130.325 17.8062 149.267 16.8003C131.646 15.5922 123.491 14.0341 110.499 12.9201C81.2587 10.4129 54.19 16.6144 24.9312 13.7623C24.0913 13.6805 23.2277 13.5936 22.3398 13.5016Z'
				fill={props.firstColor}
			/>
			<path
				d='M87.0742 10.8939C115.746 7.45668 137.056 9.91152 155.058 9.38572C161.742 9.19052 168.402 9.28119 175.044 9.35883C187.782 9.50774 195.059 6.58934 214.002 7.59525C192.716 9.05454 185.243 11.0246 166.37 12.1057C143.057 13.4412 122.056 8.27268 98.7202 9.86669C95.4502 10.0901 91.6039 10.4246 87.0742 10.8939Z'
				fill={props.firstColor}
			/>
			<path
				d='M19.8895 4.92669C18.4503 3.50573 10.3378 0.40612 7.72743 0.906594C5.11711 1.40707 3.36318 3.7017 3.61078 4.99314C3.85839 6.28458 8.55853 5.06273 11.1689 4.56225C13.7792 4.06178 16.2677 4.60287 19.8895 4.92669Z'
				fill={props.secondColor}
			/>
			<path
				d='M10.8707 10.6245C9.35856 10.5528 3.2902 12.8835 2.08666 14.4676C0.883116 16.0517 1.36238 18.2478 2.14612 18.8433C2.92985 19.4387 4.04299 19.4521 5.24653 17.8679C6.45007 16.2838 8.69162 12.2554 10.8707 10.6245Z'
				fill={props.secondColor}
			/>
			<path
				d='M13.1939 2.43313C12.3629 1.73199 8.23807 0.980376 6.99567 1.39704C5.75327 1.81371 4.95003 2.66093 5.1831 3.35589C5.41618 4.05086 7.62564 3.12468 8.86803 2.70801C10.1104 2.29134 11.3825 2.45268 13.1939 2.43313Z'
				fill={props.firstColor}
			/>
			<path
				d='M5.97237 12.9348C5.17002 13.0711 2.49613 14.2126 2.13397 15.1242C1.77181 16.0357 1.86799 16.9043 2.37789 17.1069C2.88779 17.3094 3.32518 16.7582 3.68733 15.8466C4.04949 14.9351 5.10825 13.9798 5.97237 12.9348Z'
				fill={props.firstColor}
			/>
			<path
				d='M205.755 21.5349C207.462 20.4508 216.047 19.1391 218.492 20.1808C220.937 21.2226 221.835 24.0884 221.319 25.2981C220.804 26.5079 219.521 27.2601 217.076 26.2183C214.631 25.1766 209.363 21.9852 205.755 21.5349Z'
				fill={props.secondColor}
			/>
			<path
				d='M212.967 20.9743C213.988 20.6014 217.847 20.1564 218.871 20.9745C219.894 21.7927 220.358 22.8643 219.9 23.4369C219.442 24.0095 218.57 23.6592 217.546 22.8411C216.523 22.0229 214.662 21.615 212.967 20.9743Z'
				fill={props.firstColor}
			/>
		</svg>
	)
}
export default BtnUpperBorder
