import { Link } from 'react-router-dom'
import BackArrow from '../Components/lectionBackArrow/index.jsx'
import Header from '../Components/Header/Header.jsx'
import NextArrow from '../Components/LectionNextArrow/index.jsx'
import styles from '../Lection/lection.module.css'
const LectionContainer = props => {
	return (
		<div className={styles.container}>
			<Header lection={true} />
			{props.hint && (
				<Link className={styles.btn_back} to='/map'>
					Вернуться
				</Link>
			)}
			{props.leftButton && (
				<BackArrow
					setCurrentComic={props.setCurrentComic}
					currentComic={props.currentComic}
					comicsName={props.comicsName}
					lection
				/>
			)}
			{props.onClick && <BackArrow onClick={props.onClick} lection />}
			{props.rightButton && (
				<NextArrow
					setCurrentComic={props.setCurrentComic}
					currentComic={props.currentComic}
					comicsName={props.comicsName}
					lection
				/>
			)}

			<div className={styles.content}>
				<div className={styles.content_info}>
					<p className={styles.content_name}>{props.name}</p>
				</div>
				<div className={styles.content_element}>{props.element}</div>
			</div>
		</div>
	)
}

export default LectionContainer
