// AvatarSlider.js

import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import cn from 'classnames';
import partner1 from "../Footer/partner1.png";
import partner2 from "../Footer/partner2.png";
import partner3 from "../Footer/partner3.png";
import partner4 from "../Footer/partner4.png";
import partner5 from "../Footer/partner5.png";
import partner6 from "../Footer/partner6.png";
import partner7 from "../Footer/partner7.png";
import partner8 from "../Footer/partner8.png";
import { style } from '@mui/system';
const AvatarSlider = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imgSrc = [
    partner1,
    partner2,
    partner3,
    partner4,
    partner5,
    partner6,
    partner7,
    partner8
  ]
  const handleNext = () => {
    if (currentIndex + 2 >= props.imageLinks.length - 1) {
      const newIndex = 0
      setCurrentIndex(newIndex);
    } else {
      const newIndex = (currentIndex + 1) % props.imageLinks.length;
      setCurrentIndex(newIndex);
    }
   
    
  };

  const handlePrev = () => {
    if (currentIndex - 2 < -1) {
      const newIndex = props.imageLinks.length - 3
      setCurrentIndex(newIndex);
    } else {
      const newIndex = (currentIndex - 1 + props.imageLinks.length) % props.imageLinks.length;
      setCurrentIndex(newIndex);
    }
   
  };

 

  return (
    <div id={styles.partnersContainerMobile} className={styles.partnersContainer}>
      <div className={styles['arrow'] + ' ' + styles['arrow_left']} onClick={handlePrev}>

        <div class={styles["arrow-top"]}></div>
        <div class={styles["arrow-bottom"]}></div>

      </div>
      <div className={styles.partnerContainer}>
      <a href={props.imageLinks[currentIndex]} target="_blank" rel="noopener noreferrer">
        <img
          src={imgSrc[currentIndex]}
          alt=""
          className={cn(styles.avatar_img, props.avatarStyleImg)}
        />
      </a>
      </div>
      <div className={styles.partnerContainer}>
      <a href={props.imageLinks[currentIndex + 1]} target="_blank" rel="noopener noreferrer">
        <img
          src={imgSrc[currentIndex + 1]}
          alt=""
          className={cn(styles.avatar_img, props.avatarStyleImg)}
        />
      </a>
      </div>
      <div className={styles.partnerContainer}>
      <a href={props.imageLinks[currentIndex + 2]} target="_blank" rel="noopener noreferrer">
        <img
          src={imgSrc[currentIndex + 2]}
          alt=""
          className={cn(styles.avatar_img, props.avatarStyleImg)}
        />
      </a>
      </div>
     
      
      <div className={cn(styles['arrow'], styles['arrow_right'])} onClick={handleNext}>

        <div class={styles["arrow-top"]}></div>
        <div class={styles["arrow-bottom"]}></div>

      </div>
    </div>
  );
};

export default AvatarSlider;
