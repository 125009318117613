const FactDown = props => {
	return (
		<svg
			width='866'
			height='106'
			viewBox='0 0 866 106'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M33.4635 44.6131C29.7526 42.9891 12.3586 43.0161 7.83694 45.8255C3.31526 48.6349 2.41482 54.5817 3.80474 56.8187C5.19467 59.0558 7.96408 60.1527 12.4858 57.3434C17.0074 54.534 26.459 46.6071 33.4635 44.6131Z'
				fill={props.firstColor}
				fill-opacity='0.8'
			/>
			<path
				d='M40.3569 60.7147C35.0142 63.0579 18.0593 81.5004 16.6188 89.0309C15.1783 96.5614 20.5954 103.321 24.321 104.034C28.0467 104.746 31.9121 102.885 33.3526 95.3549C34.7931 87.8245 35.6289 70.0778 40.3569 60.7147Z'
				fill={props.firstColor}
				fill-opacity='0.8'
			/>
			<path
				d='M19.0217 45.7063C16.8862 45.2802 9.11239 45.5786 7.33626 47.5109C5.56014 49.4432 4.97063 51.7059 6.0515 52.6994C7.13237 53.6929 8.75227 52.7331 10.5284 50.8008C12.3045 48.8685 15.8635 47.4924 19.0217 45.7063Z'
				fill={props.secondColor}
				fill-opacity='0.8'
			/>
			<path
				d='M27.4168 77.072C24.8805 78.917 17.6061 87.4389 17.9177 91.2061C18.2294 94.9733 20.0495 97.8068 22.1567 97.6324C24.264 97.4581 24.8294 94.8058 24.5178 91.0386C24.2061 87.2714 26.2242 82.1597 27.4168 77.072Z'
				fill={props.secondColor}
				fill-opacity='0.8'
			/>
			<path
				d='M792 22.9788C800.229 19.5969 838.425 20.5209 848.215 26.9151C858.005 33.3093 861.315 45.5772 858.151 50.4207C854.988 55.2643 837.895 42.4455 828.105 36.0513C818.315 29.6571 807.282 27.7059 792 22.9788Z'
				fill={props.firstColor}
				fill-opacity='0.8'
			/>
			<path
				d='M814.796 44.2874C821.071 46.5194 841.882 66.1354 844.137 74.5925C846.392 83.0496 840.775 91.1888 836.591 92.3044C832.407 93.42 827.854 91.6164 825.599 83.1593C823.344 74.7022 820.944 54.5629 814.796 44.2874Z'
				fill={props.firstColor}
				fill-opacity='0.8'
			/>
			<path
				d='M823.415 24.0036C827.968 22.5367 846.013 26.3622 850.374 30.132C854.736 33.9018 856.592 38.6911 854.483 41.131C852.374 43.5708 844.926 36.1129 840.565 32.3431C836.203 28.5733 830.756 27.1067 823.415 24.0036Z'
				fill={props.secondColor}
				fill-opacity='0.8'
			/>
			<path
				d='M830.865 61.8617C833.903 63.7555 842.882 72.8653 842.834 77.1803C842.787 81.4953 840.946 84.8702 838.532 84.8437C836.118 84.8173 835.258 81.8433 835.306 77.5283C835.353 73.2133 832.638 67.5575 830.865 61.8617Z'
				fill={props.secondColor}
				fill-opacity='0.8'
			/>
			<path
				d='M784.537 42.7747C787.894 43.2102 797.892 48.4505 802.363 54.6801C806.535 61.2973 801.029 71.5639 796.677 68.8738C791.976 64.372 794.657 61.4848 794.811 56.0503C794.936 51.6042 788.694 47.2192 784.537 42.7747Z'
				fill={props.firstColor}
				fill-opacity='0.8'
			/>
			<path
				d='M799.911 55.0626C800.855 55.5251 801.644 57.9095 802.045 59.7009C802.214 60.4583 802.279 61.2388 802.189 62.0097C801.828 65.1138 800.202 67.3235 799.084 65.971C798.897 65.6116 798.779 65.253 798.712 64.8972C798.089 61.6096 801.291 58.1105 799.911 55.0626Z'
				fill={props.secondColor}
				fill-opacity='0.8'
			/>
			<path
				d='M79.6907 49.4664L141.748 38.3629L256.448 21.3446C292.134 16.0498 328.345 15.2775 364.224 19.0461L512.742 34.6457C553.866 38.9652 595.337 38.836 636.433 34.2604L730.259 23.8139C720.59 33.0137 708.074 38.6436 694.775 39.7753L642.502 44.2233C597.15 48.0825 551.548 47.9697 506.216 43.8862L367.966 31.4329C335.856 28.5405 303.511 29.6306 271.667 34.6787L143.779 54.9526C122.288 58.3596 100.29 56.4765 79.6907 49.4664Z'
				fill={props.secondColor}
				fill-opacity='0.8'
			/>
			<path
				d='M188.567 23.8682L236.812 30.7398L324.381 41.0683C353.207 44.4682 382.303 44.9339 411.223 42.4582L522.983 32.8908C556.122 30.0539 589.446 30.0977 622.578 33.0218L693.723 39.3009C686.092 33.5232 676.95 30.079 667.403 29.3844L635.361 27.053C593.702 24.0221 551.876 24.0987 510.229 27.2824L414.409 34.6074C388.172 36.6131 361.8 35.9651 335.693 32.6734L237.819 20.3327C221.329 18.2536 204.591 19.4551 188.567 23.8682Z'
				fill={props.firstColor}
				fill-opacity='0.8'
			/>
		</svg>
	)
}

export default FactDown
