import React, { createContext, useContext, useState } from 'react'

const UserContext = createContext()
export const useUserContext = () => {
	return useContext(UserContext)
}

export const UserProvider = ({ children }) => {
	const [modulePoints, setModulePoints] = useState({})
	const [user, setUser] = useState({})

	const updateModulePoints = (currentComic, type, newStatus) => {
		if (modulePoints.hasOwnProperty(`comic:${currentComic}`)) {
			modulePoints[`comic:${currentComic}`] = {
				...modulePoints[`comic:${currentComic}`],
				[type]: newStatus,
			}
		} else {
			modulePoints[`comic:${currentComic}`] = { [type]: newStatus }
		}
		setModulePoints(modulePoints)
	}

	const clearModulePoints = () => {
		setModulePoints({}) // Vacía el objeto modulePoints
	}

	return (
		<UserContext.Provider
			value={{
				modulePoints,
				updateModulePoints,
				clearModulePoints,
				user,
				setUser,
			}}
		>
			{children}
		</UserContext.Provider>
	)
}
