import cn from 'classnames'
import { Link } from 'react-router-dom'
import PartnerSlider from '../PartnerSlider/AvatarSlider.jsx'
import mailLogo from './mailicon.png'
import vklogo from './vk.png'
import partner1 from './partner1.png';
import partner2 from './partner2.png';
import partner3 from './partner3.png';
import partner4 from './partner4.png';
import partner5 from './partner5.png';
import partner6 from './partner6.png';
import partner7 from './partner7.png';
import partner8 from './partner8.png';
import styles from './styles.module.css'


const Footer = (props) => {
  const imageLinks = [
    'https://природастепи.рф',
    'https://sfedu.ru',
    'https://www.ssc-ras.ru',
    'https://www.rgo.ru/ru/rostovskoe-oblastnoe-otdelenie',
    'https://xn--d1ahaoghbejbc5k.xn--p1ai/?ysclid=lpsa4l88zr893190762',
    'https://yaest.ru',
    'http://www.rgpbz.ru/',
    'https://oopt.donland.ru',
  ];
  return (
    <div className={styles.footerContainer}>
      <h3
        className={cn(styles.footerTitle, { [styles.text_white]: props.white })}
      >
        Наши партнёры
      </h3>
      <PartnerSlider imageLinks={imageLinks}  avatarStyleImg={styles.partner_img}/>
      <div id={styles.partnersContainerDesktop} className={styles.partnersContainer}>
        <div className={styles.partnerContainer}>
          <Link target="_blank" to="https://природастепи.рф">
            <img className={styles.partner_img} src={partner1} alt="partner" />
          </Link>
        </div>
        <div className={styles.partnerContainer}>
          <Link target="_blank" to="https://sfedu.ru">
            <img className={styles.partner_img} src={partner2} alt="partner" />{" "}
          </Link>
        </div>
        <div className={styles.partnerContainer}>
          <Link target="_blank" to="https://www.ssc-ras.ru">
            <img className={styles.partner_img} src={partner3} alt="partner" />
          </Link>
        </div>
        <div className={styles.partnerContainer}>
          <Link target="_blank" to="https://www.rgo.ru/ru/rostovskoe-oblastnoe-otdelenie">
            <img className={styles.partner_img} src={partner4} alt="partner" />
          </Link>
        </div>
        <div className={styles.partnerContainer}>
          <Link target="_blank" to="https://xn--d1ahaoghbejbc5k.xn--p1ai/?ysclid=lpsa4l88zr893190762">
            <img className={styles.partner_img} src={partner5} alt="partner" />
          </Link>
        </div>
        <div className={styles.partnerContainer}>
          <Link target="_blank" to="https://yaest.ru">
            <img className={styles.partner_img} src={partner6} alt="partner" />
          </Link>
        </div>
        <div className={styles.partnerContainer}>
          <Link target="_blank" to="http://www.rgpbz.ru/">
            <img className={styles.partner_img} src={partner7} alt="partner" />
          </Link>
        </div>
       
        <div className={styles.partnerContainer}>
          <Link target="_blank" to="https://oopt.donland.ru">
            <img className={styles.partner_img} src={partner8} alt="partner" />
          </Link>
        </div>
      </div>
      <div className={styles.footerContact}>
        
        <Link target='_blank' to="https://vk.com/prirodastepi">
          <img className={styles.img} src={vklogo} alt="vklogo" />
        </Link>
        
        <Link to="mailto:priroda.rostov@yandex.ru">
          <img className={styles.img} src={mailLogo} alt="mailLogo" />
        </Link>
        
      </div>
    </div>
  );
};


export default Footer
