import React, { useEffect, useState } from 'react'
import styles from './index.module.css'

import cn from 'classnames'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import FullNewsItem from '../../Components/NewsItem/fullNews'

const FullNewsPage = () => {
	const [modalMessage, setModalMessage] = useState({
		status: false,
		message: '',
		type: '',
	})
	const closeModalBoxHandler = () => {
		setModalMessage({
			status: false,
			message: '',
			type: '',
		})
	}
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<div
			className={cn(styles.greatContainer, {
				[styles.fixed]: modalMessage.status,
			})}
		>
			<Header landing={true} bcg={true} />
			<div className={styles.newsContainer_full}>
				<FullNewsItem />
			</div>

			<Footer white={true} />
		</div>
	)
}

export default FullNewsPage
