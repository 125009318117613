import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import Header from './Components/Header/Header'
import {
	Hint1_m1,
	Hint1_m2,
	Hint1_m3,
	Hint1_m4,
	Hint2_m1,
	Hint2_m2,
	Hint2_m3,
	Hint2_m4,
	Hint3_m1,
	Hint3_m2,
	Hint3_m3,
	Hint3_m4,
	Hint4_m1,
	Hint4_m2,
	Hint4_m3,
	Hint4_m4,
	Hint5_m2,
	Hint5_m3,
	Hint5_m4,
	Hint6_m2,
	
} from './Lection/Hint'
import {
	Lection0,
	Lection1,
	Lection1_m2,
	Lection1_m3,
	Lection1_m4,
	Lection2,
	Lection2_m2,
	Lection2_m3,
	Lection2_m4,
	Lection3,
	Lection3_m2,
	Lection3_m3,
	Lection3_m4,
	Lection4,
	Lection4_m2,
	Lection4_m3,
	Lection5,
	Lection5_m2,
	Lection6,
	Lection6_m2,
	Lection7,
	Lection7_m2,
	Lection8,
	Lection8_m2,
	Lection9,
	Lection9_m2,
} from './Lection/Lection'
import LectionContainer from './Lection/LectionContainer'
import FullNewsPage from './Pages/News/fullNewsPage'
import UI from './UI_Kit/UI'
const HintHorse = React.lazy(() => import('./Pages/Hints/Module1/Horse'))
const HintCamel = React.lazy(() => import('./Pages/Hints/Module1/Camel'))
const HintDzeren = React.lazy(() => import('./Pages/Hints/Module1/Dzeren'))
const HintCrane = React.lazy(() => import('./Pages/Hints/Module1/Crane'))
const HintCitate = React.lazy(() => import('./Pages/Hints/Module1/Citate'))
const HintSnake = React.lazy(() => import('./Pages/Hints/Module1/Snake'))
const HintSpring = React.lazy(() => import('./Pages/Hints/Module1/Spring'))
const HintDauria = React.lazy(() =>
	import('./Pages/Hints/Module1/MongolDauria')
)
const HintRedBook = React.lazy(() =>
	import('./Pages/Hints/Module1/RedBookRebus')
)
const HintVimmelbuh1 = React.lazy(() =>
	import('./Pages/Hints/Module1/Vimmelbuh')
)
const HintVimmelbuh3 = React.lazy(() =>
	import('./Pages/Hints/Module3/Vimmelbuh')
)
const HintModule2Citate = React.lazy(() =>
	import('./Pages/Hints/Module2/Citate')
)
const HintAskania = React.lazy(() => import('./Pages/Hints/Module2/Askania'))
const HintStone = React.lazy(() => import('./Pages/Hints/Module2/Stone'))
const HintTarpan = React.lazy(() => import('./Pages/Hints/Module2/Tarpan'))
const HintSilkRoad = React.lazy(() => import('./Pages/Hints/Module2/SilkRoad'))
const HintReservoir = React.lazy(() =>
	import('./Pages/Hints/Module2/Reservoir')
)
const HintYurt = React.lazy(() => import('./Pages/Hints/Module2/Yurt'))
const HintYurtLection = React.lazy(() =>
	import('./Pages/Hints/Module2/YurtLection')
)
const HintOrenburgLection = React.lazy(() =>
	import('./Pages/Hints/Module3/OrenburgLection')
)
const HintReservedEstates = React.lazy(() =>
	import('./Pages/Hints/Module3/ReservedEstates')
)
const HintEagle = React.lazy(() => import('./Pages/Hints/Module3/Eagle'))
const HintVideoHorse = React.lazy(() =>
	import('./Pages/Hints/Module3/VideoHorse')
)
const HintBlackSoil = React.lazy(() =>
	import('./Pages/Hints/Module3/BlackSoil')
)
const HintSaiga = React.lazy(() => import('./Pages/Hints/Module3/Saiga'))
const HintButterfly = React.lazy(() =>
	import('./Pages/Hints/Module4/YoungNatural')
)
const HintEzh = React.lazy(() => import('./Pages/Hints/Module4/Ezh'))
const HintFactsSaiga = React.lazy(() =>
	import('./Pages/Hints/Module4/FactsSaiga')
)
const HintLakeManych = React.lazy(() =>
	import('./Pages/Hints/Module4/LakeManych')
)
const HintPelican = React.lazy(() => import('./Pages/Hints/Module4/Pelican'))
const HintLizard = React.lazy(() => import('./Pages/Hints/Module4/Lizard'))
const HintBustard = React.lazy(() => import('./Pages/Hints/Module4/Bustard'))
const HintStepi = React.lazy(() => import('./Pages/Hints/Module4/StepiHint'))
const HintHorses = React.lazy(() => import('./Pages/Hints/Module4/Horses'))
const IndexPage = React.lazy(() => import('./Pages/IndexPage'))
const AboutPlatform = React.lazy(() => import('./Pages/AboutPlatform'))
const PrivatePolitic = React.lazy(() => import('./Pages/PrivatePolitic'))
const StartTravelPage = React.lazy(() => import('./Pages/StartTravel'))
const AboutTeam = React.lazy(() => import('./Pages/AboutTeam'))
const NewsPage = React.lazy(() => import('./Pages/News'))
const IntroductoryComic = React.lazy(() => import('./Comics/IntroductoryComic'))
const SignIn = React.lazy(() => import('./Pages/SignIn'))
const SignUp = React.lazy(() => import('./Pages/SignUp'))
const Home = React.lazy(() => import('./Pages/Home'))
const Map = React.lazy(() => import('./Pages/Map'))
const EmailConfirmation = React.lazy(() => import('./Pages/EmailConfirmation'))
const Module1 = React.lazy(() => import('./Modules/Module1'))
const Module2 = React.lazy(() => import('./Modules/Module2'))
const Module3 = React.lazy(() => import('./Modules/Module3'))
const Module4 = React.lazy(() => import('./Modules/Module4'))
const Module4FinalTest = React.lazy(() => import('./Modules/Module4FinalTask'))
const StudentScorePage = React.lazy(() => import('./Pages/StudentScorePage'))
const TryModule = React.lazy(() => import('./Modules/TryModule'))
const PageNotFound = React.lazy(() => import('./Pages/PageNotFound'))

const App = () => {
	return (
		<div className='App'>
			<Routes>
				<Route
					path='/'
					element={
						<Suspense fallback={<></>}>
							<IndexPage />
						</Suspense>
					}
				/>
				<Route
					path='/introductorycomic'
					element={
						<Suspense fallback={<></>}>
							<IntroductoryComic />
						</Suspense>
					}
				/>
				<Route
					path='/sign-in'
					element={
						<Suspense fallback={<></>}>
							<SignIn />
						</Suspense>
					}
				/>
				<Route
					path='/sign-up'
					element={
						<Suspense fallback={<></>}>
							<SignUp />
						</Suspense>
					}
				/>
				<Route
					path='/news'
					element={
						<Suspense fallback={<></>}>
							<NewsPage />
						</Suspense>
					}
				/>
				<Route
					path='/news/:slug'
					element={
						<Suspense fallback={<></>}>
							<FullNewsPage />
						</Suspense>
					}
				/>

				<Route
					path='/home'
					element={
						<Suspense fallback={<></>}>
							<Home />
						</Suspense>
					}
				/>
				<Route
					path='/map'
					element={
						<Suspense fallback={<></>}>
							<Map />
						</Suspense>
					}
				/>
				<Route
					path='/about-us'
					element={
						<Suspense fallback={<></>}>
							<AboutPlatform />
						</Suspense>
					}
				/>
				<Route
					path='/mainpage'
					element={
						<Suspense fallback={<></>}>
							<StartTravelPage />
						</Suspense>
					}
				/>
				<Route
					path='/about-team'
					element={
						<Suspense fallback={<></>}>
							<AboutTeam />
						</Suspense>
					}
				/>
				<Route
					path='/email-confirmation'
					element={
						<Suspense fallback={<></>}>
							<EmailConfirmation />
						</Suspense>
					}
				/>
				<Route
					path='/module-1'
					element={
						<Suspense fallback={<></>}>
							<Module1 />
						</Suspense>
					}
				/>
				<Route
					path='/module-2'
					element={
						<Suspense fallback={<></>}>
							<Module2 />
						</Suspense>
					}
				/>
				<Route
					path='/module-3'
					element={
						<Suspense fallback={<></>}>
							<Module3 />
						</Suspense>
					}
				/>
				<Route
					path='/module-4'
					element={
						<Suspense fallback={<></>}>
							<Module4 />
						</Suspense>
					}
				/>
				<Route
					path='/module-4-final-test'
					element={
						<Suspense fallback={<></>}>
							<Module4FinalTest />
						</Suspense>
					}
				/>
				<Route
					path='/studentscore'
					element={
						<Suspense fallback={<></>}>
							<StudentScorePage />
						</Suspense>
					}
				/>
				<Route
					path='/tests'
					element={
						<Suspense fallback={<></>}>
							<TryModule />
						</Suspense>
					}
				/>
				<Route
					path='/private'
					element={
						<Suspense fallback={<></>}>
							<PrivatePolitic />
						</Suspense>
					}
				/>

				<Route
					path='/hints-citate'
					element={
						<Suspense fallback={<></>}>
							<HintCitate />
						</Suspense>
					}
				/>
				<Route
					path='/hints-snake'
					element={
						<Suspense fallback={<></>}>
							<HintSnake />
						</Suspense>
					}
				/>
				<Route
					path='/hints-spring'
					element={
						<Suspense fallback={<></>}>
							<HintSpring />
						</Suspense>
					}
				/>

				<Route
					path='/hints-module2-citate'
					element={
						<Suspense fallback={<></>}>
							<HintModule2Citate />
						</Suspense>
					}
				/>

				<Route
					path='/hints-pinkFlower-1'
					element={
						<Suspense fallback={<></>}>
							<HintVimmelbuh1 />
						</Suspense>
					}
				/>
				<Route
					path='/hints-pinkFlower-3'
					element={
						<Suspense fallback={<></>}>
							<HintVimmelbuh3 />
						</Suspense>
					}
				/>
				<Route
					path='/hints-yurt'
					element={
						<Suspense fallback={<></>}>
							<HintYurt />
						</Suspense>
					}
				/>
				

				<Route
					path='/hints-videoHorse'
					element={
						<Suspense fallback={<></>}>
							<HintVideoHorse />
						</Suspense>
					}
				/>

				<Route
					path='/hints-butterfly'
					element={
						<Suspense fallback={<></>}>
							<HintButterfly />
						</Suspense>
					}
				/>
				<Route
					path='/hints-ezh'
					element={
						<Suspense fallback={<></>}>
							<HintEzh />
						</Suspense>
					}
				/>

				<Route
					path='/hints-pelican'
					element={
						<Suspense fallback={<></>}>
							<HintPelican />
						</Suspense>
					}
				/>

				<Route
					path='/hints-bustard'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Hint5_m4 />}
								name={'Дрофа'}
							/>
						</Suspense>
					}
				/>

				<Route
					path='/hints-horses'
					element={
						<Suspense fallback={<></>}>
							<HintHorses />
						</Suspense>
					}
				/>
				<Route
					path='/hints-redbook'
					element={
						<Suspense fallback={<></>}>
							<HintRedBook />
						</Suspense>
					}
				/>
				<Route
					path='*'
					element={
						<Suspense fallback={<></>}>
							<PageNotFound />
						</Suspense>
					}
				/>
				<Route
					path='/header'
					element={
						<Suspense fallback={<></>}>
							<Header />
						</Suspense>
					}
				/>

				<Route
					path='/ui'
					element={
						<Suspense fallback={<></>}>
							<UI />
						</Suspense>
					}
				/>
				<Route
					path='/l1'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection1 />}
								name={'Международный заповедник Даурия'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/l2'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection2 />}
								name={'Лекго ли жить в степи?'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/l3'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection3 />}
								name={'Что же харакетризует степь?'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/l4'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection4 />}
								name={'Что же харакетризует степь?'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/l5'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection5 />}
								name={'Что же харакетризует степь?'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/l6'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection6 />}
								name={'Где располагается степной пояс Евразии?'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/l7'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection7 />}
								name={'Различается ли степной пейзаж в разных регионах?'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/l8'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection8 />}
								name={'Лекго ли жить в степи?'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/l9'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection9 />}
								name={'Лекго ли жить в степи?'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/m2l1'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection1_m2 />}
								name={'Степные тайны древнейших людей'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/m2l2'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection2_m2 />}
								name={'...и кое-что о собаках'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/m2l3'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection3_m2 />}
								name={'“Аскания-Нова”'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/m2l4'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection4_m2 />}
								name={'Новый каменный век, рыбы и быки'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/m2l5'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection5_m2 />}
								name={
									'Кочевники-хозяева степей, или Как приручили первую лошадь'
								}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/m2l6'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection6_m2 />}
								name={
									'Как вы думаете, появилось бы в парках аттракционов колесо обозрения, если бы люди когда-то не изобрели колеса?'
								}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/m2l7'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection7_m2 />}
								name={'Бронза, лошадь и колесо'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/m2l8'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection8_m2 />}
								name={'Великая Степь-перекрёсток цивилизации'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/m2l9'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection9_m2 />}
								name={'Великая Степь-перекрёсток цивилизации'}
							/>
						</Suspense>
					}
				/>

				<Route
					path='/m3l1'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection1_m3 />}
								name={'Великая Степь-перекрёсток цивилизации'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/m3l2'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection2_m3 />}
								name={'Великая Степь-перекрёсток цивилизации'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/m3l3'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection3_m3 />}
								name={'Великая Степь-перекрёсток цивилизации'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/m3l4'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection4_m3 />}
								name={'Великая Степь-перекрёсток цивилизации'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/m4l1'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection1_m4 />}
								name={'Великая Степь-перекрёсток цивилизации'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/m4l2'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection2_m4 />}
								name={'Великая Степь-перекрёсток цивилизации'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/m4l3'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								element={<Lection3_m4 />}
								name={'Великая Степь-перекрёсток цивилизации'}
							/>
						</Suspense>
					}
				/>

				<Route
					path='/hints-camel'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Hint1_m1 />}
								name={'Корабли в степи?'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/hints-dzeren'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Hint2_m1 />}
								name={'Монгольский дзерен, или зобастая антилопа'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/hints-crane'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Hint3_m1 />}
								name={'Даурский журавль'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/hints-dauria'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Hint4_m1 />}
								name={'Многоликая Даурия'}
							/>
						</Suspense>
					}
				/>

				<Route
					path='/hints-askania'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Hint1_m2 />}
								name={'«Аскания-Нова»'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/hints-stone'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Hint2_m2 />}
								name={'Таинственные статуи степи'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/hints-horse'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Lection0 />}
								name={'История Лошади Пржевальского'}
							/>
						</Suspense>
					}
				/>

				<Route
					path='/hints-yurtLection'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer hint element={<Hint3_m2 />} name={'Юрты'} />
						</Suspense>
					}
				/>

				<Route
					path='/hints-tarpan'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer hint element={<Hint4_m2 />} name={'Тарпан'} />
						</Suspense>
					}
				/>
				<Route
					path='/hints-reservoir'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Hint5_m2 />}
								name={'Что скрывается на дне Цимлянского водохранилища?'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/hints-silkRoad'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Hint6_m2 />}
								name={'Великий Шёлковый путь'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/hints-orenburg'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Hint1_m3 />}
								name={'Государственный природный заповедник "Оренбургский"'}
							/>
						</Suspense>
					}
				/>

				<Route
					path='/hints-reservedestates'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Hint2_m3 />}
								name={'“Заповедные имения”'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/hints-blacksoil'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Hint3_m3 />}
								name={'Фабрика чернозёма'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/hints-saiga'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Hint4_m3 />}
								name={'Колыбель сайгака'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/hints-eagle'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Hint5_m3 />}
								name={'Степной орёл'}
							/>
						</Suspense>
					}
				/>

				<Route
					path='/hints-stepi'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Hint1_m4 />}
								name={'Фестиваль “Воспетая степь”'}
							/>
						</Suspense>
					}
				/>

				<Route
					path='/hints-factsaiga'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Hint2_m4 />}
								name={'6 фактов о сайгаке'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/hints-manych'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Hint3_m4 />}
								name={' Озеро Маныч-Гудило'}
							/>
						</Suspense>
					}
				/>
				<Route
					path='/hints-lizard'
					element={
						<Suspense fallback={<></>}>
							<LectionContainer
								hint
								element={<Hint4_m4 />}
								name={'Прыткая ящерица'}
							/>
						</Suspense>
					}
				/>
			</Routes>
		</div>
	)
}

export default App
