import 'bootstrap/dist/css/bootstrap.min.css'
import cn from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { Hint5_m4 } from '../Lection/Hint'
import LectionContainer from '../Lection/LectionContainer'
import FactDown from './FactDown'
import FactUp from './FactUp'
import './fact.scss'
import './imgBlock.scss'
import './imgBox.scss'
import './styles.scss'
import './textBox.scss'
export const TextBox = props => {
	return (
		<div
			className={cn(`${props.className} textBox  `, {
				'bcg-purple-1': props.purple && props.mode === 1,
				'bcg-purple-2': props.purple && props.mode === 2,
				'bcg-purple-3': props.purple && props.mode === 3,
				'bcg-purple-4': props.purple && props.mode === 4,
				'bcg-purple-5': props.purple && props.mode === 5,

				'bcg-yellow-1': props.yellow && props.mode === 1,
				'bcg-yellow-2': props.yellow && props.mode === 2,
				'bcg-yellow-3': props.yellow && props.mode === 3,
				'bcg-yellow-4': props.yellow && props.mode === 4,
				'bcg-yellow-5': props.yellow && props.mode === 5,

				'bcg-green-1': props.green && props.mode === 1,
				'bcg-green-2': props.green && props.mode === 2,
				'bcg-green-3': props.green && props.mode === 3,
				'bcg-green-4': props.green && props.mode === 4,
				'bcg-green-5': props.green && props.mode === 5,

				'bcg-blue-1': props.blue && props.mode === 1,
				'bcg-blue-2': props.blue && props.mode === 2,
				'bcg-blue-3': props.blue && props.mode === 3,
				'bcg-blue-4': props.blue && props.mode === 4,
				'bcg-blue-5': props.blue && props.mode === 5,

				'clip-blue-top-right': props.clip === 'blue_tr',
				'clip-blue-top-left': props.clip === 'blue_tl',

				'clip-pink-top-right': props.clip === 'pink_tr',
				'clip-pink-top-left': props.clip === 'pink_tl',

				'clip-yellow-top-right': props.clip === 'yellow_tr',
				'clip-yellow-top-left': props.clip === 'yellow_tl',

				'clip-orange-top-right': props.clip === 'orange_tr',
				'clip-orange-top-left': props.clip === 'orange_tl',

				'clip-green-top-right': props.clip === 'green_tr',
				'clip-green-top-left': props.clip === 'green_tl',

				'stick-double': props.doubleBox,

				'stick-double-up': props.doubleBox_up,
			})}
		>
			{props.element}

			{props.dote && (
				<>
					<div className='dote-white'></div>
					<div className='dote-white'></div>
					<div className='dote-white'></div>
					<div className='dote-white'></div>
				</>
			)}
			{props.description && (
				<p className={`text-italic ${props.description_class}`}>
					{props.description}
				</p>
			)}
		</div>
	)
}
export const ImgBox = props => {
	return (
		<div
			className={cn(`img-box  ${props.mark_class} ${props.className} `, {
				['stick-double-img']: props.doubleBox,
			})}
		>
			<img className='img' src={props.src} alt={props.alt} />
			{props.description && (
				<p style={{ whiteSpace: "pre-wrap" }} className={`img-desctiption ${props.description_class}`}>
					{props.description}
				</p>
			)}
		</div>
	)
}
export const OverlayBox = props => {
	const [isActive, setIsActive] = useState(false)

	const handleClick = useCallback(() => {
		setIsActive(!isActive)
		console.log(isActive)
	}, [isActive, setIsActive])

	return (
		<>
			<button
				data-check-after
				id='span-modal'
				onClick={handleClick}
				className='text-bold overlay '
			>
				{props.text}
				<p
					className={cn(
						props.mode_class,
						'text-mediume overlay-tl overlay-bcg-1',
						{
							['open']: isActive,
							['close']: !isActive,
						}
					)}
				>
					{props.afterText}
				</p>
			</button>
		</>
	)
}
export const Fact = props => {
	const [Color, SetColor] = useState({
		firstColor: '#5A8EEBCC',
		secondColor: '#ACC8FACC',
	})
	useEffect(() => {
		if (props.color == 1) {
			SetColor({
				firstColor: '#FFC54FCC',
				secondColor: '#FFDB91CC',
			})
		} else if (props.color == 2) {
			SetColor({
				firstColor: '#603C9DCC',
				secondColor: '#A27AE6CC',
			})
		} else if (props.color == 3) {
			SetColor({
				firstColor: '#6AD239CC',
				secondColor: '#ACF48ACC',
			})
		} else if (props.color == 4) {
			SetColor({
				firstColor: '#ACC8FACC',
				secondColor: '#5A8EEBCC',
			})
		}
	}, [])
	return (
		<div className='fact'>
			<p className='fact-title'>ИНТЕРЕСНЫЙФАКТ</p>
			<div className='fact-container'>
				<FactUp firstColor={Color.firstColor} secondColor={Color.secondColor} />
				{props.text}
				<FactDown
					firstColor={Color.firstColor}
					secondColor={Color.secondColor}
				/>
			</div>
			<p className='fact-title'>ИНТЕРЕСНЫЙФАКТ</p>
		</div>
	)
}
export const ImgBlock = props => {
	return (
		<div
			className={cn(`${props.className} img-block `, {
				'bcg-purple-1': props.purple && props.mode === 1,
				'bcg-purple-2': props.purple && props.mode === 2,
				'bcg-purple-3': props.purple && props.mode === 3,
				'bcg-purple-4': props.purple && props.mode === 4,
				'bcg-purple-5': props.purple && props.mode === 5,

				'bcg-yellow-1': props.yellow && props.mode === 1,
				'bcg-yellow-2': props.yellow && props.mode === 2,
				'bcg-yellow-3': props.yellow && props.mode === 3,
				'bcg-yellow-4': props.yellow && props.mode === 4,
				'bcg-yellow-5': props.yellow && props.mode === 5,

				'bcg-green-1': props.green && props.mode === 1,
				'bcg-green-2': props.green && props.mode === 2,
				'bcg-green-3': props.green && props.mode === 3,
				'bcg-green-4': props.green && props.mode === 4,
				'bcg-green-5': props.green && props.mode === 5,

				'bcg-blue-1': props.blue && props.mode === 1,
				'bcg-blue-2': props.blue && props.mode === 2,
				'bcg-blue-3': props.blue && props.mode === 3,
				'bcg-blue-4': props.blue && props.mode === 4,
				'bcg-blue-5': props.blue && props.mode === 5,
				'double-mark': props.doubleMark,
			})}
		>
			{props.element}
			{props.imgs && (
				<div className='block-container mt-5'>
					{props.imgs.map((img, index) => (
						<div className='block-item'>
							<img
								key={index}
								src={img.src}
								alt='img'
								className='block-item-img'
							/>
							<p className='block-item-text'>{img.name}</p>
						</div>
					))}
				</div>
			)}
		</div>
	)
}
const UI = props => {
	return (
		<LectionContainer
			element={<Hint5_m4 />}
			name={'Государственный природный биосферный заповедник “Ростовский”'}
		/>
	)
}

export default UI
